import React, { Component  } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export class CustomModal extends Component {
    constructor(props) {

        super(props);

        this.state = {
            open:this.props.open,
        };    
    }

    componentWillUpdate(nextProps, nextState) {
        nextState.open = nextProps.open;
    }

    closeModal(){
        this.props.handleCustomModalCallBack();
    }

    render(){

        var open = this.state.open;

        return(
            <Dialog
                open={open}       
                onClose={() => this.closeModal()} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={this.props == undefined ? "sm" : this.props.size}
            >
                <DialogTitle id="alert-dialog-title">
                    { this.props.title }
                </DialogTitle>
                <DialogContent>                    
                    {this.props.children}                    
                </DialogContent>              
            </Dialog>
        );
    }
}