const userInfo = {
  userAgent: navigator.userAgent,
  language: navigator.language,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const screenInfo = {
  width: window.screen.width,
  height: window.screen.height,
  colorDepth: window.screen.colorDepth,};

const cookiesEnabled = navigator.cookieEnabled;

const connection =  navigator.connection || navigator.mozConnection || navigator.webkitConnection;

const connectionInfo = {
  type: connection?.type,
  effectiveType: connection?.effectiveType,
  downlink: connection?.downlink,
};



export { userInfo, screenInfo, cookiesEnabled, connectionInfo }

