import React, { Component } from "react";

import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material/";
import { BsFillTelephoneFill } from "react-icons/bs";

import moment from "moment";
import "moment/locale/es";
import { Cancel, Check } from "@mui/icons-material";

export class VerificacionTelefonica extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    var verificacion = this.props.verificacion;

    if (verificacion !== null) {
      return (
        <Paper className="paper" square={false}>
          <Grid
            container
            style={{
              border: "1px solid #ccc",
              borderColor: "#ccc",
              borderRadius: "50px",
              paddingRight: "15px",
            }}
            alignItems="center"
          >
            <Grid
              item
              style={{
                backgroundColor: "#ccc",
                borderRadius: "50px",
                padding: "7px",
              }}
            >
              <BsFillTelephoneFill style={{ color: "#fff" }} />
            </Grid>
            <Grid item style={{ marginLeft: "8px" }}>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "0.85rem",
                  color: "#444",
                  margin: "0px",
                }}
              >
                Verificación telefónica
              </p>
            </Grid>
          </Grid>
          <br />

          {verificacion.realizada ? (
            <>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b style={{ color: "#3E5060" }}>Plan correcto</b>
                    </TableCell>
                    <TableCell>
                      {verificacion.planCorrecto ? <Check /> : <Cancel />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b style={{ color: "#3E5060" }}>Producto correcto</b>
                    </TableCell>
                    <TableCell>
                      {verificacion.productoCorrecto ? <Check /> : <Cancel />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b style={{ color: "#3E5060" }}>Observación</b>
                    </TableCell>
                    <TableCell>{verificacion.observacion}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <br />
              <p style={{ color: "#3E5060", fontSize: "0.75rem" }}>
                {moment(verificacion.fecha).format("dddd DD MMMM YYYY")}
              </p>
            </>
          ) : (
            <p style={{ color: "#3E5060" }}>
              La verificación telefónica aún no se realizó
            </p>
          )}

          {/* {this.props.solicitud.verificacionTelefonicaPlanCorrecto ? (
            <b style={{ color: "#3E5060" }}>El plan es correcto</b>
          ) : (
            <p></p>
          )}

          {this.props.solicitud.verificacionTelefonicaProductoCorrecto ? (
            <b style={{ color: "#3E5060" }}>El producto es correcto</b>
          ) : (
            <p></p>
          )}

          <p className="long-text">
            {this.props.solicitud.verificacionTelefonicaObservacion}
          </p>

          <br />
          <br />
          <b style={{ color: "#3E5060", fontSize: "0.75rem" }}></b>
          <p style={{ color: "#3E5060", fontSize: "0.75rem" }}>
            {this.props.solicitud.verificacionTelefonicaFecha
              ? moment(this.props.solicitud.verificacionTelefonicaFecha).format(
                  "dddd DD MMMM YYYY"
                )
              : null}
          </p> */}
        </Paper>
      );
    } else {
      return <p>Espere por favor</p>;
    }
  }
}
