import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { convertToLocalTime } from "date-fns-timezone";
import moment from "moment";
import "moment/locale/es";
/**
 * Returns date object  to DD/MM/YYYY.
 *
 * @param {date} x The date to format.

 * @return DD/MM/YYYY
 */

export const DEFAULT_DATE_FORMAT = "dd/MM/yyy";

function formatDate(date) {
  if (date === "0001-01-01T00:00:00" || date === null) {
    return "-";
  } else {
    var result = new Date(date);
    var day = result.getDate();
    var month = result.getMonth() + 1;
    return (
      day.toString().padStart(2, "00") +
      "/" +
      month.toString().padStart(2, "00") +
      "/" +
      result.getFullYear()
    );
  }
}

function formatDateToMonth(date){
    return moment().month(date, "ddd MMM DD YYYY");
}

function getHour(date) {
  var result = new Date(date);
  var hour = result.getHours();
  var minutes = result.getMinutes();
  return (
    hour.toString().padStart(2, "00") +
    ":" +
    minutes.toString().padStart(2, "00")
  );
}

function formatLongDate(date){

  var momentObj = moment(date);
var momentString = momentObj.format('ddd DD MMM YYYY - HH:mm:ss '); 
  return momentString;
}


function formatDateWithHour(date){

  var momentObj = moment(date);
var momentString = momentObj.format('DD/MM/YYYY - HH:mm:ss'); 
  return momentString;
}

function jsDateToLocalISO8601DateString(date) {
  return [
    String(date.getFullYear()),
    String(101 + date.getMonth()).substring(1),
    String(100 + date.getDate()).substring(1),
  ].join("-");
}

function dateStringToLocalDate(s) {
  if (!s) return null;
  return new DateFnsUtils().parse(s, "dd-MM-yyyy");
}

function  getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}



export {
  formatDate,
  getHour,
  jsDateToLocalISO8601DateString,
  dateStringToLocalDate,
  getMonday,
  formatLongDate,
  formatDateWithHour
};
