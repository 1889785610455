
const routes = {


   user: {
      label: "Usuarios",
      list: "/usuarios",
      add: "/nuevo-usuario",
      edit: "/editando-usuario",
      view: "/ver-usuario",        
   },  

   role: {
      label: "Permisos",
      list: "/permisos",
      add: "/nuevo-permiso",
      edit: "/editando-permiso",
      view: "/ver-premiso",        
   },  

   cargo: {
      label: "Sectores",
      list: "/sectores",
      add: "/nuevo-sector",
      edit: "/editando-sector",
      view: "/ver-sector",        
      url: "/api/cargo"
   },  



   escalasComisiones: {
      label: "Escalas comisiones",
      list: "/escalas-comisiones",
      add: "/nuevo-escalas-comisiones",
      edit: "/editando-escalas-comisiones",
      view: "/ver-escalas-comisiones",        
      url: "/api/EscalaComisionVenta",
   },  


   objetivosVentas: {
      label: "Objetivos de ventas",
      list: "/objetivos-de-ventas",
      add: "/nuevo-objetivo-semanal",
      edit: "/editando-objetivo-semanal",
      view: "/ver-objetivo-semanal",        
      url: "/api/ObjetivoVenta",

   },  

   comisionesVendedores: {
      label: "Comisiones vendedores",
      list: "/comisiones-vendedores",
      url: "/api/ComisionesVendedores",

   },  

   tiposDeMovimientosCaja: {
      label: "Asientos prearmados",
      list: "/asientos-prearmados",
      add: "/nuevo-tipo-movimiento",
      edit: "/editando-tipo-movimiento",
      view: "/ver-tipo-movimiento",        
      url: "/api/AsientoPrearmado",
   },  

   
   movimientosCaja: {
      label: "Panel de control",
      list: "/caja",
      listCentroDeCostos: "/centro-de-costos",
      add: "/nuevo-movimiento",
      edit: "/editando-movimiento",
      view: "/ver-movimiento",        
      url: "/api/MovimientoCaja",

      addSimplificado: "/nuevo-movimiento-simplificado",
      editSimplificado: "/editando-movimiento-simplificado",
   }, 

   centroDeCostos: {
      label: "Centro de costos",
      list: "/centro-de-costos",           
      view: "/ver-movimiento",        
      url: "/api/CentroDeCostos",

      addSimplificado: "/nuevo-movimiento-simplificado",
      editSimplificado: "/editando-movimiento-simplificado",
   }, 

   movimientosCajaSimplificado: {
      label: "Caja tesorería",
      list: "/caja-azul",
      add: "/nuevo-movimiento-simplificado",
      edit: "/editando-movimiento-simplificado",

      view: "/ver-movimiento-simplificado",      
        
      url: "/api/MovimientoCaja",

   
   }, 

   prospectos: {
      label: "Prospectos",
      list: "/prospectos",
      add: "/nuevo-prospecto",
      edit: "/editando-prospecto",
      view: "/ver-prospecto",        
      url: "/api/Prospecto",
   }, 


   historialComisiones: {
      label: "Historial comisiones",
      list: "/historial-comisiones", 
      view: "/ver-captura",        
      url: "/api/ComisionesVendedores",
   }, 


   comisionesParametros: {
      label: "Parámetros",
      list: "/comisiones-parametros",
      add: "/nueva-comision-parametro",
      edit: "/editando-comision-parametro",
      view: "/ver-comision-parametro",        
      url: "/api/ComisionVendedorParametro",
   },  

   zonas: {
      label: "Zonas",
      list: "/zonas",
      add: "/nueva-zona",
      edit: "/editando-zona",
      view: "/ver-zona",        
      url: "/api/Zona",
   },  

   
   clientes: {
      label: "Clientes",
      list: "/clientes",
      add: "/nuevo-cliente",
      edit: "/editando-cliente",
      view: "/ver-cliente",        
      url: "/api/cliente",
   },  


   movimientosNoPagos: {
      label: "No pagos",
      list: "/no-pagos",        
      url: "/api/movimientoNoPago",
   },  


   logistica: {
      label: "Logística",
      list: "/logistica",            
      view: "/ver-solicitud-logistica",        
      url: "/api/Logistica",
   }, 

   calificacionCrediticia: {
      label: "Calificación crediticia",
      list: "/calificacion-crediticia",            
      view: "/ver-solicitud-calificacion",        
      url: "/api/CalificacionCrediticia",
   }, 


   
   tasaInteres: {
      label: "Tasas de interés",
      list: "/tasas-de-interes",            
      view: "/ver-tasas-interes",        
      url: "/api/TasaInteres",
      add: "/tasas-de-interes/add",
      edit: "/editando-tasa-interes",
   }, 

   
   cuentas: {
      label: "Cuentas",
      list: "/cuentas",            
      view: "/ver-cuenta",        
      url: "/api/Cuenta",
      add: "/nueva-cuenta",
      edit: "/editando-cuenta",
   }, 


   motivosDescuentoRechazos: {
      label: "Descuentos. Motivos de rechazo",
      list: "/motivos-descuentos-rechazos",            
      view: "/ver-motivos-descuentos-rechazos",        
      url: "/api/MotivoDescuentoRechazo",
      add: "/nuevo-motivos-descuentos-rechazos",
      edit: "/editando-motivos-descuentos-rechazos",
   }, 

   motivosPedidosDescuento: {
      label: "Descuentos. Motivos de pedido",
      list: "/motivos-pedidos-descuentos",            
      view: "/ver-motivos-pedidos-descuentos",        
      url: "/api/MotivoPedidoDescuento",
      add: "/nuevo-motivo-pedidos-descuentos",
      edit: "/editando-motivos-pedidos-descuentos",
   }, 


   descuentos: {
      label: "Descuentos",
      list: "/descuentos",            
      view: "/ver-descuento",        
      url: "/api/Descuento",
      add: "/nuevo-descuento",
      edit: "/editando-descuento",
   }, 


   agenda: {
      label: "Agenda",
      list: "/agenda",            
      view: "/ver-actividad",        
      url: "/api/actividad",
      add: "/nueva-actividad",
      edit: "/editando-actividad",
   }, 

   campañaDeVentas: {
      label: "Camapaña ventas",
      list: "/camapaña-ventas",            
      view: "/ver-camapaña",        
      url: "/api/CampañaVenta",
      add: "/nueva-campaña",
      edit: "/editando-campaña",
   }, 

   fleterosTiposVehiculos: {
      label: "Tipos de vehículos",
      list: "/tipos-de-vehiculos",            
      view: "/ver-tipo-de-vehiculo",        
      url: "/api/FleteroTipoVehiculo",
      add: "/nuevo-tipo-de-vehiculo",
      edit: "/editando-tipo-de-vehiculo",
      viewTarifas: "/tipo-vehiculo-tarifa"
   }, 

   fleteros: {
      label: "Fleteros",
      list: "/fleteros",            
      view: "/ver-fletero",        
      url: "/api/Fletero",
      add: "/nuevo-fletero",
      edit: "/editando-fletero",      
   }, 

   destinoPYL: {
      label: "Agrupaciones P&L",
      list: "/agrupaciones-de-cuentas",            
      view: "/ver-destino-pyl",        
      url: "/api/AgrupacionesDeCuentas",
      add: "/nuevo-destino-pyl",
      edit: "/editando-destino-pyl",      
   }, 


 };

 export default routes;