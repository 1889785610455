import React from "react";
import { Grid, Paper, Typography, LinearProgress, CircularProgress } from "@mui/material/";
import Avatar from "@mui/material/Avatar";
import { formatPercent } from '../../../helpers/currencyPercent-helper'


export default function DashboardCardPercent(props) {
  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: "0.75rem",
      }}
    >
      <div className="p-20">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <span className="dashboard-title">{props.title}</span>

            <Typography variant="h5">
            <span>{props.value} %</span> 
              </Typography>

          
          
          </Grid>
          <Grid item>
            <Avatar sx={{ bgcolor: props.color }}>{props.icon}</Avatar> 
          </Grid>
        </Grid>

        
      </div>
    </Paper>
  );
}
