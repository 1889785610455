// src/apis/pageAPI.js

import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const CajaConsolidadaAPI = {



  getPaginated: async function ({ page, perPage, sortColumn, sortDirection, query, filters }, cancel = false) {
    var params = {
      page: page,
      per_page: perPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      query: query,
    };

    Object.assign(params, filters);

    const response = await api.request({
      url: "api/CentroDeCostos",
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.getPaginated.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response;
  },


  getBalanceDisponibilidades: async function (filters, cancel = false) {
    const response = await api.request({
      url: `/api/AsientosManuales/GetBalanceDisponibilidades/`,
      method: "GET",
      params: filters,
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getBalanceDisponibilidades.name].handleRequestCancellation().signal : undefined,
    })

    // returning the page returned by the API
    return response
  }, 

  getDisponibilidadesForSelect: async function (filters, cancel = false) {
    const response = await api.request({
      url: `/api/AsientosManuales/GetDisponibilidadesForSelect/`,
      method: "GET",
      params: filters,
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getDisponibilidadesForSelect.name].handleRequestCancellation().signal : undefined,
    })

    // returning the page returned by the API
    return response
  }, 


  getCuentasForAutocomplete: async function (filters, cancel = false) {
    const response = await api.request({
      url: `/api/cuenta/GetCuentasForAutocomplete/`,
      method: "GET",
      params: filters,
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getCuentasForAutocomplete.name].handleRequestCancellation().signal : undefined,
    })

    // returning the page returned by the API
    return response
  }, 

  generateExcel: async function ( {sortColumn, sortDirection, query, filters }, cancel = false) {

    var params = {
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      query: query,        
    };

    Object.assign(params, filters);


    const response = await api.request({
      url: "/api/CentroDeCostos/GenerateExcel",
      method: "GET",
      params: params,      
      responseType: "blob",
      signal: cancel
        ? cancelApiObject[this.generateExcel.name].handleRequestCancellation().signal
        : undefined,
    });

       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", "caja_consolidada.xlsx");
       document.body.appendChild(link);
       link.click();

       return response;
  },

  

  generatePdf: async function ( { sortColumn, sortDirection, query, filters }, cancel = false) {

    var params = {
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      query: query,        
    };

    Object.assign(params, filters);


    const response = await api.request({
      url: "/api/CentroDeCostos/GeneratePdf",
      method: "GET",
      params: params,      
      responseType: "blob",
      signal: cancel
        ? cancelApiObject[this.generatePdf.name].handleRequestCancellation().signal
        : undefined,
    });

       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", "caja_consolidada.pdf");
       document.body.appendChild(link);
       link.click();

       return response;

  },

};

// defining the cancel API object for pageAPI
const cancelApiObject = defineCancelApiObject(CajaConsolidadaAPI);
