import axios from "axios";
import { getToken } from '../../helpers/auth-helper'
import  appConfig from './../../constants/appConfig'

export const version = "1.0.57";




export const axiosInstance = axios.create({	
    baseURL: appConfig.baseURL,
    timeout: 10000
});


axiosInstance.interceptors.request.use(
    async config => {

        var token = await getToken();

        if (token !== null) {            
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        else {
            config.headers["Authorization"] = `Bearer 1`;
        }

        return config;
    });

    
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status == 403) {
            window.location = "/error-403";
        } else {
            return Promise.reject(error);
        }
    }
);