/**
 * cart list item
 */
/* eslint-disable */
import React, { Fragment } from 'react';
import { Button, Popover, IconButton, Badge } from '@mui/material';

import { Link } from 'react-router-dom';

//connect to store
import { connect } from "react-redux";




//action
import { removeProductItem, initializeCart } from './../actions/action';

class Cart extends React.Component {

    constructor(props) {
        super(props);
        this.confirmationDialog = React.createRef();
        this.state = {
            anchorEl: null,
        };
    }

    async componentDidMount() {
        //const shoppingCartResponse = await axios.get('/api/ShoppingCart/GetCart');
        //this.props.initializeCart(shoppingCartResponse.data);
    }

    //Define function for open dropdown
    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    //Define function for close dropdown
    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    //Function to delete product from cart
    onDeleteCartItem(cartItem) {
        this.cartItem = cartItem;
        this.confirmationDialog.current.openDialog();
    }

    //Function for delete cart product
    deleteCartItem(popupResponse) {
        if (popupResponse) {
            this.props.removeProductItem(this.cartItem);
            this.cartItem = ""
        }
        this.setState(
            {
                anchorEl: null,
            }
        )
    }

    
    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const { cart } = this.props;

        return (
            <div>
                <IconButton
                    color="inherit"
                    aria-owns={open ? 'simple-popper' : null}
                    aria-haspopup="true"
                    variant="contained"
                    onClick={this.handleClick}
                    className="mr-10"
                    aria-label="Cart"
                >
                    {cart && cart.length > 0 ?
                        (
                            <Badge
                                badgeContent={cart.length}
                                color="secondary"
                                className="badge-active"
                            >
                                <i className="material-icons">shopping_cart</i>

                            </Badge>
                        )
                        :
                        (
                            <i className="material-icons">shopping_cart</i>
                        )
                    }
                </IconButton>
                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div>
                        {(cart && cart.length > 0) ?
                            (
                                <Fragment>
                                    <ul className="iron-cart-list-wrap mb-0" style={{ borderTop: '4px solid #0074bc', }}>
                                        {cart && cart.map((cartItem, index) => {
                                            return (
                                                <li key={index} className="cart-menu-item p-10 mb-0">
                                                    <div className="d-flex iron-cart-post">
                                                        <div className="cart-thumb">


                                                               
                                                                    <img
                                                                        src={cartItem.image}
                                                                        alt='product-thumbnail'
                                                                    />
                                                          

                                                        </div>
                                                        <div className=" cart-content-wrap d-flex justify-content-start align-items-center">
                                                            <div className="cart-content" >
                                                                <h6 className="mb-5 text-truncate">{cartItem.name}</h6>
                                                                <p> Cantidad: {cartItem.quantity}</p>
                                                                
                                                            </div>
                                                            <div className="cart-edit-action d-flex justify-content-end align-items-center">
                                                                <Button
                                                                    className="icon-btn button mr-5"
                                                                    onClick={() => this.deleteCartItem(cartItem)
                                                                    }
                                                                    title="Eliminar producto"
                                                                >
                                                                    <i className="material-icons">delete</i>
                                                                </Button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )


                                        })
                                        }


                                     




                                    </ul>
                                    <div className=" py-15 px-10">
                                        <Button onClick={this.handleClose} component={Link} to="/cart" className="button btn-active w-100">
                                            Ir a mi carrito
                              </Button>
                                    </div>
                                </Fragment>
                            )
                            :
                            (
                                <div>
                                    <span className="text-capitalize text-14 dark-color d-block px-40 py-15">Carrito sin productos</span>
                                </div>
                            )
                        }
                    </div>
                  
                </Popover>
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ ecommerce }) => {
    const { cart, shipping } = ecommerce;
    return { cart, shipping };
}

export default connect(mapStateToProps, {
    initializeCart,
    removeProductItem
})(Cart);

