import React from "react";
import { Button } from "@mui/material/";
import { AddOutlined } from "@mui/icons-material/";

import { Link } from "react-router-dom";

export default function NewButton(props) {
  return (
    <Link to={props.to}>
      <Button
        type="button"
        startIcon={<AddOutlined />}
        style={{ float: "right", width: "100%" }}
        variant="contained"
        color="primary"
        size="medium"
      >
        { props.label == undefined ? "Nuevo" : props.label }
      </Button>
    </Link>
  );
}
