import React, { useState, useEffect } from "react";
import { MenuItem, TextField } from "@mui/material/";
import Autocomplete from "@mui/material/Autocomplete";

import { AsientosManualesService } from "../../../services/moduloCajaService";

function UsuarioSelect(props) {
  const [usuarioId, setUsuarioId] = useState("0");
  const [usuarios, setUsuarios] = useState(null);

  const getData = async () => {
    setUsuarios([]);
    const data = await AsientosManualesService.getUsuarios();
    setUsuarios(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeAutocomplete = (event, value) => {

    if(value == null){
      setUsuarioId("0");
    }
    else{
      setUsuarioId(value.id);
    }

    props.onSelectChange(value === null ? null : value.id);

  };

  return (
    <Autocomplete
      groupBy={(option) => option.groupBy}
      style={{ width: "100%", minWidth: "300px" }}
      disablePortal
      id="idUsuario"
      name="idUsuario"
      size="small"
      options={usuarios}
      onChange={onChangeAutocomplete}
      renderInput={(params) => (
        <TextField size="small" variant="standard" {...params} label="Usuario" />
      )}
    />
  );
}

export default UsuarioSelect;
