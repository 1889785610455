import React, { Component } from 'react';
import { Button , Grid, Checkbox  }  from '@mui/material/';



import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import CancelIcon from '@mui/icons-material/Cancel';


export class VerificacionAmbientalForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pagandoProducto: '',
            tiempoNegocio: this.props.verificacion.tiempoNegocio,
            facturacionDiaria: this.props.verificacion.facturacionDiaria,
            facturacionOtrosIngresos: this.props.verificacion.facturacionOtrosIngresos,
            stockDelComercio: this.props.verificacion.stockDelComercio,
            vivienda: this.props.verificacion.vivienda,
            alquilerVivienda: this.props.verificacion.alquilerVivienda,
            comercio: this.props.verificacion.comercio,
            alquilerComercio: this.props.verificacion.alquilerComercio,
            zona: this.props.verificacion.zona,
            pagoDiario: this.props.verificacion.pagoDiario,
            opinionDelVerificador: this.props.verificacion.opinionDelVerificador,
        };
    }

    async componentDidMount() {
    }

    componentWillUpdate(nextProps, nextState) {
        nextState.cliente = nextProps.cliente;
    }



    onChange2 = (event, value) => {
        this.setState({
            [event.target.name]: value
        })
    }


    onBlur = (event, value) => {
        if(event.target.value === "")
        {
            this.setState({
                [event.target.name]: '0.00'
            })
        }
    }

    onChange = (event, value) => {

        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {
            this.setState({
                [event.target.name]: event.target.value
            })
        }

    }

    onSubmit(){

    }

    render(){

        var cliente = this.props.cliente;
        var verificacion = this.props.verificacion;

        if(cliente !== null && verificacion !== null){
            return (



                    <ValidatorForm
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                    >

                    <Grid container spacing={2}>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <TextValidator
                                name="tiempoNegocio"
                                className="w-100"
                                size="medium"
                                color="primary"
                                label="¿Cuánto hace que tiene el negocio?"
                                variant="outlined"
                                value={this.state.tiempoNegocio}
                                onChange={this.onChange}
                                validators={['required', 'maxStringLength:400']}
                                errorMessages={['*', 'Máximo 400 carácteres']}
                                />
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={6}>
                            <TextValidator
                                label="¿Cuánto factura por día?"
                                className="w-100"
                                name="facturacionDiaria"
                                variant="outlined"
                                value={this.state.facturacionDiaria}                                
                                onChange={this.onChange}                                              
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={6}>
                            <TextValidator
                                label="Otros ingresos"
                                className="w-100"
                                name="facturacionOtrosIngresos"
                                variant="outlined"
                                value={this.state.facturacionOtrosIngresos}
                                onChange={this.onChange}
                                
                                
                                
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel id="stockDelComercioLabel">Stock del comercio</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="stockDelComercio"
                                    value={this.state.stockDelComercio}
                                    onChange={this.onChange}
                                >
                                    <FormControlLabel value="Sin stock" control={<Radio />} label="Sin stock" />
                                    <FormControlLabel value="Stock regular" control={<Radio />} label="Stock regular" />
                                    <FormControlLabel value="Stock aceptable" control={<Radio />} label="Stock aceptable" />
                                    <FormControlLabel value="Buen nivel de stock" control={<Radio />} label="Buen nivel de stock" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Equipamentos comerciales</FormLabel>
                                <div>

                                    <FormControlLabel value="Heladera" control={<Checkbox  />} label="Heladera" />
                                    <FormControlLabel value="Freezer" control={<Checkbox  />} label="Freezer" />
                                    <FormControlLabel value="Balanza" control={<Checkbox  />} label="Balanza" />
                                    <FormControlLabel value="Panchera" control={<Checkbox  />} label="Panchera" />
                                    <FormControlLabel value="Batea/Vitrina" control={<Checkbox  />} label="Batea/Vitrina" />
                                    <FormControlLabel value="Freidora" control={<Checkbox  />} label="Freidora" />
                                    <FormControlLabel value="Otro" control={<Checkbox  />} label="Otro" />
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel id="viviendaLabel">Vivienda</FormLabel>
                                <RadioGroup
                                    row
                                    name="vivienda"
                                    value={this.state.vivienda}
                                    onChange={this.onChange}
                                >
                                    <FormControlLabel value="Propia" control={<Radio />} label="Propia" />
                                    <FormControlLabel value="Alquilada" control={<Radio />} label="Alquilada" />



                                </RadioGroup>



                            </FormControl>

                            <CurrencyTextField
                                label="Monto alquiler"
                                name="alquilerVivienda"
                                variant="outlined"
                                size="small"
                                value={this.state.alquilerVivienda}
                                currencySymbol="$"
                                outputFormat="string"
                                onChange={(event, value)=> this.onChange2(event, value)}
                                decimalCharacter="."
                                digitGroupSeparator=","
                                onBlur={(event, value)=> this.onBlur(event, value)}
                                style={{marginTop:'24px'}}
                            />
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Comercio</FormLabel>
                                <RadioGroup
                                    row
                                    name="vivienda"
                                    value={this.state.comercio}
                                    onChange={this.onChange}
                                >
                                    <FormControlLabel value="Propio" control={<Radio />} label="Propio" />
                                    <FormControlLabel value="Alquilado" control={<Radio />} label="Alquilado" />
                                </RadioGroup>
                            </FormControl>

                            <CurrencyTextField
                                label="Monto alquiler"
                                name="alquilerComercio"
                                variant="outlined"
                                size="small"
                                value={this.state.alquilerComercio}
                                currencySymbol="$"
                                outputFormat="string"
                                onChange={(event, value)=> this.onChange2(event, value)}
                                decimalCharacter="."
                                digitGroupSeparator=","
                                onBlur={(event, value)=> this.onBlur(event, value)}
                                style={{marginTop:'24px'}}
                            />
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">La zona es</FormLabel>
                                <RadioGroup
                                    row
                                    name="zona"
                                    value={this.state.zona}
                                    onChange={this.onChange}
                                >
                                    <FormControlLabel value="Aceptable" control={<Radio />} label="Aceptable" />
                                    <FormControlLabel value="Riesgo" control={<Radio />} label="Riesgo" />
                                    <FormControlLabel value="Calle de tierra" control={<Radio />} label="Calle de tierra" />
                                    <FormControlLabel value="Calle de asfalto" control={<Radio />} label="Calle de asfalto" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>



                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Indagar sobre el IC</FormLabel>
                                <RadioGroup
                                    row
                                    name="icReconoceDeuda"
                                    value={this.state.icReconoceDeuda}
                                    onChange={this.onChange}

                                >
                                    <FormControlLabel value="Reconoce deuda" control={<Radio />} label="Reconoce deuda" />
                                    <FormControlLabel value="No reconoce deuda" control={<Radio />} label="No reconoce deuda" />
                                </RadioGroup>
                            </FormControl>

                            <CurrencyTextField
                                label="Monto deuda"
                                name="icDeuda"
                                variant="outlined"
                                size="small"
                                value={this.state.icDeuda}
                                currencySymbol="$"
                                outputFormat="string"
                                onChange={(event, value)=> this.onChange2(event, value)}
                                decimalCharacter="."
                                digitGroupSeparator=","
                                onBlur={(event, value)=> this.onBlur(event, value)}
                                style={{marginTop:'24px'}}
                            />
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextValidator
                                    name="comentarioIC"
                                    className="w-100"
                                    size="medium"
                                    label="Comentario IC"
                                    variant="outlined"
                                    value={this.state.comentarioIC}
                                    multiline
                                    rows={4}
                                    onChange={this.onChange}
                                    validators={['required', 'maxStringLength:400']}
                                    errorMessages={['La comentario es requerido', 'Máximo 400 carácteres']}
                                    />
                        </Grid>





                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Solicita crédito</FormLabel>
                                <RadioGroup
                                    row
                                    name="solicitaCredito"
                                    value={this.state.solicitaCredito}
                                    onChange={this.onChange}
                                >
                                    <FormControlLabel value="Si" control={<Radio />} label="Si" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Solicita producto</FormLabel>
                                <RadioGroup
                                    row
                                    name="solicitaProducto"
                                    value={this.state.solicitaProducto}
                                    onChange={this.onChange}

                                >
                                    <FormControlLabel value="Si" control={<Radio />} label="Si" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel id="labelPagoDiario">Conoce el sistema de pado diario</FormLabel>
                                <RadioGroup
                                    row
                                    name="pagoDiario"
                                    value={this.state.pagoDiario}
                                    onChange={this.onChange}
                                >
                                    <FormControlLabel value="Si" control={<Radio />} label="Si" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel id="labelPagandoProducto">¿Esta actualmente pagando algún producto?</FormLabel>
                                <RadioGroup
                                    row
                                    name="pagandoProducto"
                                    value={this.state.pagandoProducto}
                                    onChange={this.onChange}
                                >
                                    <FormControlLabel value="si" control={<Radio />} label="Si" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextValidator
                                    name="comentarioProducto"
                                    className="w-100"
                                    size="medium"
                                    label="Observación producto"
                                    variant="outlined"
                                    value={this.state.comentarioProducto}
                                    multiline
                                    rows={4}
                                    onChange={this.onChange}
                                    validators={['required', 'maxStringLength:400']}
                                    errorMessages={['La observación es requerida', 'Máximo 400 carácteres']}
                                    />
                        </Grid>



                        <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextValidator
                                name="opinionDelVerificador"
                                className="w-100"
                                size="medium"
                                label="Opinión del verificador"
                                variant="outlined"
                                value={this.state.opinionDelVerificador}
                                multiline
                                rows={6}
                                onChange={this.onChange}
                                validators={['required', 'maxStringLength:400']}
                                errorMessages={['La opinió de vrificador es requerida', 'Máximo 400 carácteres']}
                                />
                    </Grid>

                    </Grid>
                    <br/>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container direction="row" justifyContent='flex-end' spacing={2}>
                            <Grid item>

   {/* <Button color="primary" variant="contained" type="submit" autoFocus
                            startIcon={<SaveIcon />}
                        >
                            Guardar
                        </Button> */}

                            </Grid>
                            <Grid item>
                                <Button
                                    className="ml-10"
                                    variant="text"
                                    color="primary"
                                    size="medium"
                                    startIcon={<CancelIcon />}
                                    onClick={() => this.props.handleModalCallBack()}
                                >
                                    Cerrar
                                </Button>
                            </Grid>
                        </Grid>

                     

                        
                    </Grid>
                    <br/>
                    </ValidatorForm>





            )
        }
        else{
            return null;
        }

   }


}