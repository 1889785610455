import { Button, CircularProgress } from "@mui/material";
import { Download } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { styled } from '@mui/styles';

const PdfButton = styled(Button)(({ theme }) => ({
  color: '#ED2224',
  borderColor: '#ED2224',    
  '&:hover': {
    borderColor: '#ED2224',    
    
  },
}));


export default PdfButton;