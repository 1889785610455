import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Container,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material/";

import { green, pink } from "@mui/material/colors";

import {
  Edit,
  AddOutlined,
  RemoveRedEye,
  AccountBalance,
  PhoneAndroid,
  Money,
} from "@mui/icons-material/";
import Skeleton from "@mui/material/Skeleton";

import CurrencyFormat from "react-currency-format";
import { formatCurrency } from "../../helpers/currencyPercent-helper";

export default function CobranzasDelDia(props) {
  return (
    <div>
      {props.data == null && props.data == undefined ? (
        <Skeleton variant="rounded" height={100} animation="wave" />
      ) : (
        <Paper
          elevation={0}
          style={{
            borderRadius: "0.75rem",
          }}
        >
          <div className="p-20">
            <span className="dashboard-title">Totales de cobranzas</span>

            <Grid container dense={true}>
              <Grid item>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#4caf50" }}>
                      <Money />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h5">
                        {formatCurrency(props.data.efectivo)}
                      </Typography>
                    }
                    secondary="Efectivo"
                  />
                </ListItem>
              </Grid>

              <Grid item>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountBalance />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatCurrency(props.data.transferencia)}
                    secondary="Transferencias"
                  />
                </ListItem>
              </Grid>

              <Grid item>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#007eb5" }}>
                      <PhoneAndroid />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatCurrency(props.data.mercadoPago)}
                    secondary="Mercado pago"
                  />
                </ListItem>
              </Grid>
            </Grid>
          </div>
        </Paper>
      )}
    </div>
  );
}
