import React from 'react'
import { Button } from "@mui/material/";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";

export default function BackButton(props) {
  return (
     <Link to={props.to}>
            <Button
              type="button"
              startIcon={<ArrowBack />}
              variant="text"
              color="primary"
              size="large"
            >
              Volver
            </Button>
          </Link>
  )
}
