import apiClient from "../api/apiClient";

export const UserService = {
  getById: async (id) => {
    return await apiClient("GET", `/api/UserAdmin/GetById/${id}`);
  },
  getCargosForSelect: async () => {
    return await apiClient("GET", `/api/Cargo/GetCargosForSelect/`);
  },
  getEquiposVentasForSelect: async () => {
    return await apiClient("GET", `/api/EquipoVenta/GetEquiposVentasForSelect/`);
  },
  getUsuariosAplicacionCreditosForSelect: async () => {
    return await apiClient("GET",`/api/UserAdmin/GetUsuariosAplicacionCreditosForSelect/`);
  },
  getRoles: async () => {
    return await apiClient("GET", `/api/RoleAdmin/GetRoles/`);
  },
  resetPassword: async (data) => {
    return await apiClient("PUT", `/api/UserAdmin/ResetPassword/`, data);
  },
  getRolesByCargoForSelect: async (idCargo) => {
      return await apiClient("GET", `/api/Cargo/GetRolesByCargoForSelect/${idCargo}`);
  },
  saveUser: async (data) => {   
    return await apiClient("POST", `/api/UserAdmin/`, data);
  },
  updateUser: async (id, data) => {
    return await apiClient("PUT", `/api/UserAdmin/${id}`, data);
  },
  login: async (data) => {
    return await apiClient("POSTPROMISE", `/api/Auth/Login/`, data);
  },
  getMenu: async () => {
    return await apiClient("GET", `/api/Menu/GetAll/`);
  }
};
