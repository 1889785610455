import React, { Component, Fragment } from "react";
import { axiosInstance } from '../../services/api/axiosInstance';
import { MenuItem, TextField }  from '@mui/material/';

export class CuentaSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.defaultValue !== undefined ? this.props.defaultValue : '0',
      options: [],    
    };
  }

  onChange = async e => {    
    
    this.setState({
      id: e.target.value,     
    });

    this.props.onSelectChange(e);
  } 

  renderOptions() {
    return this.state.options.map((dt, i) => {
      return (
        <MenuItem
          value={dt.id}
          key={i} name={dt.text}>{dt.text}
        </MenuItem>          
      );
    });
  }
   
  async componentDidMount() {
    
    const response = await axiosInstance.get(`/api/Cuenta/GetCuentasForSelect`);             

    this.setState({
      options: response.data,      
    })
  
};

  render() {
   
    return (
        <Fragment>       
        
        <TextField
              style={{ width: "200px" }}
              name="id"
              variant="standard"
              size="small"
              select 
              label="Cuenta"
              value={this.state.id}       
              onChange={this.onChange}>
                    <MenuItem
                      value={0}
                    >
                      Todos
                    </MenuItem>        

              {this.renderOptions()}
          </TextField>
        </Fragment>
      );
    }
  }


