import { formatDate } from "../helpers/dateTimeHelper";
import apiClient from "./api/apiClient";

export const DashBoardService = {
  getCobranzas: async (desde, hasta) => {
    var params = "fechaDesde=" + formatDate(desde);
    params += "&fechaHasta=" + formatDate(hasta);
    return await apiClient("GET", `/api/dashboard/getCobranzas?${params}`);
  }
};
