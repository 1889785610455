import { Button, CircularProgress } from "@mui/material";
import { Download } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { styled } from '@mui/styles';

const ExcelButton = styled(Button)(({ theme }) => ({
  color: '#1d6f42',
  borderColor: '#1d6f42',    
  '&:hover': {
    borderColor: '#248b52',    
    
  },
}));


export default ExcelButton;