import React, { useState, useEffect } from "react";
import { Grid, Button, Container, Paper, Divider } from "@mui/material/";
import { Link, useNavigate, useParams } from "react-router-dom";

import { axiosInstance } from "../../services/api/axiosInstance";

import ArrowBack from "@mui/icons-material/ArrowBack";
import routeConfig from "./../../constants/routeConfig";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@mui/material/MenuItem";

import { CustomImageGallery } from "../../components/multimedia/customImageGallery";
import { BuscadorDeProductos } from "../../components/formComponents/buscadorDeProductos";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { ProspectoAPI as API } from "../../apis/prospectoAPI";

import SaveButton from "./../../components/common/buttons/SaveButton";
import CancelButton from "./../../components/common/buttons/CancelButton";

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DrawingManager,
} from "@react-google-maps/api";

import appConfig from "../../constants/appConfig";

import { useGeolocated } from "react-geolocated";

import InteraccionesCliente from "./InteraccionesCliente";

import LoadWrapper from "components/common/LoadWrapper";

import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

import GoogleMapsWrapperClickable from "components/geo/googleMapsWrapperClickable";

const paths = routeConfig.prospectos;
const childRef = React.createRef();

export function ProspectoForm(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [zonas, setZonas] = useState([]);
  const [rubros, setRubros] = useState([]);
  const [articulos, setArticulos] = useState([]);

  const [imagenes, setImagenes] = useState([]);

  const [prospecto, setProspecto] = useState({
    fecha: new Date(),
    apellidoNombre: "",
    zona: null,
    numeroCelular: "",
    direccionComercial: "",
    localidadComercial: "",
    ubicacionMapa: "",
    idRubro: null,
    interes: "",
    gradoDeInteres: null,
    observacion: "",
    latitud: "",
    longitud: "",
    marker: {
      lat: 0,
      lng: 0,
    },
    interacciones: [],
    direccionGoogle: "",
    direccionCompleta: "",
  });

  const [markers, setMarkers] = useState([]);

  const [value, setValue] = useState(null);

  const onSubmit = async function () {
    var response;

    var interaccionesData = childRef.current.getInteracciones();

    prospecto.articulos = articulos.map((a) => a.idArticulo);
    prospecto.imagenes = imagenes;
    prospecto.interacciones = interaccionesData;

    if (id) {
      response = await API.update(id, prospecto);
    } else {
      console.log("Create");
      response = await API.create(prospecto);
    }

    if (response.status === 200) {
      navigate(props.url);
    }
  };

  const renderZonaOptions = () => {
    return zonas.map((dt, i) => {
      return (
        <MenuItem value={dt.id} key={i} name={dt.text}>
          {dt.text}
        </MenuItem>
      );
    });
  };

  const renderRubroOptions = () => {
    return rubros.map((dt, i) => {
      return (
        <MenuItem value={dt.id} key={i} name={dt.text}>
          {dt.text}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    getData();

    if (id) {
      getProspecto();
    }
  }, []);

  useEffect(() => {
    if (prospecto.direccionCompleta) {
      geocodeByAddress(prospecto.direccionCompleta)
        .then((results) => {
          return getLatLng(results[0]);
        })
        .then(({ lat, lng }) => {
          setProspecto({
            ...prospecto,
            latitud: parseFloat(lat.toFixed(12)),
            longitud: parseFloat(lng.toFixed(12)),
          });
        });
    }
  }, [prospecto.direccionCompleta]);

  const getProspecto = async () => {
    setLoading(true);

    var prospectoResponse = await API.get(id);

    if (prospectoResponse !== undefined && prospectoResponse.status == "200") {
      setProspecto(prospectoResponse.data);

      //setMarkers(() => [prospectoResponse.data.marker]);
      //setCenter(prospectoResponse.data.marker);
    } else {
      setError(true);
    }

    setLoading(false);
  };

  const getData = async () => {
    const zonaResponse = await axiosInstance.get(
      `/api/Cobrador/GetZonasForSelect`
    );
    const rubroResponse = await axiosInstance.get(
      `/api/Rubro/GetRubrosForSelect`
    );

    setZonas(zonaResponse.data);
    setRubros(rubroResponse.data);
  };

  const onChange = (event, value) => {
    if (event.target.type == "checkbox") {
      setProspecto({
        ...prospecto,
        [event.target.name]: event.target.checked,
      });
    } else {
      setProspecto({
        ...prospecto,
        [event.target.name]: event.target.value,
      });
    }
  };

  const updateDocumentCallBack = (imagen) => {
    setImagenes([...imagenes, imagen]);
  };

  const updateDocumentCallBackRemove = (imagen) => {
    setImagenes(
      imagenes.filter(function (imagen2) {
        return imagen2 !== imagen;
      })
    );
  };

  const updateArticuloCallBack = (articulo) => {
    const newState = articulos.map((obj) => {
      if (obj.idArticulo == articulo.idArticulo) {
        return { ...obj, meInteresa: true };
      }
      return obj;
    });

    setArticulos(newState);

    //setArticulosSeleccionados([...articulosSeleccionados, articulo]);

    setArticulos([...articulos, articulo]);
  };

  const updateArticuloCallBackRemove = (articulo) => {
    const newState = articulos.filter(function (articulo2) {
      return articulo2.idArticulo !== articulo.idArticulo;
    });

    setArticulos(newState);
  };

  const handleRadioChange = (event) => {
    setProspecto({
      ...prospecto,
      [event.target.name]: event.target.value,
    });
  };

  // const centerMap = () => {
  //   if (coords) {
  //     var _center = {
  //       lat: coords.latitude,
  //       lng: coords.longitude,
  //     };

  //     setCenter(_center);
  //     setZoom(14);
  //   }
  // };

  const handleGoogleApiLoaded = () => {
    alert("loaded");
  };

  const onAddressChange = (address) => {
    console.log("onAddressChange: ", address);
    setValue(address);

    setProspecto({
      ...prospecto,
      direccionCompleta: address.label,
      direccionGoogle: address,
    });
  };

  const buscarDireccionEnMapa = () => {
    if (prospecto.direccionGoogle) {
      console.log("prospecto asd: ", prospecto.direccionGoogle.label);

      setProspecto({
        ...prospecto,
        direccionCompleta: `${prospecto.direccionGoogle.label}`,
      });
    }
  };

  return (
    <Container maxWidth="md" style={{ paddingLeft: "0", paddingRight: "0" }}>
      <Link to={paths.list}>
        <Button
          variant="text"
          color="primary"
          size="medium"
          startIcon={<ArrowBack />}
        >
          {paths.label}
        </Button>
      </Link>

      <LoadWrapper loading={loading} error={error}>
        <ValidatorForm
          onSubmit={onSubmit}
          onError={(errors) => console.log(errors)}
        >
          <Paper elevation={0} className="paper mt-20">
            <div className="paper-header">
              <h3 className="mt-0"></h3>
            </div>

            <div className="paper-body">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextValidator
                    name="apellidoNombre"
                    className="w-100"
                    size="medium"
                    label="APELLIDO, Nombre"
                    variant="outlined"
                    value={prospecto.apellidoNombre}
                    onChange={onChange}
                    validators={["maxStringLength:70"]}
                    errorMessages={["Máximo 70 carácteres"]}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <SelectValidator
                    className="w-100"
                    name="zona"
                    variant={"outlined"}
                    size={"medium"}
                    select
                    label="Zona"
                    value={prospecto.zona}
                    onChange={onChange}
                  >
                    {renderZonaOptions()}
                  </SelectValidator>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextValidator
                    name="numeroCelular"
                    className="w-100"
                    size="medium"
                    label="Número celular"
                    variant="outlined"
                    value={prospecto.numeroCelular}
                    onChange={onChange}
                    validators={[
                      "maxStringLength:20",
                      "matchRegexp:^[0-9]+(.[0-9]{1,2})?$",
                    ]}
                    errorMessages={["Máximo 20 carácteres", "Solo números"]}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <SelectValidator
                    className="w-100"
                    name="idRubro"
                    variant={"outlined"}
                    size={"medium"}
                    select
                    label="Rubro"
                    value={prospecto.idRubro}
                    onChange={onChange}
                  >
                    {renderRubroOptions()}
                  </SelectValidator>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={10} xl={10}>
                      <GooglePlacesAutocomplete
                        minLengthAutocomplete="5"
                        apiKey="AIzaSyAVuddcpI18nKCGjclB_m1yYf6J6YTDWxM"
                        selectProps={{
                          placeholder: "Buscar dirección...",
                          value,
                          onChange: onAddressChange,
                        }}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ["ar"],
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} sm={6} md={2} xl={2}>
                      <Button
                        variant="contained"
                        onClick={buscarDireccionEnMapa}
                        fullWidth
                      >
                        Buscar
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <p>{prospecto.direccionComercial}</p>
                </Grid>

                {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button variant="outlined" onClick={centerMap}>
                    Centrar mapa mi posición
                  </Button>
                </Grid>
 */}

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <GoogleMapsWrapperClickable
                    latitud={prospecto.latitud}
                    longitud={prospecto.longitud}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}></Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Interesado en
                      </FormLabel>
                      <br />
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="interes"
                          value={prospecto.interes}
                          onChange={handleRadioChange}
                          row
                        >
                          <FormControlLabel
                            value="producto"
                            control={<Radio />}
                            label="Producto"
                          />
                          <FormControlLabel
                            value="credito"
                            control={<Radio />}
                            label="Crédito"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Grado de interés
                      </FormLabel>
                      <br />
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="gradoDeInteres"
                          value={prospecto.gradoDeInteres}
                          onChange={handleRadioChange}
                          row
                        >
                          <FormControlLabel
                            value="5"
                            control={<Radio />}
                            label="Alto interés"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Medio interés"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Bajo interés"
                          />

                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Sin interés"
                          />

                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Sin respuesta / No sabe"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    name="observacion"
                    className="w-100"
                    size="medium"
                    label="Observación"
                    variant="outlined"
                    value={prospecto.observacion}
                    multiline
                    rows={4}
                    onChange={onChange}
                    validators={["maxStringLength:400"]}
                    errorMessages={["Máximo 400 carácteres"]}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>

          <InteraccionesCliente
            ref={childRef}
            data={prospecto.apellidoNombre == "" ? [] : prospecto.interacciones}
          />

          <Paper elevation={0} className="paper mt-20">
            <CustomImageGallery
              updateDocumentCallBack={updateDocumentCallBack}
              updateDocumentCallBackRemove={updateDocumentCallBackRemove}
              imagenes={prospecto.imagenes}
              cantidadLimite={4}
              getAction="GetProspectoImage"
              saveAction="SaveProspectoImage"
              deleteAction="DeleteProspectoImage"
              rotateAction="RotateProspectoImage"
            />
          </Paper>

          <div className="mt-20">
            <Grid container spacing={3}>
              <Grid item>
                <SaveButton />
              </Grid>
              <Grid item>
                <CancelButton to={paths.list} />
              </Grid>
            </Grid>
          </div>
        </ValidatorForm>
      </LoadWrapper>

      <br />
      <br />
      <br />
    </Container>
  );
}
