
import CurrencyFormat from "react-currency-format";


export function formatCurrency(value, decimalScale = 2) {
    return(
        <CurrencyFormat
        value={value}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"$"}
        decimalScale={decimalScale}
      />
    );
}

export function formatPercent(value) {



    return(
        <CurrencyFormat
        value={value}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        suffix={" %"}
        decimalScale={1}
      />
    );
}