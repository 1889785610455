import { Paper } from "@mui/material/";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import GoogleMapsWrapper from "components/geo/googleMapsWrapper";

export default function ClienteLogistica(props) {
  const renderRow = (label, value) => {
    return (
      <TableRow sx={{ "& .MuiTableCell-root": { padding: "8px 24px" } }}>
        <TableCell align="left" width="40%">
          <span className="table-cell-left">{label}</span>
        </TableCell>
        <TableCell align="left" width="60%">
          <span className="table-cell-right">{value}</span>
        </TableCell>
      </TableRow>
    );
  };

  var cliente = props.cliente;

  return (
    <Paper elevation={1} className="paper">
      <div className="paper-header">
        <h3 className="mt-0">
          Cliente{" "}
          <small style={{ fontSize: "1rem" }}>#{cliente.idCliente}</small>
        </h3>
      </div>

      <Table aria-label="simple table" className="mt-10">
        <TableBody>
          {renderRow("Nombre", cliente.apellidoNombre)}

          {renderRow("Rubro comercial", cliente.rubro)}

          {renderRow("Domicilio particular", cliente.domicilioParticular)}

          {renderRow("Teléfono particular", cliente.telefonoParticular)}

          {renderRow("Teléfono comercial", cliente.telefonoComercial)}

          {renderRow("Teléfono fijo", cliente.telefonoFijo)}

          {renderRow("Celular actualizado", cliente.celularActualizado)}

          {renderRow("Zona", cliente.zona)}

          {renderRow("Dirección Google Maps", cliente.direccionCompleta)}

          {renderRow("Latitud", cliente.latitud)}

          {renderRow("Longitud", cliente.longitud)}
        </TableBody>
      </Table>

      <GoogleMapsWrapper
        markers={[cliente.marker]}
        defaultIcon
      />
    </Paper>
  );
}
