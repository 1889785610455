import { axiosInstance } from "./api/axiosInstance";
import apiClient from "./api/apiClient";


export const getBalance = async () => {
  return await apiClient("GET", `/api/AsientosManuales/GetBalance`);
};

export const CuentaService = {
  getById: async (id) => {
    return await apiClient("GET", `/api/cuenta/${id}`);
  },
  getCuentasEgresos: async () => {
    return await apiClient("GET", `/api/cuenta/GetCuentasEgresos`);
  },
  save: async (data) => {
    return await apiClient("POST", "/api/Cuenta/", data);
  },
  update: async (id, data) => {
    return await apiClient("PUT", `/api/Cuenta/${id}`, data);
  },
  delete: async (id) => {
    return await apiClient("DELETE", `/api/Cuenta/${id}`, null);
  },
  getAllForSelect: async () => {
    return await apiClient("GET", `/api/cuenta/GetAllCuentasForSelect`);
  },
  getCuentasForSelect: async () => {
    return await apiClient("GET", `/api/cuenta/GetCuentasForSelect`);
  },
  getCuentasForAutocomplete: async () => {
    return await apiClient("GET", `/api/cuenta/GetCuentasForAutocomplete`);
  },
  getTreeView: async () => {
    return await apiClient("GET", `/api/cuenta/GetTreeView`);
  },
};

export const AsientoPrearmadoService = {
  getGrupoPerdidaGananciasForSelect: async () => {
    return await apiClient("GET",`/api/GrupoPerdidaGanancia/GetGrupoPerdidaGananciasForSelect`);
  },
  getById: async (id) => {
    return await apiClient("GET", `/api/AsientoPrearmado/${id}`);
  },
  save: async (data) => {    
    return apiClient("POST", "/api/AsientoPrearmado/", data);

  },
  update: async (id, data) => {    
    return apiClient("PUT", `/api/AsientoPrearmado/${id}`, data);
  },
  delete: async (id) => {    
    return apiClient("DELETE", `/api/AsientoPrearmado/${id}`, null);

  },
};

export const AgrupacionesCuentasService = {
  getById: async (id) => {
    return await apiClient("GET", `/api/AgrupacionesDeCuentas/${id}`);
  },
  getCuentasEgresos: async () => {
    return await apiClient("GET", `/api/Cuenta/GetCuentasEgresos`);
  },
  save: async (data) => {
    return await apiClient("POST", "/api/AgrupacionesDeCuentas/", data);
  },
  update: async (id, data) => {
    return await apiClient("PUT", `/api/AgrupacionesDeCuentas/${id}`, data);
  },
  delete: async (id) => {
    return await apiClient("DELETE", `/api/AgrupacionesDeCuentas/${id}`, null);
  },
};

export const CajaTesoreriaService = {
  getById: async (id) => {
    return await apiClient("GET", `/api/AsientosManualesTesoreria/${id}`);
  },
  getSaldo: async (idCuenta) => {
    return await apiClient(
      "GET",
      `/api/AsientosManualesTesoreria/GetSaldo/${idCuenta}`
    );
  },
  getProveedoresForSelect: async () => {
    return await apiClient(
      "GET",
      `/api/AsientosManualesTesoreria/GetProveedoresForSelect`
    );
  },

  getSubTotal: async(params) => {
    return await apiClient("GET",`/api/AsientosManualesTesoreria/GetSubTotal?1=1${params}` );
  },

  getFleterosForSelect: async () => {
    return await apiClient(
      "GET",
      `/api/AsientosManualesTesoreria/GetFleterosForSelect`
    );
  },
  getAsientosPrearmadosForSelect: async () => {
    return await apiClient(
      "GET",
      `/api/AsientoPrearmado/GetMovimientoCajaTiposForModalSelect`
    );
  },
  getSolicitudesAprobadasYEntregadasForSelect: async (idAsientoPrearmado) => {
    return await apiClient(
      "GET",
      `/api/AsientosManualesTesoreria/GetSolicitudesAprobadasYEntregadasForSelect/${idAsientoPrearmado}`
    );
  },
  getCuentasDebitosByAsientoPrearmado: async (idAsientoPrearmado) => {
    return await apiClient(
      "GET",
      `/api/AsientosManualesTesoreria/GetCuentasDebitos/${idAsientoPrearmado}`
    );
  },
  getResumen: async () => {
    return await apiClient("GET", `/api/AsientosManualesTesoreria/GetResumen/`);
  },
  getEstado: async () => {
    return await apiClient("GET", `/api/AsientosManualesTesoreria/GetEstadoCaja/`);
  },
  getArticulos: async (articuloText) => {
    return await apiClient(
      "GET",
      `/api/AsientosManualesTesoreria/GetArticulos/${articuloText}`
    );
  },
  save: async (data) => {
    return await apiClient("POST", "/api/AsientosManualesTesoreria/", data);
  },
  update: async (id, data) => {
    return await apiClient("PUT", `/api/AsientosManualesTesoreria/${id}`, data);
  },
  delete: async (id) => {
    return await apiClient("DELETE",`/api/AsientosManualesTesoreria/${id}`, null);
  },
  generateExcel: async (params) => {
    return await axiosInstance({
      method: "GET",
      url: `/api/AsientosManualesTesoreria/GenerateExcel?1=1${params}`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "cajaTesoreria.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },
};

export const AsientosManualesService = {
  getById: async (id) => {
    return await apiClient("GET", `/api/AsientosManuales/${id}`);
  },
  getUsuarios: async () => {
    return await apiClient("GET",`/api/AsientosManuales/GetUsuariosModuloCaja`);
  },
  getTipoMovimientosCajaForSelect: async () => {
    return await apiClient("GET",`/api/AsientosManuales/GetTipoMovimientosCajaForSelect`);
  },
  abrirCaja: async () => {
    return await apiClient("POST", `/api/AsientosManuales/abrirCaja`, null);
  },
  cerrarCaja: async () => {
    return await apiClient("POST", `/api/AsientosManuales/cerrarCaja`, null);
  },
  save: async (data) => {
    return await apiClient("POST", "/api/AsientosManuales/SaveAsientos", data);
  },
  delete: async (id) => {
    return await apiClient("DELETE", `/api/AsientosManuales/${id}`, null);
  },
   getBalance: (params) => {
    return apiClient("GET",`/api/AsientosManuales/GetBalance?${params}`);
  },
  getBalanceDisponibilidad: async (params) => {
    return await apiClient("GET",`/api/AsientosManuales/GetBalanceDisponibilidad?${params}`);
  },

  generateReporteFleterosExcel: async (params) => {
    return await axiosInstance({
      method: "GET",
      url: `/api/AsientosManuales/GenerateReporteFleterosExcel?${params}`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "reporteFleteros.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },
};
