import React, { Component, Fragment } from "react";
import { Grid, Typography } from "@mui/material/";
import Switch from "@mui/material/Switch";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";

export class EstadoCaja extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  async handleChange(e) {}

  render() {
    var estado = this.props.estado;

    return (
      <Fragment>
        {estado === null ? (
          <div></div>
        ) : (
          <Grid
            container
            style={{
              backgroundColor: "#fff",
              border: "1px solid #ddd",
              color: "#333",
              padding: "0.8rem",
              borderRadius: "0.75rem",
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            

            {/* {this.props.puedeOperar ? (
              <Grid item>
                <Switch
                  onChange={(e) => this.handleChange(e)}
                  checked={estado.cerrada}
                  onColor="#b3ffcc"
                  onHandleColor="#00802b"
                  offColor="#ffcccc"
                  offHandleColor="#e60000"
                  handleDiameter={17}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={10}
                  width={30}
                  className="react-switch"
                />
              </Grid>
            ) : (
              <Grid item>
                {estado.cerrada ? (
                  <FaLock style={{ fontSize: "2rem", color: "#8B0000" }} />
                ) : (
                  <FaLockOpen style={{ fontSize: "2rem", color: "green" }} />
                )}
              </Grid>
            )} */}

            {this.props.children}
          </Grid>
        )}
      </Fragment>
    );
  }
}
