import React, { useState, useEffect } from "react";

import "./App.css";
import "typeface-roboto";
import "./assets/scss/layout.css";
import "bootstrap/dist/css/bootstrap.css";
import MiniDrawer from "./layout/layout2";

import { Routes, useNavigate } from "react-router-dom";

import { axiosInstance } from "./services/api/axiosInstance";
import { setToken, getToken } from "./helpers/auth-helper";
import Login from "./components/forms/Login";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";

import { ThemeProvider, CssBaseline } from "@mui/material/";
import darkTheme from "./themes/dark";
import lightTheme from "./themes/light";
import crediarioTheme from "./themes/crediario";

import grupoWestRoutes from "./routes/grupoWestRoutes";
import systemRoutes from "./routes/systemRoutes";
import LinearProgress from "@mui/material/LinearProgress";
import { UserProvider } from "./userContext";



function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [menu, setMenu] = useState(false);
  const [login, setLogin] = useState(false);
  const [stateToken, setStateToken] = useState("");
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function getUseEffectToken() {
      var token = await getToken();
      setStateToken(token);
    }

    getUseEffectToken();

    if (stateToken === null) {
      setToken("");
      setLogin(false);
    } else {
      axiosInstance
        .get("/api/auth/GetUserProfile", {
          headers: { Authorization: `Bearer ${stateToken}` },
        })
        .then((response) => {


          var user = {
            nombre: response.data.nombre,
            cargo: response.data.cargo,
            roles: response.data.roles,
            token: response.data.token,
            menu: response.data.menu,
          };

          setUser(user);
          setLogin(true);
        })
        .catch((error) => {
          setLogin(false);
        });

      //await this.GetMenu();
    }
  }, [login]);

  const loginCallBack = (data) => {
    
    if (data === false) {
      
      setLogin(false);
      
    } else {
            
      setToken(data.token);

      var userObject = {
        nombre: data.nombre,
        cargo: data.cargo,
        roles: data.roles,
        token: data.token,
        menu: data.menu
      };
    
      setUser(userObject);
      setLogin(true);
    }
  };

  const logoutCallBack = () => {
    setUser(null);

    setToken("");
  };


  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />

      <LocalizationProvider dateAdapter={DateAdapter}>
        {user ? (
          <UserProvider value={user}>
            <MiniDrawer
              darkMode={darkMode}
              setDarkMode={setDarkMode}
              //login={login}
              logoutClickCallback={logoutCallBack}
              user={user}
            >
              <React.Suspense fallback={<LinearProgress />}>
                <Routes>
                  {systemRoutes}
                  {grupoWestRoutes}
                </Routes>
              </React.Suspense>
            </MiniDrawer>
          </UserProvider>
        ) : (
          <Login login={loginCallBack} />
        )}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
