import React, { Component, Fragment } from "react";
import { axiosInstance } from "../../services/api/axiosInstance";
import { MenuItem, TextField } from "@mui/material/";

class VendedorSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idText: this.props.idText === undefined ? false : this.props.idText,
      label: this.props.label === undefined ? "Vendedor" : this.props.label,

      idVendedor: this.props.defaultValue,
      vendedores: [],
    };
  }

  onChange = async (e) => {
    this.setState({
      idVendedor: e.target.value,
    });

    this.props.onSelectChange(e);
  };

  renderOptions() {
    return this.state.vendedores.map((dt, i) => {
      return (
        <MenuItem value={dt.id} key={i} name={dt.text}>
          {dt.text}
        </MenuItem>
      );
    });
  }

  async componentDidMount() {
    var vendedores;

    if (this.state.idText) {
      vendedores = await axiosInstance.get(
        `/api/Vendedor/GetVendedoresForSelectIdText`
      );
    } else {
      vendedores = await axiosInstance.get(
        `/api/Vendedor/GetVendedoresForSelect`
      );
    }

    this.setState({
      vendedores: vendedores.data,
    });
  }

  render() {
    return (
      <Fragment>
        <TextField
          style={{ width: "250px", marginTop: "3px" }}
          name="idVendedor"
          variant="standard"
          size="small"
          select
          label={this.state.label}
          value={this.state.idVendedor}
          onChange={this.onChange}
        >
          <MenuItem value={0}>Todos</MenuItem>

          {this.renderOptions()}
        </TextField>
      </Fragment>
    );
  }
}

export default VendedorSelect;
