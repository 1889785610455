import React, { Component } from "react";

import {
  Grid,
  Button,
  Paper,
  Tooltip,
  TextField,
  IconButton,
  Chip,
  Typography,
} from "@mui/material/";
import { Link } from "react-router-dom";
import {
  Edit,
  Check,
  Cancel,
  RemoveRedEye,
  FindInPage,
  Save,
  Brightness1,
} from "@mui/icons-material/";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import esLocale from "date-fns/locale/es";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { styled } from "@mui/styles";
import { AiFillFileExcel } from "react-icons/ai";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import routeConfig from "../../../constants/routeConfig";
import EquipoVentaSelect from "../../../components/formComponents/equipoVentaSelect";
import CumplioObjetivoSelect from "../../../components/formComponents/cumplioObjetivoSelect";
import VendedorAutocomplete from "../../../components/formComponents/vendedorAutocomplete";
import { ExcelButton } from "../../../components/buttons";
import {
  formatDate,
  jsDateToLocalISO8601DateString,
} from "../../../helpers/dateTimeHelper";

import { formatCurrency } from "../../../helpers/currencyPercent-helper";

import { ComisionesVendedoresService } from "../../../services/moduloComisionesService";

import DetalleVentasVendedorModal from "../../../components/widgets/comisiones/DetalleVentasVendedorModal";

const paths = routeConfig.comisionesVendedores;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    padding: "4px 9px",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: 4,
  },
}));

const borderRight = {
  borderRight: "1px solid rgba(224, 224, 224, 1)",
};

export class ComisionesVendedores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      fecha: this.getMonday(new Date()),
      idEquipoDeVenta: null,
      cumpleObjetivo: null,
      idVendedor: null,
      vendedor: '',

      equiposDeVenta: [],
      solicitudesAprobadas: [],
      detalles: [],
      dialogOpen: false,
      solicitudSelected: null,
      totales: null,

      detalleVentas: [],
      detalleDescuentos: [],
      solicitudesADescontar: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleExcelClick = this.handleExcelClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.closeModalDetalleCallBack = this.closeModalDetalleCallBack.bind(this);
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  async componentDidMount() {
    this.getSolicitudesAprobadas();

    const columns = [
      {
        id: "idEscalaComisionVenta",
        name: "Id",
        selector: (row) => row.idEscalaComisionVenta,
        sortable: true,
        width: "90px",
        hide: "sm",
      },
      {
        id: "ventaDesde",
        name: "Desde",
        selector: (row) => row.ventaDesde,
        sortable: true,
        width: "120px",
      },

      {
        id: "ventaHasta",
        name: "Hasta",
        selector: (row) => row.ventaHasta,
        sortable: true,
      },

      {
        id: "comision",
        name: "comisión",
        selector: (row) => row.comision,
        sortable: true,
        width: "120px",
      },

      {
        id: "habilitada",
        name: "habilitada",
        selector: (row) => row.habilitada,
        sortable: true,
        cell: (d) =>
          d.habilitada ? <Check style={{ color: "green" }} /> : <Cancel />,
      },

      {
        name: "Acciones",
        button: true,
        cell: (row) => (
          <Link to={paths.edit + "/" + row.idEscalaComisionVenta}>
            <Tooltip title="Editar">
              <IconButton aria-label="Editar">
                <Edit />
              </IconButton>
            </Tooltip>
          </Link>
        ),
        allowOverflow: true,
        width: "70px",
      },
      {
        cell: (row) => (
          <Link to={paths.view + "/" + row.idEscalaComisionVenta}>
            <Tooltip title="Ver">
              <IconButton aria-label="ver">
                <RemoveRedEye />
              </IconButton>
            </Tooltip>
          </Link>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "60px",
      },
    ];

    this.setState({
      columns: columns,
    });
  }

  disableWeekends(date) {
    return date.getDay() !== 1;
  }

  async getSolicitudesAprobadas() {
    this.setState({
      solicitudesAprobadas: [],
      totales: null,
    });

    var data = {
      fecha: jsDateToLocalISO8601DateString(this.state.fecha),
      idEquipoDeVenta: this.state.idEquipoDeVenta,
      cumpleObjetivo: this.state.cumpleObjetivo,
      idVendedor: this.state.idVendedor,
    };

    const comisiones =
      await ComisionesVendedoresService.calcularComisionVendedoresSemanal(data);

    this.setState({
      solicitudesAprobadas: comisiones.comisiones,
      totales: comisiones.totales,
    });
  }

  onChangeDate = (newValue) => {
    this.setState(
      {
        fecha: newValue,
      },
      () => {
        this.getSolicitudesAprobadas();
      }
    );
  };

  async handleDetallesClick(solicitud) {
    var data = {
      fecha: jsDateToLocalISO8601DateString(this.state.fecha),
      idVendedor: solicitud.idVendedor,
    };

    const detalleResponse =
      await ComisionesVendedoresService.getDetalleVentasPorVendedor(data);

    this.setState({
      //detalles: detalles.data,
      dialogOpen: true,
      vendedor: solicitud.vendedor,
      detalleVentas: detalleResponse.solicitudesAprobadas,
      detalleDescuentos: detalleResponse.operacionesADescontar,
      solicitudesADescontar: detalleResponse.solicitudesADescontar,
    });
  }

  onSelectEquipoVentaChange = (e) => {
    this.setState(
      {
        idEquipoDeVenta: e.target.value === 0 ? null : e.target.value,
      },
      () => {
        this.getSolicitudesAprobadas();
      }
    );
  };
  onSelectCumpleObjetivoChange = (e) => {
    this.setState(
      {
        cumpleObjetivo: e.target.value === 0 ? null : e.target.value,
      },
      () => {
        this.getSolicitudesAprobadas();
      }
    );
  };

  onSelectVendedorChange = (e) => {
    this.setState(
      {
        idVendedor: e === 0 ? null : e,
      },
      () => {
        this.getSolicitudesAprobadas();
      }
    );
  };

  handleClose() {
    this.setState({
      dialogOpen: false,
    });
  }

  handleExcelClick(e) {
    var params = "fecha=" + formatDate(this.state.fecha);

    if (this.state.idEquipoDeVenta !== null) {
      params += "&IdEquipoVenta=" + this.state.idEquipoDeVenta;
    }

    if (this.state.idVendedor !== null) {
      params += "&idVendedor=" + this.state.idVendedor;
    }

    if (this.state.cumpleObjetivo !== null) {
      params += "&cumpleObjetivo=" + this.state.cumpleObjetivo;
    }

    ComisionesVendedoresService.generateExcel(params);
  }

  handleSaveClick() {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "question",
      title: "¿Confirma guardar la captura de comisiones?",

      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Guardar captura",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          fecha: jsDateToLocalISO8601DateString(this.state.fecha),
          idEquipoDeVenta: this.state.idEquipoDeVenta,
          cumpleObjetivo: this.state.cumpleObjetivo,
          idVendedor: this.state.idVendedor,
        };

        var isOk = ComisionesVendedoresService.save(data);
      }
    });
  }

  closeModalDetalleCallBack() {
    this.setState({
      dialogOpen: false,
    });
  }

  render() {
    var title = paths.label;

    const sumaVenta = this.state.detalleVentas
      .filter((s) => s.cancelada === false)
      .reduce((accumulator, object) => {
        if (object.comisionaVentas) {
          return parseFloat(accumulator) + parseFloat(object.montoTotal);
        } else {
          return parseFloat(accumulator);
        }
      }, 0);

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={6}>
            <ExcelButton
              variant="text"
              size="small"
              startIcon={<AiFillFileExcel />}
              onClick={this.handleExcelClick}
              type="button"
              style={{ float: "right" }}
            >
              Exportar
            </ExcelButton>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              size="small"
              startIcon={<Save />}
              onClick={this.handleSaveClick}
              type="button"
              color="primary"
            >
              Guardar captura de comisiones
            </Button>
          </Grid>
        </Grid>

        <br />

        <Paper className="p-10 mb-20">
          <Grid container spacing={5}>
            <Grid item>
              <Typography variant="h5">
                Total comisiones:{" "}
                {this.state.totales !== null
                  ? formatCurrency(this.state.totales.total)
                  : formatCurrency(0)}{" "}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h5">
                %{" "}
                {this.state.totales !== null ? (
                  <span>{this.state.totales.porcentajeGlobal}</span>
                ) : (
                  <span>0</span>
                )}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper className="p-20 mt-20">
          <Grid container spacing={6}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item>
                  <LocalizationProvider
                    locale={esLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      variant="outlined"
                      orientation="portrait"
                      label="Semana del"
                      openTo="day"
                      value={this.state.fecha}
                      shouldDisableDate={this.disableWeekends}
                      onChange={(newValue) => this.onChangeDate(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <EquipoVentaSelect
                    onSelectChange={this.onSelectEquipoVentaChange}
                  />
                </Grid>
                <Grid item>
                  <CumplioObjetivoSelect
                    onSelectChange={this.onSelectCumpleObjetivoChange}
                  />
                </Grid>
                <Grid item>
                  <VendedorAutocomplete
                    onSelectChange={this.onSelectVendedorChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6}></TableCell>
                    <TableCell colSpan={2} align="center">
                      Objetivo
                    </TableCell>

                    <TableCell align="center" colSpan={2}>
                      Comisión venta
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                      Comisión objetivo
                    </TableCell>
                    <TableCell align="center" colSpan={5}></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">
                      Cumple <br /> objetivo
                    </TableCell>
                    <TableCell align="center">Equipo</TableCell>

                    <TableCell align="left">Nombre del asesor</TableCell>
                    <TableCell align="center">
                      Clientes <br />
                      nuevos
                    </TableCell>
                    <TableCell align="right">Ventas netas</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="center">$</TableCell>
                    <TableCell align="center">%</TableCell>

                    <TableCell align="center">%</TableCell>
                    <TableCell align="center">$</TableCell>
                    <TableCell align="center">%</TableCell>
                    <TableCell align="center">$</TableCell>
                    <TableCell align="center">
                      Comisión <br />
                      cliente nuevo
                    </TableCell>
                    <TableCell align="right">
                      Subtotal <br /> comisión
                    </TableCell>
                    <TableCell align="right">Descuentos</TableCell>
                    <TableCell align="right">
                      Comisión <br />
                      producto usado
                    </TableCell>
                    <TableCell align="right">
                      Total <br /> comisión{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.solicitudesAprobadas.map((solicitud, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" style={borderRight}>
                        {solicitud.cumpleObjetivo ? (
                          <Check style={{ color: "green" }} />
                        ) : (
                          <Cancel size="small" style={{ color: "red" }} />
                        )}
                      </TableCell>

                      <TableCell className="fs-10" component="th" scope="row">
                        {solicitud.equipoVenta}
                      </TableCell>

                      <TableCell
                        className="fs-10"
                        component="th"
                        scope="row"
                        style={borderRight}
                      >
                        {solicitud.vendedor}
                      </TableCell>

                      <TableCell align="center">
                        {solicitud.cantidadSolicitudesDeClientesNuevos === 0
                          ? ""
                          : solicitud.cantidadSolicitudesDeClientesNuevos}
                      </TableCell>

                      <TableCell align="right">
                        {formatCurrency(solicitud.montoTotal)}
                      </TableCell>

                      <TableCell align="center" style={borderRight}>
                        <Tooltip title="Ver detalles">
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleDetallesClick(solicitud)}
                            style={{ padding: "0px" }}
                          >
                            <FindInPage />
                          </IconButton>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="right">
                        {formatCurrency(solicitud.objetivo)}
                      </TableCell>

                      {solicitud.cumpleObjetivo ? (
                        <TableCell
                          align="right"
                          style={{
                            borderRight: "2px solid rgba(224, 224, 224, 1)",
                            color: "green",
                          }}
                        >
                          {solicitud.objetivoPorcentaje + " %"}
                        </TableCell>
                      ) : (
                        <TableCell align="right" style={borderRight}>
                          {solicitud.objetivoPorcentaje + " %"}
                        </TableCell>
                      )}

                      <TableCell align="right">
                        {" "}
                        {solicitud.comisionPorcentaje + " %"}{" "}
                      </TableCell>
                      <TableCell align="right" style={borderRight}>
                        {formatCurrency(solicitud.comisionVenta)}
                      </TableCell>

                      {solicitud.cumpleObjetivo ? (
                        <TableCell align="center" style={{ color: "green" }}>
                          {solicitud.comisionObjetivoPorcentaje + " %"}
                        </TableCell>
                      ) : (
                        <TableCell align="center"></TableCell>
                      )}

                      {solicitud.cumpleObjetivo ? (
                        <TableCell
                          align="right"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            color: "green",
                          }}
                        >
                          {formatCurrency(solicitud.comisionObjetivo)}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="right"
                          style={borderRight}
                        ></TableCell>
                      )}

                      {solicitud.comisionClienteNuevo === 0 ? (
                        <TableCell
                          align="right"
                          style={borderRight}
                        ></TableCell>
                      ) : (
                        <TableCell
                          align="right"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            color: "green",
                          }}
                        >
                          {formatCurrency(solicitud.comisionClienteNuevo)}
                        </TableCell>
                      )}
                      <TableCell align="right">
                        {formatCurrency(solicitud.subtotalComision)}
                      </TableCell>

                      <TableCell align="right">
                        {formatCurrency(solicitud.descuentos)}
                      </TableCell>

                      <TableCell align="right">
                        {formatCurrency(solicitud.comisionProductoUsado)}
                      </TableCell>
                      <TableCell align="right">
                        <b>{formatCurrency(solicitud.totalComision)}</b>
                      </TableCell>
                    </TableRow>
                  ))}

                  {this.state.totales !== null ? (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <p style={{ color: "#666" }}>
                          Cantidad de registros{" "}
                          {this.state.solicitudesAprobadas.length}
                        </p>
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip title="Cantidad de clientes nuevos">
                          <b>{this.state.totales.clientesNuevos}</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Venta neta">
                          <b>
                            {formatCurrency(this.state.totales.ventasNetas)}
                          </b>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        <Tooltip title="Objetivo">
                          <b>{formatCurrency(this.state.totales.objetivo)}</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" colSpan={2}></TableCell>

                      <TableCell align="right">
                        <Tooltip title="Comisión por ventas">
                          <b>
                            {formatCurrency(this.state.totales.comisionVentas)}
                          </b>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        <Tooltip title="Comisión por cumplir objetivo">
                          <b>
                            {formatCurrency(
                              this.state.totales.comisionObejtivo
                            )}
                          </b>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Comisión por clientes nuevos">
                          <b>
                            {formatCurrency(
                              this.state.totales.comisionClienteNuevo
                            )}
                          </b>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Subtotal">
                          <b>{formatCurrency(this.state.totales.subTotal)}</b>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip title="Descuentos">
                          <b>{formatCurrency(this.state.totales.descuentos)}</b>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip title="Productos usados">
                          <b>{formatCurrency(0)}</b>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Total">
                          <b>{formatCurrency(this.state.totales.total)}</b>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Paper>

        <DetalleVentasVendedorModal
          open={this.state.dialogOpen}
          closeModalCallBack={this.closeModalDetalleCallBack}
          detalleVentas={this.state.detalleVentas}
          detalleDescuentos={this.state.detalleDescuentos}
          solicitudesADescontar={this.state.solicitudesADescontar}
          vendedor={this.state.vendedor}
          fecha={this.state.fecha}
        />
      </>
    );
  }
}
