import React from "react";
import { Grid, Paper, Typography } from "@mui/material/";
import Avatar from "@mui/material/Avatar";
import { formatCurrency } from "./../../../helpers/currencyPercent-helper";

export default function DashboardCard(props) {
  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: "0.75rem",
      }}
    >
      <div className="p-20">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <span className="dashboard-title">{props.title}</span>

            <Typography variant="h5">
              {props.valueType == "text" ? (
                <span>{props.value}</span>
              ) : (
                <span>{formatCurrency(props.value)}</span>
              )}
            </Typography>
            <p className="footer-text">
              {props.subtitle}
            </p>
          </Grid>
          <Grid item>
            <Avatar sx={{ bgcolor: props.color }}>{props.icon}</Avatar>
          </Grid>

        </Grid>
      </div>
    </Paper>
  );
}
