import swal from "sweetalert2";

export async function showDeleteDialog() {
  const result = await swal.fire({
    title: "¿Confirma la operación?",
    text: "Eliminando",
    icon: "warning",
    showCancelButton: true,

    confirmButtonText: "Si, eliminar",
    confirmButtonColor: "#b62d25",

    cancelButtonText: "Cancelar",
    
    cancelButtonColor: "#999",
  });
  return result;
}



export async function showDialog(title, text, confirmButtonText) {
  const result = await swal.fire({
    title: title,
    text: text,
    icon: "warning",
    showCancelButton: true,

    confirmButtonText: confirmButtonText,
    confirmButtonColor: "#1b5e20",

    cancelButtonText: "Cancelar",
    
    cancelButtonColor: "#999",
  });
  return result;
}



export async function showValidationDialog(text) {
  const result = await swal.fire({
    title: "Advertencia",
    html: text,
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: "Ok",    

  });
  return result;
}

