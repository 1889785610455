import apiClient from "./api/apiClient";
import { axiosInstance } from "./api/axiosInstance"

export const EscalaComisionService = {
  getById: async (id) => {    
    return await apiClient("GET", `/api/EscalaComisionVenta/${id}`);
  },
  save: async (data) => {
    return apiClient('POST', "/api/EscalaComisionVenta/", data);
  },
  update: async (id, data) => {
    return apiClient('PUT', `/api/EscalaComisionVenta/${id}`, data);
  },
  delete: async (id) => {
    return apiClient('DELETE', `/api/EscalaComisionVenta/${id}`, null);
  }
}

export const ParametrosService = {
  getById: async (id) => {    
    return await apiClient("GET", `/api/ComisionVendedorParametro/${id}`);

  }, 
  update: async (id, data) => {
    return apiClient('PUT', `/api/ComisionVendedorParametro/${id}`, data);
  }, 
}

export const ObjetivoVentaService = {
  getById: async (id) => {    
    return await apiClient("GET", `/api/ObjetivoVenta/${id}`);
  },
  getEquiposDeVentaForSelect: async () => {    
    return await apiClient("GET", `/api/ObjetivoVenta/GetEquiposDeVentaForSelect`);    
  },
  getVendedoresPorEquipo: async (idObjetivoVenta) => {    
    return await apiClient("GET", `/api/ObjetivoVenta/GetVendedoresPorEquipo/${idObjetivoVenta}`);    
  },

  save: async (data) => {
    return apiClient('POST', "/api/ObjetivoVenta/", data);
  },
  update: async (id, data) => {
    return apiClient('PUT', `/api/ObjetivoVenta/${id}`, data);
  },
  delete: async (id) => {
    return apiClient('DELETE', `/api/ObjetivoVenta/${id}`, null);
  }, 
  updateObjetivosVendedores: async (id, data) => {
    return apiClient('PUT', `/api/ObjetivoVenta/UpdateObjetivosVendedores/${id}`, data);
  },
}

export const ComisionesVendedoresService = {

  save: async (data) => {
    return apiClient('POST', "/api/ComisionesVendedores/GuardarCapturaComision", data);
  },

  calcularComisionVendedoresSemanal: async (data) => {
    return await axiosInstance.post(`/api/ComisionesVendedores/CalcularComisionVendedoresSemanal`, data).then(response => response.data);
  },

  getDetalleVentasPorVendedor: async (data) => {
    return await axiosInstance.post(`/api/ComisionesVendedores/GetDetalleVentasPorVendedor`, data).then(response => response.data);
  },

  generateExcel: async (params) => {
    return await axiosInstance({
      method: "GET",
      url: `/api/ComisionesVendedores/GenerateExcel?${params}`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "cajaTesoreria.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });;
  }, 
}

export const ComisionesVendedoresCapturaService = {

 

  generateExcel: async (params) => {
    return await axiosInstance({
      method: "GET",
      url: `/api/ComisionesVendedores/GenerateExcelCaptura?k=1${params}`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "captura.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });;
  },

  //generateRecibos: async 

 
}
