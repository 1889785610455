import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  DrawingManager,
  InfoWindowF,
} from "@react-google-maps/api";

import appConfig from "constants/appConfig";

const containerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "500px",
  maxHeight: "1000px",
  border: "1px solid #CCC",
  borderRadius: "13px",
};

export default function GoogleMapsWrapperClickable(props) {
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(11);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState(props.markers);
  const [mark, setMark] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAVuddcpI18nKCGjclB_m1yYf6J6YTDWxM",
  });

  useEffect(() => {
    var _center = {
      lat: props.latitud,
      lng: props.longitud,
    };
    setCenter(_center);
  }, [props.latitud, props.longitud]);

  const handleGoogleApiLoaded = () => {
    alert("loaded");
  };

  const onLoad = React.useCallback(function callback(map) {
    
    var center = appConfig.centerMapDefault;

    setCenter(center);

    //const bounds = new window.google.maps.LatLngBounds(_center);
    //map.fitBounds(bounds);
    map.setZoom(zoom);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMapClick = (e) => {
    setMarkers(() => [
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    ]);

    var marker = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
  };

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <div>
      <GoogleMap
        onGoogleApiLoaded={handleGoogleApiLoaded}
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
        //onClick={onMapClick}
        zoom={zoom}
      >
        <MarkerF   
          position={{
            lat: props.latitud,
            lng: props.longitud,
          }}
        ></MarkerF>
      </GoogleMap>
    </div>
  );
}
