import { createStore } from 'redux';



const reducer = (state = {}, action) => {
console.log("🚀 ~ file: store.js:6 ~ reducer ~ action:", action)


    switch (action.type) {
        case "SNACKBAR_SUCCESS":
            return {
                ...state,
                snackBarType: action.snackBarType,
                message: action.message
            };
      

        case "SNACKBAR_ERROR":
                return {
                    ...state,
                    snackBarType: action.snackBarType,
                    message: action.message
                };
        default:
            return state;
    }
  

    return state;
};

export default createStore(reducer, { snackBarSuccessIsOpen: false, snackBarMessage:'', snackBarType: '' });
//export default uiReducer;