import React from 'react'
import { Button } from "@mui/material/";
import ArrowBack from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

export default function SaveButton(props) {
  return (
    <Button
    variant="contained"
    color="primary"
    size="medium"
    startIcon={<SaveIcon />}
    type="submit"
    disabled={props.disabled == undefined ? false : props.disabled}
  >
    Guardar
  </Button>
  )
}
