import React from "react";
import { useTheme } from "@mui/styles";
import {
  Grid,
  Button,
  Paper,
  Tooltip,
  TextField,
  Avatar,
  Chip,
  Box,
  LinearProgress,
} from "@mui/material/";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CurrencyFormat from "react-currency-format";
import {
  Check,
  Cancel,
  Person,
  FindInPage,
  GetApp,
  Save,
  Brightness1,
} from "@mui/icons-material/";

import { ThemeProvider } from "@mui/system";

const hideColum = {
  display: { xl: "table-cell", lg: "table-cell", md: "table-cell", xs: "none" },
};

export default function PerformanceVendedoresTable(props) {
  const theme = useTheme();

  return (
    <div>
      {props.solicitudesAprobadas !== undefined &&
      props.solicitudesAprobadas !== null ? (
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Performance</TableCell>

              <TableCell>Vendedor</TableCell>

              <TableCell align="right" sx={hideColum}>Objetivo</TableCell>

              <TableCell align="right" sx={hideColum}>Prestamo</TableCell>

              <TableCell align="right" sx={hideColum}>Dif a vender</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.solicitudesAprobadas.map((solicitud, index) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  style={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {solicitud.cumpleObjetivo ? (
                    <Check style={{ color: "green" }} />
                  ) : (
                    <Cancel size="small" style={{ color: "red" }} />
                  )}
                </TableCell>

                <TableCell style={{ width: "100px" }}>
                  <Box sx={{ width: "100%" }}>
                    {solicitud.objetivoPorcentaje >= 100 ? (
                      <ThemeProvider theme={theme}>
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          color="success"
                        />
                      </ThemeProvider>
                    ) : (
                      <LinearProgress
                        variant="determinate"
                        value={solicitud.objetivoPorcentaje}
                      />
                    )}

                    <span
                      style={{
                        fontSize: "0.7rem",
                        color: solicitud.cumpleObjetivo ? "green" : "red",
                      }}
                    >
                      {solicitud.objetivoPorcentaje} %
                    </span>
                  </Box>
                </TableCell>

                <TableCell
                  className="fs-10"
                  component="th"
                  scope="row"
                  style={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {solicitud.vendedor} <br />
                  <span style={{ fontSize: "0.65rem" }}>
                    Equipo {solicitud.equipoVenta}
                  </span>
                  {solicitud.cantidadSolicitudesDeClientesNuevos > 0 ? (
                    <>
                      <br />
                      <Chip
                        size="small"
                        avatar={
                          <Avatar
                            style={{
                              color: "#155724",
                              backgroundColor: "#d4edda",
                              fontWeight: "800",
                              fontSize: "0.75rem",
                            }}
                          >
                            {solicitud.cantidadSolicitudesDeClientesNuevos}
                          </Avatar>
                        }
                        icon={<Person style={{ color: "#155724" }} />}
                        style={{
                          color: "#155724",
                          backgroundColor: "#d4edda",
                          marginTop: "10px",
                        }}
                        label="Cliente(s) nuevo(s)"
                      ></Chip>
                    </>
                  ) : null}
                </TableCell>

                <TableCell align="right" sx={hideColum}>
                  <CurrencyFormat
                    value={solicitud.objetivo}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                  />
                </TableCell>

                <TableCell align="right" sx={hideColum}>
                  <CurrencyFormat
                    value={solicitud.montoTotal}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                  />
                </TableCell>

                <TableCell align="right" sx={hideColum}>
                  {solicitud.diferenciaAVender < 0 ? (
                    <CurrencyFormat
                      style={{ color: "#E4392F" }}
                      value={solicitud.diferenciaAVender}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                    />
                  ) : (
                    <CurrencyFormat
                      value={solicitud.diferenciaAVender}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {/* <TableFooter>
        {this.state.totales !== null ? (
          <TableRow>
            <TableCell colSpan={5}></TableCell>
            <TableCell align="center">
              <Typography variant="h5">
                {this.state.totales.clientesNuevos} 
              </Typography>
            </TableCell>

            <TableCell align="right">
              <Typography variant="h5">
                <CurrencyFormat
                  value={this.state.totales.objetivo}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                />
              </Typography>
            </TableCell>

            <TableCell align="right">
              <Typography variant="h5">
                <CurrencyFormat
                  value={this.state.totales.ventasNetas}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                />
              </Typography>
            </TableCell>
            <TableCell align="right"></TableCell>

            <TableCell align="center" colSpan={2}></TableCell>
          </TableRow>
        ) : null}
      </TableFooter> */}
        </Table>
      ) : null}
    </div>
  );
}
