import React from 'react'
import { Button } from "@mui/material/";
import { Link } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

export default function CancelButton(props) {
  return (
     <Link to={props.to}>
            <Button
              type="button"
              startIcon={<CancelIcon />}
              variant="text"
              color="primary"
              size="large"
            >
              Cancelar
            </Button>
          </Link>
  )
}
