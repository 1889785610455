import { Grid, Paper, Typography, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Cancel, Edit } from "@mui/icons-material/";

export default function PaperEdit(props) {
  return (
    <Paper elevation={1} className="paper">
      <Grid
        container
        alignContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6" inline>
            {props.title}
          </Typography>
        </Grid>
        <Grid item>
          {props.isEdit ? (
            <Tooltip title="Cancelar">
              <IconButton onClick={props.isEditCallBack}>
                <Cancel />
              </IconButton>
            </Tooltip>
          ) : 
          
          props.canEdit &&

          (

            <Tooltip title="Editar">
              <IconButton onClick={props.isEditCallBack}>
                <Edit />
              </IconButton>
            </Tooltip>
          )
          
          
          }
        </Grid>
      </Grid>

      {props.children}
    </Paper>
  );
}
