import React, { Component } from "react";
import { axiosInstance } from "../../../services/api/axiosInstance";
import { Grid } from "@mui/material/";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import store from "../../../store";
import { LogisticaAPI as API } from "../../../apis/logisticaAPI";

export class EnviarAControlCrediticioModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
      idSolicitud: this.props.idSolicitud,
      observacion: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {}

  componentWillUpdate(nextProps, nextState) {
    nextState.open = nextProps.open;
  }

  handleRejectedModal(value) {
    this.setState({
      open: value,
    });
  }

  onChange = (event, value) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  async onSubmit() {
    var data = {
      observacion: this.state.observacion,
    };

    var response = await API.enviarAControlCrediticio(
      this.state.idSolicitud,
      data
    );

    if (response.status == "200") {
      store.dispatch({
        type: "SNACKBAR_SUCCESS",
        message: "Operación exitosa",
        snackBarType: "success",
      });

      this.props.submitCallBack();
      this.closeSendCreditVerificadorModal();
    }
  }

  closeSendCreditVerificadorModal() {
    
    this.props.closeModalCallBack();
  }

  render() {
    var open = this.state.open;

    return (
      <Dialog
        open={open}
        onClose={() => this.closeSendCreditVerificadorModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {"Enviar a calificación crediticia"}
        </DialogTitle>
        <ValidatorForm
          onSubmit={this.onSubmit}
          onError={(errors) => console.log(errors)}
          style={{ padding: "10px" }}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextValidator
                  id="observacion"
                  style={{
                    width: "100%",
                  }}
                  name="observacion"
                  label="Observación"
                  variant="outlined"
                  onChange={this.onChange}
                  multiline
                  rows={4}
                  value={this.state.observacion}
                  validators={["required", "maxStringLength:500"]}
                  errorMessages={[
                    "La observación es requerida",
                    "Máximo 500 carácteres",
                  ]}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" variant="contained" type="submit" autoFocus>
              Enviar a calificación crediticia
            </Button>
            <Button onClick={() => this.closeSendCreditVerificadorModal()}>
              Cancelar
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}
