import { createTheme } from "@mui/material/";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ec5990",
      contrast: "#fff",
    },
    warning: {
      main: "#e74c3c",
      contrast: "#fff",
    },

    secondary:{
      main:'#4974a5',
      contrast:'#fff'
    },
    delete: { 
      main:'#e64c43',
      contrast:'#fff'
   },

    background: {
      default: "#1c2025",
    },
    operacion: {
      main: '#fff3cd',
      contrastText: '#856404',
    },
    whatsApp:{
      main: '#128C7E',
      contrastText: '#fff',
    }
    ,

    grey:{
      main: '#999',
      contrastText: '#fff',
    } 
    ,

    lightGrey:{
      main: '#ABABAB',
      contrastText: '#fff',
    } 
  },


  
});
export default darkTheme;
