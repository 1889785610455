import React, { Component } from "react";
import {
  IconButton,
  Paper,
  Grid,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Button,
} from "@mui/material/";
import moment from "moment";
import "moment/locale/es";
import { RemoveRedEye } from "@mui/icons-material/";

import { Store } from "@mui/icons-material/";

import { VerificacionAmbientalForm } from "../../components/customer/verificacionAmbientalForm";
import { CustomModal } from "../../components/customModal";
import { axiosInstance } from "../../services/api/axiosInstance";

class VerificacionesAmbientalesCarpeta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //cliente: this.props.cliente != null ? this.props.cliente : null,
      modalVerificacionAmbientalOpen: false,
      verificacionAmbiental: null,
    };
  }

  async componentDidMount() {}

  async openModal(idVerificacionAmbiental) {
    const verificacionAmbientalResponse = await axiosInstance.get(
      `/api/Solicitud/GetVerificacionAmbiental/` + idVerificacionAmbiental
    );

    this.setState({
      modalVerificacionAmbientalOpen: true,
      verificacionAmbiental: verificacionAmbientalResponse.data,
    });
  }

  closeModal() {
    this.setState({
      modalVerificacionAmbientalOpen: false,
    });
  }

  render() {
    var verificacionesAmbientales = this.props.verificacionesAmbientales;
    return (
      <Paper className="paper">
        <Grid
          container
          style={{
            border: "1px solid #ccc",
            borderColor: "#fff",
            borderRadius: "50px",
            paddingRight: "15px",
          }}
          alignItems="center"
        >
          <Grid
            item
            style={{
              backgroundColor: "#333",
              borderRadius: "50px",
              padding: "7px",
            }}
          >
            <Store style={{ color: "#fff" }} />
          </Grid>
          <Grid item style={{ marginLeft: "8px" }}>
            <p
              style={{
                fontWeight: "500",
                fontSize: "0.85rem",
                color: "#333",
                margin: "0px",
              }}
            >
              Verificaciones ambientales
            </p>
          </Grid>
        </Grid>


        {verificacionesAmbientales !== null ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {verificacionesAmbientales.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ paddingLeft: "10px" }}>
                    <p className="long-text">{row.opinionDelVerificador}</p>
                    <Button
                      variant="text"
                      size="small"
                      color="secondary"
                      onClick={() =>
                        this.openModal(row.idVerificacionAmbiental)
                      }
                    >
                      Ver más
                    </Button>

                    <p
                      style={{
                        color: "#3E5060",
                        fontSize: "0.75rem",
                        float: "right",
                      }}
                    >
                      {moment(row.fecha).format("dddd DD MMMM YYYY")}
                    </p>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>Espere por favor...</p>
        )}

        <CustomModal
          title="Verificación ambiental"
          open={this.state.modalVerificacionAmbientalOpen}
          handleCustomModalCallBack={() => this.closeModal()}
        >
          <br />
          <VerificacionAmbientalForm
            verificacion={this.state.verificacionAmbiental}
            cliente={this.state.cliente}
            handleModalCallBack={() => this.closeModal()}
          />
        </CustomModal>
      </Paper>
    );
  }
}
export default VerificacionesAmbientalesCarpeta;
