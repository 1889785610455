import { axiosInstance } from "./axiosInstance";
import store from "../../store";

const apiClient = async (method, url, data) => {

  const config = {
    method: method,
    url: url,
    data: data,
  };

  if (method == "GET") {

    return await axiosInstance(config).then((response) => response.data);

  }
  else if(method == "POSTPROMISE"){

    config.method = "POST";
    return await axiosInstance(config);
   
  } else {
    var isOk = true;
    const response = await axiosInstance(config)
      .then((response) => {
        if (response.status == 200) {
          store.dispatch({
            type: "SNACKBAR_SUCCESS",
            message: "Operación exitosa",
            snackBarType: "success",
          });
        }

        isOk = true;
      })
      .catch((error) => {
        isOk = false;

        if (error.response) {
          store.dispatch({
            type: "SNACKBAR_SUCCESS",
            message: error.message,
            snackBarType: "error",
          });
        }
      });

    return isOk;
  }
};

export default apiClient;







