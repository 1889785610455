import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import DataTable, { createTheme } from "react-data-table-component";

import { TextField, Grid, LinearProgress, Paper, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withStyles } from "@mui/styles";
import { withTheme } from "styled-components";
import ExcelButton from "./../common/buttons/ExcelButton";
import PdfButton from "./../common/buttons/PdfButton";
import { Search } from "@mui/icons-material";

import { AiFillFileExcel, AiFillFilePdf, AiFillEyeInvisible } from "react-icons/ai";



const paginationOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: false,
  selectAllRowsItemText: "Todos",
};

const paginationServerOptions = {
  persistSelectedOnPageChange: true,
  persistSelectedOnSort: true,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "12px",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p>Espere por favor...</p>
      <LinearProgress />
    </div>
  );
};

createTheme("dark", {
  background: {
    default: "transparent",
  },
});

createTheme("light", {
  background: {
    default: "#fafbfb",
  },
});

const styles = (theme) => ({
  // here I can use theme provided by ThemeProvider
});


const TESTexpandableRowsComponent = ({ data }) => (

  
  <p>
    Mariano
  </p>
)

const CustomDataTableV2 = forwardRef((props, ref) => {
  const [filters, setFilters] = useState(props.filters);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortdirection] = useState("");

  const [query, setQuery] = useState("");
  // conditionalRowStyles: props.conditionalRowStyles,
  // elevation: props.elevation == null ? 1 : props.elevation,

  const [isGeneratingExcel, setIsGeneratingExcel] = useState(false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);


  useEffect(() => {
    getData();
  }, [page, sortDirection, perPage, query]);

  const handleOnChangeQuerySearch = async (event, value) => {
    setQuery(event.target.value);
  };

  const getData = async () => {
    setLoading(true);

    props
      .getPaginated({
        page: page,
        perPage: perPage,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        query: query,
        filters: props.filters,
      })
      .then((response) => {
        if (response !== undefined) {
          setData(response.data.data);
          setTotalRows(response.data.total);

          if (typeof props.totalCallBack === "function") {
            props.totalCallBack(response.data.totals);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useImperativeHandle(ref, (filters) => ({
    refresh(filters) {
      getData(filters);
    },
  }));

  const handleDataTablePageChange = async (page) => {
    setPage(page);
  };

  const handleDataTablePerRowsChange = async (perPage, page) => {
    setPerPage(perPage);
    setPage(page);
  };

  const handleSort = async (column, sortdirection) => {
    setSortColumn(column.id);
    setSortdirection(sortdirection);
  };

  const rendersubHeaderComponent = () => {
    if (true === true) {
      return (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            {props.showExportButton !== undefined && props.showExportButton ? (
              <>
                <ExcelButton
                  variant="outlined"
                  size="medium"
                  onClick={handleExcelClick}
                  type="button"
                  style={{ margin: "0px 10px 0px 0px" }}             

                  startIcon={
                    isGeneratingExcel ? (
                      <CircularProgress color="inherit" size="1.5rem" />
                    ) : (
                      <AiFillFileExcel />
                    )
                  }

                  disabled={isGeneratingExcel}

                >
                  { isGeneratingExcel ? "Generando" : "Excel"}
                </ExcelButton>

                <PdfButton
                  variant="outlined"
                  size="medium"
                  onClick={handlePdfClick}
                  type="button"
                  startIcon={
                    isGeneratingPdf ? (
                      <CircularProgress color="inherit" size="1.5rem" />
                    ) : (
                      <AiFillFilePdf />
                    )
                  }
                  disabled={isGeneratingPdf}

                                  >
                                    { isGeneratingPdf ? "Generando" : "Pdf"}

                </PdfButton>
              </>
            ) : null}
          </Grid>
          <Grid item>
            <TextField
              id="query"
              label="Búsqueda global"
              variant="outlined"
              size="small"
              name="query"
              style={{ float: "right" }}
              //onKeyUp={this.handleGlobalSearchInputKeyUp}
              onChange={handleOnChangeQuerySearch}
              value={query}
              title={"Búsqueda global"}
              InputProps={{
                endAdornment: (
                    <Search style={{color:'#888'}} />
                ),
              }}
            />
          </Grid>
        </Grid>
      );
    }
  };

  const handleExcelClick = async () => {

    setIsGeneratingExcel(true);



    var response =  await props.generateExcel({
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      query: query,
      filters: props.filters,
    });


    setIsGeneratingExcel(false);

  };

  const handlePdfClick = async () => {

    setIsGeneratingPdf(true);


    var response =  await props.generatePdf({
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      query: query,
      filters: props.filters,
    });

    setIsGeneratingPdf(false);

  };



  return (
    <Paper
      elevation={0}
      style={{  padding: "5px", borderRadius: "10px" }}
    >
      <DataTable
        columns={props.columns}
        data={data}
        noHeader
        paginationDefaultPage={page}
        paginationPerPage={perPage}
        highlightOnHover
        selectableRowsHighlight
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        //paginationComponentOptions={paginationOptions}
        //paginationServerOptions={paginationServerOptions}
        onSort={handleSort}
        sortServer
        onChangeRowsPerPage={handleDataTablePerRowsChange}
        onChangePage={handleDataTablePageChange}
        persistTableHead
        progressPending={loading}
        progressComponent={<LinearIndeterminate />}
        subHeader={true}
        subHeaderComponent={rendersubHeaderComponent()}
        conditionalRowStyles={props.conditionalRowStyles}
        //theme={theme.palette.mode == "dark" ? "dark" : "light"}
        selectAllRowsItem={false}
        expandableRows={props.expandableRows}
        expandableRowsComponent={props.expandableRowsComponent}

        
      />
    </Paper>
  );
});

export default withStyles(styles, { withTheme: true })(CustomDataTableV2);
