import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  TextField,
  Chip,
} from "@mui/material/";

import { DateRangePickerFilter } from "../../components/formComponents/dateRangePickerFilter";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import CobranzasDelDia from "./cobranzasDelDia";
import CobranzaDelDiaStatus from "./cobranzaDelDiaStatus";
import Assignment from "@mui/icons-material/Assignment";
import LocalShipping from "@mui/icons-material/LocalShipping";
import ThumbUp from "@mui/icons-material/ThumbUp";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Person from "@mui/icons-material/Person";

import Done from "@mui/icons-material/Done";
import DoNotDisturb from "@mui/icons-material/DoNotDisturb";
import PriorityHigh from "@mui/icons-material/PriorityHigh";
import Forward from "@mui/icons-material/Forward";

import YoutubeSearchedFor from "@mui/icons-material/YoutubeSearchedFor";
import { green, pink, grey, orange, red, blue } from "@mui/material/colors";

import DashboardCard from "./Card";

import PerformanceVendedoresTable from "../../components/widgets/solicitudes/PerformanceVendedoresTable";

import { getPerformanceVendedores } from "../../services/performanceVendedoresService";
import { formatDate } from "../../helpers/dateTimeHelper";
import { DashBoardService } from "../../services/dashboardService";
import DatePicker from "@mui/lab/DatePicker";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "date-fns/locale/es";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function Dashboard() {
  const [cobranzasDelDia, setCobranzasDelDia] = useState(null);
  const [cobranzasDelDiaStatus, setCobranzasDelDiaStatus] = useState(null);
  const [cantidadesSolicitudes, setCantidadesSolicitudes] = useState(null);

  const [performanceVendedores, setPerformanceVendedores] = useState([]);

  const [start, setStart] = useState(moment());
  const [end, setEnd] = useState(moment().endOf("month"));

  const [week, setWeek] = useState(moment());

  const onDateRangePickerChange = (start, end) => {
    setStart(start);
    setEnd(end);
  };

  useEffect(() => {
    getCobranzas2();

    // const interval = setInterval(() => {
    //   getCobranzas2();
    // }, 100000);
    // return () => clearInterval(interval);

  }, [start]);

  const getCobranzas2 = async () => {
    setCobranzasDelDia(null);

    const data = await DashBoardService.getCobranzas(start, end);

    setCobranzasDelDia(data.cobranzaDelDia);
    setCobranzasDelDiaStatus(data.statusCobranza);
    setCantidadesSolicitudes(data.cantidadesSolicitudes);

    const prevMonday = new Date(start);

    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
  

    setWeek(prevMonday);

    var request = {
      fecha: prevMonday,
      idEquipoDeVenta: null,
      cumpleObjetivo: null,
      idVendedor: null,
    };
    const response = await getPerformanceVendedores(request);
    console.log(response.data.performances);
    setPerformanceVendedores(response.data.performances);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Dashboard</Typography>
        </Grid>

        <Grid item>
          <LocalizationProvider locale={esLocale} dateAdapter={AdapterDateFns}>
            <DatePicker
              name="fecha"
              label="Fecha"
              value={start}
              size="small"
              onChange={(newValue) => setStart(newValue)}
              renderInput={(params) => (
                <TextField
                  required
                  variant="outlined"
                  {...params}
                  size="small"
                />
              )}
              inputFormat="DD/MM/YYYY"
              disableFuture={true}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={2}>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          {cantidadesSolicitudes === null ? null : (
            <>
              <DashboardCard
                title="Venta"
                icon={<AttachMoney />}
                value={cantidadesSolicitudes.prestamo}
                valueType="currency"
                color={green[500]}
                trend={
                  cantidadesSolicitudes.porcentajeDiferenciaPrestamoSolicitudes
                }
                showTrend={true}
              />
              <br />
              <DashboardCard
                title="Nuevas solicitudes"
                icon={<Assignment />}
                value={cantidadesSolicitudes.presentadas}
                valueType="text"
                color={red[500]}
                trend={cantidadesSolicitudes.porcentajeDiferenciaSolicitudes}
                showTrend={true}
              />
            </>
          )}
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          {cantidadesSolicitudes === null ? null : (
            <>
              <DashboardCard
                title="Clientes nuevos"
                icon={<Person />}
                value={cantidadesSolicitudes.clientesNuevos}
                valueType="text"
                color={blue[500]}
                trend={cantidadesSolicitudes.porcentajeDiferenciaClientesNuevos}
                showTrend={true}
              />
            </>
          )}
        </Grid>

        <Grid item lg={3} md={6} sm={6} xs={12}>
          <Paper
            elevation={0}
            style={{
              borderRadius: "0.75rem",
            }}
          >
            <div className="p-20">
              <span className="dashboard-title">Solicitudes por etapa</span>
              {cantidadesSolicitudes === null ? null : (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Chip
                          icon={<Done style={{ color: "#1b1e21" }} />}
                          style={{
                            color: "#1b1e21",
                            backgroundColor: "#d6d8d9",
                            fontWeight: "500",
                          }}
                          label="En control"
                        ></Chip>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {cantidadesSolicitudes.control}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Chip
                          icon={<ThumbUp style={{ color: "#2263ce" }} />}
                          style={{
                            color: "#2263ce",
                            backgroundColor: "#cce5ff",
                            fontWeight: "500",
                          }}
                          label="En calificación crediticia"
                        ></Chip>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {cantidadesSolicitudes.calificacionCrediticia}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Chip
                          icon={<LocalShipping style={{ color: "#818182" }} />}
                          style={{
                            color: "#818182",
                            backgroundColor: "#fefefe",
                            fontWeight: "500",
                            border: "1px solid #aaa",
                          }}
                          label="En logística"
                        ></Chip>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {cantidadesSolicitudes.logistica}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Chip
                          icon={<Forward style={{ color: "#155724" }} />}
                          style={{
                            color: "#155724",
                            backgroundColor: "#d4edda",
                            fontWeight: "500",
                          }}
                          label="Entregadas"
                        ></Chip>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {cantidadesSolicitudes.entregada}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </div>
          </Paper>
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
          <Paper
            elevation={0}
            style={{
              borderRadius: "0.75rem",
            }}
          >
            <div className="p-20">
              <span className="dashboard-title">
                Solicitudes por calificación
              </span>
              {cantidadesSolicitudes === null ? null : (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Chip
                          icon={<Done style={{ color: "#155724" }} />}
                          style={{
                            color: "#155724",
                            backgroundColor: "#d4edda",
                            fontWeight: "500",
                          }}
                          label="Aprobadas"
                        ></Chip>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {cantidadesSolicitudes.aprobadas}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Chip
                          icon={<PriorityHigh style={{ color: "#856404" }} />}
                          style={{
                            color: "#856404",
                            backgroundColor: "#fff3cd",
                            fontWeight: "500",
                          }}
                          label="Pendientes"
                        ></Chip>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {cantidadesSolicitudes.pendientes}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Chip
                          icon={<DoNotDisturb style={{ color: "#721c24" }} />}
                          style={{
                            color: "#721c24",
                            backgroundColor: "#f8d7da",
                            fontWeight: "500",
                          }}
                          label="Rechazadas"
                        ></Chip>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {cantidadesSolicitudes.rechazadas}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Chip
                          style={{
                            color: "#666",
                            backgroundColor: "#fff",
                            fontWeight: "500",
                          }}
                          label="Sin calificación"
                        ></Chip>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {cantidadesSolicitudes.sinCalificacion}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </div>
          </Paper>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          {performanceVendedores == null ||
          performanceVendedores == undefined ? (
            <Skeleton variant="rounded" height={100} animation="wave" />
          ) : (
            <div>
              <Paper
                elevation={0}
                style={{
                  borderRadius: "0.75rem",
                }}
              >
                <div className="p-10">
                  <Typography variant="h6" align="center">
                    Performance vendedores
                  </Typography>
                  <Typography variant="title" align="center">
                    {" Semanal del lunes " + formatDate(week)}
                  </Typography>
                </div>

                <Divider />

                <PerformanceVendedoresTable
                  solicitudesAprobadas={performanceVendedores}
                />
              </Paper>
            </div>
          )}
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <CobranzasDelDia data={cobranzasDelDia} />

          <Paper
            elevation={0}
            style={{
              borderRadius: "0.75rem",
            }}
          >
            <div className="p-10">
              <Typography variant="h6" align="center">
                Cobranzas del día
              </Typography>
            </div>

            <Divider />
            <CobranzaDelDiaStatus data={cobranzasDelDiaStatus} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
