import {
  GoogleMap,
  MarkerF,
  PolygonF,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import appConfig from "constants/appConfig";

import { Checkbox, FormControlLabel } from "@mui/material";

import InfoWindowLogistica from "./InfoWindowLogistica";
import InfoWindowVerificacionAmbiental from "./InfoWindowVerificacionAmbiental";

import {
  ZONA_11_COORDS,
  ZONA_13_COORDS,
  ZONA_15_COORDS,
  ZONA_16_COORDS,
  ZONA_17_COORDS,
  ZONA_1_COORDS,
  ZONA_2_COORDS,
  ZONA_3_COORDS,
  ZONA_6_COORDS,
  ZONA_7_COORDS,
  ZONA_8_COORDS,
  ZONA_9_COORDS,
} from "constants/zonasConfig";

const containerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "600px",
  maxHeight: "1000px",
  border: "1px solid #CCC",
  borderRadius: "13px",
};

export default function GoogleMapsWrapper(props) {
  const [center, setCenter] = useState(
    props.defaultCenter !== undefined ? props.defaultCenter : null
  );
  const [zoom, setZoom] = useState(11);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState(props.markers);
  const [mark, setMark] = useState(null);
  const [mostrarZonas, setMostrarZonas] = React.useState(true);
  const [zoneOptions, setZoneOptions] = React.useState(zoneOptions);

  const handleChange = (event) => {
    setMostrarZonas(event.target.checked);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAVuddcpI18nKCGjclB_m1yYf6J6YTDWxM",
  });

  useEffect(() => {
    var _center = {
      lat: props.lat,
      lng: props.lng,
    };
    setCenter(_center);
  }, [props.lat, props.lng]);

  const handleGoogleApiLoaded = () => {
    alert("loaded");
  };

  const onLoad = React.useCallback(function callback(map) {
    var center;

    if (props.defaultCenter !== undefined) {
      center = props.defaultCenter;
    } else {
      center = appConfig.centerMapDefault;
    }

    // if (props.markers.length > 0) {
    //   center = { lat: props.markers[0].lat, lng: props.markers[0].lng };
    // }

    // var _center = {
    //   lat: props.lat ? props.lat : appConfig.lat,
    //   lng: props.lng ? props.lng : appConfig.lng,
    // };

    setCenter(center);

    //const bounds = new window.google.maps.LatLngBounds(_center);
    //map.fitBounds(bounds);
    map.setZoom(zoom);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMapClick = (e) => {
    setMarkers(() => [
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    ]);

    var marker = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
  };

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);

    const label = window.document.getElementById("dir_" + marker);
    console.log("🚀 ~ handleActiveMarker ~ label", label);
    const range = document.createRange();
    const selection = window.getSelection();
  };

  const iconSolicitud = (marker) => {
    if (!props.defaultIcon) {
      return {
        url:
          marker.tipoSolicitud == "Producto"
            ? "http://1.apalapa.net/new2/images/marker.png"
            : "http://1.apalapa.net/new2/images/marker-money.png",
        scaledSize: { width: 40, height: 45 },
      };
    }
  };

  const labelRef = useRef(null);

  const onClickCopy = (text) => {
    navigator.clipboard.writeText(text);
    if (labelRef.current) {
      const range = document.createRange();
      const selection = window.getSelection();

      // Limpiar cualquier selección previa
      selection.removeAllRanges();

      // Crear un rango que abarque el contenido del label
      range.selectNodeContents(labelRef.current);

      // Agregar el rango a la selección
      selection.addRange(range);
    }
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <FormControlLabel
        control={<Checkbox checked={mostrarZonas} onChange={handleChange} />}
        label="Mostrar zonas"
      />

      <GoogleMap
        onGoogleApiLoaded={handleGoogleApiLoaded}
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
        //onClick={onMapClick}
        zoom={zoom}
      >
        {mostrarZonas && (
          <>
            <PolygonF
              path={ZONA_1_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#0288D1",
                strokeColor: "#0288D1",
              }}
            />
            <PolygonF
              path={ZONA_2_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#fae422",
                strokeColor: "#fae422",
              }}
            />
            <PolygonF
              path={ZONA_3_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#a708fc",
                strokeColor: "#a708fc",
              }}
            />
            <PolygonF
              path={ZONA_6_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#fb923a",
                strokeColor: "#fb923a",
              }}
            />
            <PolygonF
              path={ZONA_7_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#190089",
                strokeColor: "#190089",
              }}
            />
            <PolygonF
              path={ZONA_8_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#ff1e99",
                strokeColor: "#ff1e99",
              }}
            />

            <PolygonF
              path={ZONA_9_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#08b200",
                strokeColor: "#08b200",
              }}
            />

            <PolygonF
              path={ZONA_11_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#034100",
                strokeColor: "#034100",
              }}
            />
            <PolygonF
              path={ZONA_13_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#900057",
                strokeColor: "#900057",
              }}
            />
            <PolygonF
              path={ZONA_15_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#FF0000",
                strokeColor: "#FF0000",
              }}
            />
            <PolygonF
              path={ZONA_16_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#fff643",
                strokeColor: "#fff643",
              }}
            />
            <PolygonF
              path={ZONA_17_COORDS}
              options={{
                ...zoneOptions,
                fillColor: "#810096",
                strokeColor: "#810096",
              }}
            />
          </>
        )}

        {props.markers.map((marker, index) => (
          <MarkerF
            key={index}
            position={marker.position}
            icon={iconSolicitud(marker)}
            onClick={() => handleActiveMarker(index)}
          >
            {activeMarker === index ? (
              <>
                {props.infoWindow === undefined ||
                  props.infoWindow === null ||
                  props.infoWindow == "logistica" && (
                    <InfoWindowLogistica
                      key={index}
                      marker={marker}
                      setActiveMarker={() => setActiveMarker(null)}
                    />
                  )}

                {props.infoWindow == "verificacionAmbiental" && (
                  <InfoWindowVerificacionAmbiental
                    key={index}
                    marker={marker}
                    setActiveMarker={() => setActiveMarker(null)}
                  />
                )}
              </>
            ) : null}
          </MarkerF>
        ))}
      </GoogleMap>
    </div>
  );
}
