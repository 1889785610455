import React, { Component } from "react";
import { axiosInstance } from "../../services/api/axiosInstance";
import store from "../../store";
import { CustomImageUploader } from "./customImageUploader";
import Button from "@mui/material/Button";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slider from "react-slick";
import appConfig from "./../../constants/appConfig";

const Input = styled("input")({
  display: "none",
});

export class CustomImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveAction: this.props.saveAction,
      deleteAction: this.props.deleteAction,
      rotateAction: this.props.rotateAction,
      getAction: this.props.getAction,
      cantidadLimite: this.props.cantidadLimite,
      dialogOpen: false,

      sliderSettings: {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
      },
    };

    this.updateDocumentCallBackRemove =
      this.updateDocumentCallBackRemove.bind(this);
    this.updateDocumentCallBack = this.updateDocumentCallBack.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  updateDocumentCallBack(imagen) {
    this.props.updateDocumentCallBack(imagen);
  }

  updateDocumentCallBackRemove(imagen) {
    this.props.updateDocumentCallBackRemove(imagen);
  }

  handleClickOpen = (name) => {
    console.log(name);

    this.setState((prevState) => ({
      sliderSettings: {
        ...prevState.sliderSettings,
        initialSlide: name,
      },
    }));

    this.setState({
      dialogOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  async setSelectedImage(imagen) {
    this.setState({
      imagen: imagen,
      imagenURL: URL.createObjectURL(imagen),
    });

    let form = new FormData();
    form.append("image", imagen);
    form.append("idTipoDocumento", 0);

    await axiosInstance
      .post("/api/Multimedia/" + this.state.saveAction, form)
      .then((response) => {
        if (response.status == "200") {
          store.dispatch({
            type: "SNACKBAR_SUCCESS",
            message: "Operación exitosa",
          });

          this.setState({
            id: response.data,
          });

          this.updateDocumentCallBack(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          store.dispatch({
            type: "SNACKBAR_SUCCESS",
            message: error.response.statusText,
            snackBarType: "error",
          });
        }
      });
  }

  render() {
    return (
      <div>
        <div className="paper-header">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h3 className="mt-0">
                Galería de imágenes{" "}
                <small style={{ color: "#666", fontSize: "0.75rem" }}>
                  {" "}
                  Hasta {this.props.cantidadLimite} imágenes
                </small>
              </h3>
            </Grid>
            <Grid item>
              {this.props.readOnly !== true &&
              this.props.imagenes !== undefined &&
              this.props.imagenes.length < this.state.cantidadLimite ? (
                <label htmlFor={"icon-button-file-" + this.props.name}>
                  <Input
                    accept="image/*"
                    id={"icon-button-file-" + this.props.name}
                    type="file"
                    onChange={(event) =>
                      this.setSelectedImage(event.target.files[0])
                    }
                  />

                  <Button
                    variant="outlined"
                    startIcon={<PhotoCamera />}
                    component="span"
                  >
                    Tomar / adjuntar imagen
                  </Button>
                </label>
              ) : null}
            </Grid>
          </Grid>
        </div>
        <div className="p-10">
          <Grid container spacing={1} alignItems="center">
            {this.props.imagenes !== undefined ? (
              <>
                {this.props.imagenes.map((idImagen, i) => (
                  <Grid item lg={3} md={3} sm={4} xs={12} key={i}>
                    <CustomImageUploader
                      key={i}
                      updateDocumentCallBackRemove={
                        this.updateDocumentCallBackRemove
                      }
                      saveAction={this.props.saveAction}
                      deleteAction={this.props.deleteAction}
                      rotateAction={this.props.rotateAction}
                      getAction={this.props.getAction}
                      name={i}
                      idImagen={idImagen}
                      handleClickOpen={this.handleClickOpen}
                      readOnly={this.props.readOnly}
                    />
                  </Grid>
                ))}
              </>
            ) : null}
          </Grid>
        </div>

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={false}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogContent style={{ paddingBottom: "20px" }}>
            <Slider
              {...this.state.sliderSettings}
              style={{ width: "99%", marginBottom: "20px" }}
            >
              {this.props.imagenes !== undefined ? (
                <>
                  {this.props.imagenes.map((imagen, index) => (
                    <center>
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: "calc(75vh - 64px)",
                        }}
                        src={`${
                          appConfig.baseURL +
                          "/api/Multimedia/" +
                          this.props.getAction +
                          "/" +
                          imagen
                        }`}
                        alt={imagen}
                      />
                    </center>
                  ))}
                </>
              ) : null}
            </Slider>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
