import React, { Component, Fragment } from "react";
import { axiosInstance } from "../../services/api/axiosInstance";
import { MenuItem, TextField } from "@mui/material/";
import Autocomplete from "@mui/material/Autocomplete";
class VendedorSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idText: this.props.idText === undefined ? false : this.props.idText,
      label: this.props.label === undefined ? "Vendedor" : this.props.label,

      idVendedor: "0",
      vendedores: [],
    };
  }

  onChangeAutocomplete = (event, newValue) => {
    console.log("new Value:", newValue);
    this.setState({
      idVendedor: newValue === null ? null : newValue.id,
    });
    this.props.onSelectChange(newValue === null ? null : newValue.id);
  };

  renderOptions() {
    return this.state.vendedores.map((dt, i) => {
      return (
        <MenuItem value={dt.id} key={i} name={dt.text}>
          {dt.text}
        </MenuItem>
      );
    });
  }

  async componentDidMount() {
    var vendedores;

    vendedores = await axiosInstance.get(
      `/api/Vendedor/GetVendedoresForAutocomplete`
    );

    this.setState({
      vendedores: vendedores.data,
    });
  }

  render() {
    return (
      <Fragment>
        <Autocomplete
          variant={
            this.props.variant == undefined ? "standard" : this.props.variant
          }
          size="small"
          id="combo-box-demo"
          options={this.state.vendedores}
          groupBy={(option) => option.equipoVenta}
          onChange={(event, newValue) =>
            this.onChangeAutocomplete(event, newValue)
          }
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Vendedor"
              variant={
                this.props.variant == undefined
                  ? "standard"
                  : this.props.variant
              }
            />
          )}
        />
      </Fragment>
    );
  }
}

export default VendedorSelect;
