import React from "react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Edit } from '@mui/icons-material/';
import { Link } from "react-router-dom";

export default function EditIconButton(props) {
  return (
    <Link to={props.to + "/" + props.id}>
      <Tooltip title="Editar">
        <IconButton aria-label="Editar">
          <Edit />
        </IconButton>
      </Tooltip>
    </Link>
  );
}
