const ZONA_1_COORDS = [
  { lat: -34.606081, lng: -58.709303 },
  { lat: -34.598856, lng: -58.695311 },
  { lat: -34.595217, lng: -58.691769 },
  { lat: -34.590173, lng: -58.684073 },
  { lat: -34.583963, lng: -58.676679 },
  { lat: -34.571125, lng: -58.659483 },
  { lat: -34.56926, lng: -58.655211 },
  { lat: -34.565825, lng: -58.631526 },
  { lat: -34.563145, lng: -58.626992 },
  { lat: -34.56285, lng: -58.589384 },
  { lat: -34.563358, lng: -58.588854 },
  { lat: -34.569097, lng: -58.590555 },
  { lat: -34.570906, lng: -58.592542 },
  { lat: -34.571548, lng: -58.593156 },
  { lat: -34.574408, lng: -58.596327 },
  { lat: -34.580835, lng: -58.601645 },
  { lat: -34.585514, lng: -58.605482 },
  { lat: -34.586271, lng: -58.605885 },
  { lat: -34.590906, lng: -58.609946 },
  { lat: -34.597871, lng: -58.617311 },
  { lat: -34.595967, lng: -58.61963 },
  { lat: -34.601364, lng: -58.625822 },
  { lat: -34.605507, lng: -58.620663 },
  { lat: -34.607093, lng: -58.621081 },
  { lat: -34.608943, lng: -58.621262 },
  { lat: -34.610533, lng: -58.623366 },
  { lat: -34.611399, lng: -58.623473 },
  { lat: -34.612036, lng: -58.623668 },
  { lat: -34.611988, lng: -58.624532 },
  { lat: -34.619499, lng: -58.624494 },
  { lat: -34.625666, lng: -58.625427 },
  { lat: -34.626919, lng: -58.619087 },
  { lat: -34.632146, lng: -58.612851 },
  { lat: -34.632597, lng: -58.629236 },
  { lat: -34.6309, lng: -58.64885 },
  { lat: -34.631306, lng: -58.653423 },
  { lat: -34.632455, lng: -58.663881 },
  { lat: -34.631134, lng: -58.665241 },
  { lat: -34.634034, lng: -58.666631 },
  { lat: -34.635856, lng: -58.668473 },
  { lat: -34.642895, lng: -58.656766 },
  { lat: -34.648466, lng: -58.658147 },
  { lat: -34.648218, lng: -58.664527 },
  { lat: -34.651801, lng: -58.675881 },
  { lat: -34.65406, lng: -58.674919 },
  { lat: -34.65504, lng: -58.678002 },
  { lat: -34.653585, lng: -58.679884 },
  { lat: -34.664671, lng: -58.693046 },
  { lat: -34.665868, lng: -58.702347 },
  { lat: -34.666747, lng: -58.70933 },
  { lat: -34.665605, lng: -58.719983 },
  { lat: -34.663828, lng: -58.730464 },
  { lat: -34.66152, lng: -58.744471 },
  { lat: -34.658125, lng: -58.741776 },
  { lat: -34.654905, lng: -58.737708 },
  { lat: -34.652827, lng: -58.739849 },
  { lat: -34.651664, lng: -58.73842 },
  { lat: -34.646953, lng: -58.731991 },
  { lat: -34.64754, lng: -58.731493 },
  { lat: -34.643995, lng: -58.726502 },
  { lat: -34.648728, lng: -58.721018 },
  { lat: -34.649362, lng: -58.720229 },
  { lat: -34.641416, lng: -58.71636 },
  { lat: -34.636612, lng: -58.714006 },
  { lat: -34.634351, lng: -58.711395 },
  { lat: -34.631239, lng: -58.711636 },
  { lat: -34.628127, lng: -58.713166 },
  { lat: -34.623427, lng: -58.719152 },
  { lat: -34.622552, lng: -58.718155 },
  { lat: -34.621183, lng: -58.719775 },
  { lat: -34.618638, lng: -58.717092 },
  { lat: -34.617384, lng: -58.718616 },
  { lat: -34.613587, lng: -58.716621 },
  { lat: -34.610969, lng: -58.713633 },
  { lat: -34.610399, lng: -58.714121 },
  { lat: -34.606081, lng: -58.709303 },
];

const ZONA_2_COORDS = [
  { lat: -34.5616, lng: -58.669565 },
  { lat: -34.57024, lng: -58.678813 },
  { lat: -34.568239, lng: -58.681888 },
  { lat: -34.56539, lng: -58.68582 },
  { lat: -34.559585, lng: -58.693299 },
  { lat: -34.555114, lng: -58.699467 },
  { lat: -34.550749, lng: -58.704333 },
  { lat: -34.551942, lng: -58.705542 },
  { lat: -34.551545, lng: -58.705937 },
  { lat: -34.551217, lng: -58.706417 },
  { lat: -34.550042, lng: -58.705223 },
  { lat: -34.543732, lng: -58.714209 },
  { lat: -34.538969, lng: -58.722143 },
  { lat: -34.528368, lng: -58.736839 },
  { lat: -34.522801, lng: -58.744401 },
  { lat: -34.519865, lng: -58.753179 },
  { lat: -34.517876, lng: -58.74917 },
  { lat: -34.515468, lng: -58.74404 },
  { lat: -34.514175, lng: -58.741185 },
  { lat: -34.512195, lng: -58.736018 },
  { lat: -34.511313, lng: -58.734095 },
  { lat: -34.510748, lng: -58.732363 },
  { lat: -34.509987, lng: -58.730525 },
  { lat: -34.50936, lng: -58.728823 },
  { lat: -34.508906, lng: -58.728236 },
  { lat: -34.508956, lng: -58.728658 },
  { lat: -34.509082, lng: -58.728555 },
  { lat: -34.508924, lng: -58.728267 },
  { lat: -34.509147, lng: -58.728505 },
  { lat: -34.509163, lng: -58.727937 },
  { lat: -34.508959, lng: -58.728507 },
  { lat: -34.508931, lng: -58.728609 },
  { lat: -34.508762, lng: -58.727812 },
  { lat: -34.508988, lng: -58.728337 },
  { lat: -34.508933, lng: -58.728305 },
  { lat: -34.500814, lng: -58.747415 },
  { lat: -34.496683, lng: -58.756947 },
  { lat: -34.494511, lng: -58.761542 },
  { lat: -34.487301, lng: -58.772733 },
  { lat: -34.476977, lng: -58.783237 },
  { lat: -34.473406, lng: -58.790034 },
  { lat: -34.469411, lng: -58.79666 },
  { lat: -34.465274, lng: -58.803285 },
  { lat: -34.460358, lng: -58.808709 },
  { lat: -34.455983, lng: -58.80082 },
  { lat: -34.448564, lng: -58.79585 },
  { lat: -34.441511, lng: -58.782305 },
  { lat: -34.443399, lng: -58.770641 },
  { lat: -34.445995, lng: -58.759491 },
  { lat: -34.451281, lng: -58.770743 },
  { lat: -34.456967, lng: -58.766584 },
  { lat: -34.455174, lng: -58.762788 },
  { lat: -34.456424, lng: -58.762654 },
  { lat: -34.461921, lng: -58.758487 },
  { lat: -34.462127, lng: -58.758739 },
  { lat: -34.465185, lng: -58.756141 },
  { lat: -34.467924, lng: -58.754035 },
  { lat: -34.462914, lng: -58.743811 },
  { lat: -34.455124, lng: -58.750321 },
  { lat: -34.450592, lng: -58.74348 },
  { lat: -34.456465, lng: -58.72564 },
  { lat: -34.459295, lng: -58.730888 },
  { lat: -34.466083, lng: -58.725361 },
  { lat: -34.46162, lng: -58.716314 },
  { lat: -34.471373, lng: -58.70831 },
  { lat: -34.473626, lng: -58.712924 },
  { lat: -34.490441, lng: -58.699063 },
  { lat: -34.485442, lng: -58.686191 },
  { lat: -34.478852, lng: -58.673496 },
  { lat: -34.489059, lng: -58.662487 },
  { lat: -34.492877, lng: -58.657572 },
  { lat: -34.495687, lng: -58.65474 },
  { lat: -34.498056, lng: -58.65092 },
  { lat: -34.501939, lng: -58.647343 },
  { lat: -34.508787, lng: -58.657339 },
  { lat: -34.529801, lng: -58.688684 },
  { lat: -34.5261, lng: -58.69371 },
  { lat: -34.532615, lng: -58.700751 },
  { lat: -34.564168, lng: -58.658467 },
  { lat: -34.567142, lng: -58.661785 },
  { lat: -34.5616, lng: -58.669565 },
];

const ZONA_3_COORDS = [
  { lat: -34.568065, lng: -58.682283 },
  { lat: -34.574922, lng: -58.689279 },
  { lat: -34.581688, lng: -58.679967 },
  { lat: -34.588117, lng: -58.68422 },
  { lat: -34.589037, lng: -58.686812 },
  { lat: -34.580231, lng: -58.699042 },
  { lat: -34.591322, lng: -58.711954 },
  { lat: -34.592874, lng: -58.710403 },
  { lat: -34.593969, lng: -58.711496 },
  { lat: -34.595134, lng: -58.712847 },
  { lat: -34.59658, lng: -58.714344 },
  { lat: -34.595176, lng: -58.716328 },
  { lat: -34.598435, lng: -58.719858 },
  { lat: -34.601516, lng: -58.71579 },
  { lat: -34.605642, lng: -58.720599 },
  { lat: -34.614301, lng: -58.729802 },
  { lat: -34.604016, lng: -58.743616 },
  { lat: -34.604156, lng: -58.743524 },
  { lat: -34.604707, lng: -58.744215 },
  { lat: -34.596569, lng: -58.755034 },
  { lat: -34.593242, lng: -58.752794 },
  { lat: -34.594071, lng: -58.753749 },
  { lat: -34.581581, lng: -58.770416 },
  { lat: -34.57328, lng: -58.761879 },
  { lat: -34.564156, lng: -58.773685 },
  { lat: -34.561131, lng: -58.77055 },
  { lat: -34.549636, lng: -58.758097 },
  { lat: -34.54978, lng: -58.757885 },
  { lat: -34.547361, lng: -58.755357 },
  { lat: -34.547466, lng: -58.755214 },
  { lat: -34.54492, lng: -58.752453 },
  { lat: -34.540817, lng: -58.747918 },
  { lat: -34.536065, lng: -58.742987 },
  { lat: -34.529297, lng: -58.735852 },
  { lat: -34.550678, lng: -58.704236 },
  { lat: -34.55317, lng: -58.702084 },
  { lat: -34.555468, lng: -58.699568 },
  { lat: -34.559885, lng: -58.693234 },
  { lat: -34.568065, lng: -58.682283 },
];

const ZONA_4_COORDS = [];

const ZONA_5_COORDS = [];

const ZONA_6_COORDS = [
  { lat: -34.633621, lng: -58.734206 },
  { lat: -34.63407, lng: -58.733627 },
  { lat: -34.638352, lng: -58.738348 },
  { lat: -34.639711, lng: -58.736652 },
  { lat: -34.640788, lng: -58.735054 },
  { lat: -34.641177, lng: -58.73455 },
  { lat: -34.642641, lng: -58.736587 },
  { lat: -34.644089, lng: -58.738593 },
  { lat: -34.646384, lng: -58.74238 },
  { lat: -34.6504, lng: -58.748126 },
  { lat: -34.653281, lng: -58.752007 },
  { lat: -34.65604, lng: -58.756039 },
  { lat: -34.657028, lng: -58.756527 },
  { lat: -34.657876, lng: -58.756951 },
  { lat: -34.659276, lng: -58.75415 },
  { lat: -34.660817, lng: -58.760104 },
  { lat: -34.663125, lng: -58.765539 },
  { lat: -34.666845, lng: -58.776553 },
  { lat: -34.668775, lng: -58.774376 },
  { lat: -34.666967, lng: -58.776491 },
  { lat: -34.668806, lng: -58.77432 },
  { lat: -34.676998, lng: -58.782535 },
  { lat: -34.678134, lng: -58.780898 },
  { lat: -34.678227, lng: -58.780916 },
  { lat: -34.680937, lng: -58.783961 },
  { lat: -34.682801, lng: -58.781599 },
  { lat: -34.688525, lng: -58.787579 },
  { lat: -34.691446, lng: -58.783252 },
  { lat: -34.693589, lng: -58.779805 },
  { lat: -34.694807, lng: -58.778379 },
  { lat: -34.69592, lng: -58.776612 },
  { lat: -34.697014, lng: -58.774975 },
  { lat: -34.698232, lng: -58.773164 },
  { lat: -34.699997, lng: -58.77071 },
  { lat: -34.707971, lng: -58.760278 },
  { lat: -34.716103, lng: -58.748836 },
  { lat: -34.712911, lng: -58.739499 },
  { lat: -34.7236, lng: -58.724647 },
  { lat: -34.720503, lng: -58.720976 },
  { lat: -34.719699, lng: -58.721768 },
  { lat: -34.713612, lng: -58.714771 },
  { lat: -34.716076, lng: -58.711202 },
  { lat: -34.714935, lng: -58.695052 },
  { lat: -34.707375, lng: -58.675685 },
  { lat: -34.69946, lng: -58.655501 },
  { lat: -34.708928, lng: -58.642986 },
  { lat: -34.707424, lng: -58.642574 },
  { lat: -34.694164, lng: -58.637496 },
  { lat: -34.684079, lng: -58.635767 },
  { lat: -34.660444, lng: -58.624668 },
  { lat: -34.659115, lng: -58.63683 },
  { lat: -34.656021, lng: -58.652853 },
  { lat: -34.663059, lng: -58.675384 },
  { lat: -34.665436, lng: -58.693366 },
  { lat: -34.667368, lng: -58.708044 },
  { lat: -34.666122, lng: -58.72094 },
  { lat: -34.665722, lng: -58.723193 },
  { lat: -34.665676, lng: -58.724846 },
  { lat: -34.665629, lng: -58.725726 },
  { lat: -34.665548, lng: -58.726176 },
  { lat: -34.665183, lng: -58.727142 },
  { lat: -34.664173, lng: -58.733194 },
  { lat: -34.662701, lng: -58.74114 },
  { lat: -34.661798, lng: -58.74609 },
  { lat: -34.661742, lng: -58.746833 },
  { lat: -34.661576, lng: -58.747591 },
  { lat: -34.66148, lng: -58.748281 },
  { lat: -34.661344, lng: -58.748947 },
  { lat: -34.660996, lng: -58.749701 },
  { lat: -34.660877, lng: -58.750544 },
  { lat: -34.660674, lng: -58.751204 },
  { lat: -34.660444, lng: -58.751597 },
  { lat: -34.660426, lng: -58.752334 },
  { lat: -34.660409, lng: -58.752986 },
  { lat: -34.65792, lng: -58.758186 },
  { lat: -34.654195, lng: -58.771198 },
  { lat: -34.652842, lng: -58.775527 },
  { lat: -34.651453, lng: -58.780349 },
  { lat: -34.650866, lng: -58.781861 },
  { lat: -34.650275, lng: -58.784077 },
  { lat: -34.650102, lng: -58.785227 },
  { lat: -34.649648, lng: -58.786206 },
  { lat: -34.649612, lng: -58.786888 },
  { lat: -34.649419, lng: -58.787507 },
  { lat: -34.648925, lng: -58.789534 },
  { lat: -34.64513, lng: -58.788042 },
  { lat: -34.643894, lng: -58.787529 },
  { lat: -34.642764, lng: -58.786905 },
  { lat: -34.641705, lng: -58.786506 },
  { lat: -34.640927, lng: -58.786321 },
  { lat: -34.640363, lng: -58.78608 },
  { lat: -34.63911, lng: -58.785597 },
  { lat: -34.638262, lng: -58.785425 },
  { lat: -34.636576, lng: -58.784781 },
  { lat: -34.635969, lng: -58.78477 },
  { lat: -34.635945, lng: -58.771713 },
  { lat: -34.635764, lng: -58.770564 },
  { lat: -34.635517, lng: -58.768279 },
  { lat: -34.635243, lng: -58.76505 },
  { lat: -34.635084, lng: -58.763291 },
  { lat: -34.634449, lng: -58.756419 },
  { lat: -34.634114, lng: -58.753667 },
  { lat: -34.633743, lng: -58.751843 },
  { lat: -34.633276, lng: -58.750523 },
  { lat: -34.632913, lng: -58.748861 },
  { lat: -34.632701, lng: -58.748281 },
  { lat: -34.632489, lng: -58.747488 },
  { lat: -34.632296, lng: -58.746779 },
  { lat: -34.632092, lng: -58.746104 },
  { lat: -34.63185, lng: -58.744875 },
  { lat: -34.631466, lng: -58.743561 },
  { lat: -34.631095, lng: -58.741866 },
  { lat: -34.630662, lng: -58.740064 },
  { lat: -34.630393, lng: -58.738518 },
  { lat: -34.631484, lng: -58.73706 },
  { lat: -34.63233, lng: -58.735879 },
  { lat: -34.632754, lng: -58.735299 },
  { lat: -34.633071, lng: -58.734912 },
  { lat: -34.633213, lng: -58.734698 },
  { lat: -34.633621, lng: -58.734206 },
];

const ZONA_7_COORDS = [
  { lat: -34.53508, lng: -58.81345 },
  { lat: -34.528946, lng: -58.803569 },
  { lat: -34.533419, lng: -58.799267 },
  { lat: -34.537941, lng: -58.794815 },
  { lat: -34.535251, lng: -58.790535 },
  { lat: -34.535018, lng: -58.789 },
  { lat: -34.537956, lng: -58.786364 },
  { lat: -34.54088, lng: -58.783687 },
  { lat: -34.537843, lng: -58.786493 },
  { lat: -34.536746, lng: -58.787477 },
  { lat: -34.535768, lng: -58.788307 },
  { lat: -34.535519, lng: -58.788583 },
  { lat: -34.535137, lng: -58.788916 },
  { lat: -34.535118, lng: -58.788894 },
  { lat: -34.535132, lng: -58.788938 },
  { lat: -34.535101, lng: -58.788917 },
  { lat: -34.535142, lng: -58.788908 },
  { lat: -34.536268, lng: -58.788049 },
  { lat: -34.537217, lng: -58.78702 },
  { lat: -34.539569, lng: -58.784894 },
  { lat: -34.54024, lng: -58.784279 },
  { lat: -34.540877, lng: -58.783688 },
  { lat: -34.539575, lng: -58.781573 },
  { lat: -34.539018, lng: -58.780707 },
  { lat: -34.538519, lng: -58.779887 },
  { lat: -34.539436, lng: -58.779011 },
  { lat: -34.540373, lng: -58.778119 },
  { lat: -34.539413, lng: -58.776434 },
  { lat: -34.54158, lng: -58.774224 },
  { lat: -34.541942, lng: -58.773801 },
  { lat: -34.542411, lng: -58.77325 },
  { lat: -34.542957, lng: -58.77266 },
  { lat: -34.543746, lng: -58.771982 },
  { lat: -34.545177, lng: -58.774033 },
  { lat: -34.550849, lng: -58.769046 },
  { lat: -34.556997, lng: -58.776051 },
  { lat: -34.561092, lng: -58.770489 },
  { lat: -34.549559, lng: -58.758079 },
  { lat: -34.549716, lng: -58.757816 },
  { lat: -34.547325, lng: -58.75535 },
  { lat: -34.54745, lng: -58.755218 },
  { lat: -34.545174, lng: -58.75276 },
  { lat: -34.541269, lng: -58.748719 },
  { lat: -34.540009, lng: -58.747348 },
  { lat: -34.539037, lng: -58.746307 },
  { lat: -34.538233, lng: -58.745426 },
  { lat: -34.537163, lng: -58.744356 },
  { lat: -34.535913, lng: -58.742981 },
  { lat: -34.533918, lng: -58.740899 },
  { lat: -34.531571, lng: -58.738448 },
  { lat: -34.530363, lng: -58.737158 },
  { lat: -34.529136, lng: -58.735814 },
  { lat: -34.522895, lng: -58.744472 },
  { lat: -34.51996, lng: -58.753442 },
  { lat: -34.518507, lng: -58.75091 },
  { lat: -34.514628, lng: -58.7428 },
  { lat: -34.509062, lng: -58.728842 },
  { lat: -34.508492, lng: -58.729307 },
  { lat: -34.506966, lng: -58.733312 },
  { lat: -34.502236, lng: -58.745151 },
  { lat: -34.499512, lng: -58.751579 },
  { lat: -34.498643, lng: -58.753575 },
  { lat: -34.495351, lng: -58.7609 },
  { lat: -34.493144, lng: -58.76444 },
  { lat: -34.490433, lng: -58.769385 },
  { lat: -34.487319, lng: -58.773407 },
  { lat: -34.482526, lng: -58.777451 },
  { lat: -34.483654, lng: -58.779521 },
  { lat: -34.483297, lng: -58.782108 },
  { lat: -34.489583, lng: -58.793341 },
  { lat: -34.497889, lng: -58.806877 },
  { lat: -34.500902, lng: -58.808655 },
  { lat: -34.502258, lng: -58.807267 },
  { lat: -34.504458, lng: -58.809996 },
  { lat: -34.505932, lng: -58.812465 },
  { lat: -34.503968, lng: -58.814291 },
  { lat: -34.505473, lng: -58.81654 },
  { lat: -34.506688, lng: -58.815441 },
  { lat: -34.509312, lng: -58.81949 },
  { lat: -34.509576, lng: -58.819934 },
  { lat: -34.510635, lng: -58.821364 },
  { lat: -34.515232, lng: -58.816612 },
  { lat: -34.51983, lng: -58.812375 },
  { lat: -34.526148, lng: -58.822397 },
  { lat: -34.53508, lng: -58.81345 },
];

const ZONA_8_COORDS = [
  { lat: -34.740145, lng: -58.632967 },
  { lat: -34.742049, lng: -58.630332 },
  { lat: -34.743742, lng: -58.628235 },
  { lat: -34.746998, lng: -58.624301 },
  { lat: -34.749216, lng: -58.621372 },
  { lat: -34.74968, lng: -58.620319 },
  { lat: -34.750427, lng: -58.619437 },
  { lat: -34.751506, lng: -58.618426 },
  { lat: -34.751678, lng: -58.618154 },
  { lat: -34.751637, lng: -58.618072 },
  { lat: -34.751694, lng: -58.617892 },
  { lat: -34.751482, lng: -58.618081 },
  { lat: -34.75175, lng: -58.6181 },
  { lat: -34.751797, lng: -58.617991 },
  { lat: -34.751971, lng: -58.617675 },
  { lat: -34.75221, lng: -58.617234 },
  { lat: -34.752704, lng: -58.616731 },
  { lat: -34.75324, lng: -58.616055 },
  { lat: -34.754046, lng: -58.614988 },
  { lat: -34.754817, lng: -58.614115 },
  { lat: -34.726162, lng: -58.649867 },
  { lat: -34.731609, lng: -58.651095 },
  { lat: -34.737054, lng: -58.651636 },
  { lat: -34.747366, lng: -58.663593 },
  { lat: -34.749073, lng: -58.663876 },
  { lat: -34.757523, lng: -58.660291 },
  { lat: -34.760672, lng: -58.6702 },
  { lat: -34.762879, lng: -58.672766 },
  { lat: -34.782696, lng: -58.648513 },
  { lat: -34.802367, lng: -58.625118 },
  { lat: -34.798616, lng: -58.620583 },
  { lat: -34.794936, lng: -58.616478 },
  { lat: -34.793053, lng: -58.613875 },
  { lat: -34.792393, lng: -58.614591 },
  { lat: -34.787363, lng: -58.608783 },
  { lat: -34.772921, lng: -58.592105 },
  { lat: -34.769483, lng: -58.596559 },
  { lat: -34.770326, lng: -58.595528 },
  { lat: -34.770869, lng: -58.594601 },
  { lat: -34.771218, lng: -58.594059 },
  { lat: -34.771703, lng: -58.59388 },
  { lat: -34.772272, lng: -58.593177 },
  { lat: -34.772628, lng: -58.592816 },
  { lat: -34.773187, lng: -58.591925 },
  { lat: -34.75784, lng: -58.573818 },
  { lat: -34.755018, lng: -58.577167 },
  { lat: -34.752535, lng: -58.574198 },
  { lat: -34.749575, lng: -58.572902 },
  { lat: -34.747559, lng: -58.573044 },
  { lat: -34.745339, lng: -58.574395 },
  { lat: -34.741715, lng: -58.567628 },
  { lat: -34.73119, lng: -58.581491 },
  { lat: -34.719611, lng: -58.596672 },
  { lat: -34.689833, lng: -58.636519 },
  { lat: -34.694508, lng: -58.637174 },
  { lat: -34.709592, lng: -58.642742 },
  { lat: -34.720651, lng: -58.643214 },
  { lat: -34.726241, lng: -58.649833 },
  { lat: -34.729088, lng: -58.646241 },
  { lat: -34.729798, lng: -58.645378 },
  { lat: -34.73028, lng: -58.644774 },
  { lat: -34.732894, lng: -58.641481 },
  { lat: -34.734632, lng: -58.640027 },
  { lat: -34.735384, lng: -58.638403 },
  { lat: -34.740145, lng: -58.632967 },
];

const ZONA_9_COORDS = [
  { lat: -34.660203, lng: -58.624262 },
  { lat: -34.660098, lng: -58.607578 },
  { lat: -34.659639, lng: -58.606559 },
  { lat: -34.665445, lng: -58.599505 },
  { lat: -34.666998, lng: -58.599197 },
  { lat: -34.679369, lng: -58.58434 },
  { lat: -34.684627, lng: -58.561627 },
  { lat: -34.706715, lng: -58.531972 },
  { lat: -34.709599, lng: -58.534097 },
  { lat: -34.722501, lng: -58.535621 },
  { lat: -34.728471, lng: -58.543721 },
  { lat: -34.733489, lng: -58.55371 },
  { lat: -34.741608, lng: -58.567677 },
  { lat: -34.689215, lng: -58.636536 },
  { lat: -34.683781, lng: -58.635549 },
  { lat: -34.660203, lng: -58.624262 },
];

const ZONA_10_COORDS = [];

const ZONA_11_COORDS = [
  { lat: -34.696263, lng: -58.776555 },
  { lat: -34.710605, lng: -58.789553 },
  { lat: -34.724979, lng: -58.804439 },
  { lat: -34.733021, lng: -58.793442 },
  { lat: -34.70586, lng: -58.763466 },
  { lat: -34.696263, lng: -58.776555 },
];

const ZONA_13_COORDS = [
  { lat: -34.562672, lng: -58.624483 },
  { lat: -34.562212, lng: -58.590087 },
  { lat: -34.562442, lng: -58.587019 },
  { lat: -34.562919, lng: -58.581676 },
  { lat: -34.567018, lng: -58.577186 },
  { lat: -34.573167, lng: -58.58454 },
  { lat: -34.56928, lng: -58.590049 },
  { lat: -34.573874, lng: -58.595558 },
  { lat: -34.588148, lng: -58.606952 },
  { lat: -34.590374, lng: -58.564498 },
  { lat: -34.568525, lng: -58.536697 },
  { lat: -34.569043, lng: -58.536126 },
  { lat: -34.556701, lng: -58.522688 },
  { lat: -34.549142, lng: -58.529059 },
  { lat: -34.543279, lng: -58.536867 },
  { lat: -34.535696, lng: -58.541137 },
  { lat: -34.529724, lng: -58.547844 },
  { lat: -34.522087, lng: -58.556642 },
  { lat: -34.514554, lng: -58.56557 },
  { lat: -34.512821, lng: -58.567501 },
  { lat: -34.508418, lng: -58.565892 },
  { lat: -34.490751, lng: -58.558381 },
  { lat: -34.48396, lng: -58.560763 },
  { lat: -34.481235, lng: -58.562243 },
  { lat: -34.476943, lng: -58.564826 },
  { lat: -34.474909, lng: -58.565342 },
  { lat: -34.472309, lng: -58.566907 },
  { lat: -34.465766, lng: -58.570072 },
  { lat: -34.458727, lng: -58.555814 },
  { lat: -34.454598, lng: -58.566858 },
  { lat: -34.450399, lng: -58.569618 },
  { lat: -34.443191, lng: -58.577787 },
  { lat: -34.442858, lng: -58.57734 },
  { lat: -34.439038, lng: -58.578738 },
  { lat: -34.439501, lng: -58.581949 },
  { lat: -34.441044, lng: -58.581535 },
  { lat: -34.443816, lng: -58.595496 },
  { lat: -34.453535, lng: -58.617412 },
  { lat: -34.454572, lng: -58.623305 },
  { lat: -34.458627, lng: -58.631316 },
  { lat: -34.468293, lng: -58.623647 },
  { lat: -34.474321, lng: -58.625991 },
  { lat: -34.484254, lng: -58.620895 },
  { lat: -34.488038, lng: -58.593858 },
  { lat: -34.506183, lng: -58.589417 },
  { lat: -34.511842, lng: -58.590083 },
  { lat: -34.524076, lng: -58.591456 },
  { lat: -34.534966, lng: -58.596348 },
  { lat: -34.548984, lng: -58.605137 },
  { lat: -34.552147, lng: -58.612454 },
  { lat: -34.555664, lng: -58.616423 },
  { lat: -34.559115, lng: -58.620292 },
  { lat: -34.562672, lng: -58.624483 },
];

const ZONA_15_COORDS = [
  { lat: -34.447913, lng: -58.698862 },
  { lat: -34.461254, lng: -58.68912 },
  { lat: -34.467764, lng: -58.684678 },
  { lat: -34.469463, lng: -58.68427 },
  { lat: -34.470302, lng: -58.682515 },
  { lat: -34.471, lng: -58.680847 },
  { lat: -34.471332, lng: -58.679733 },
  { lat: -34.471697, lng: -58.677469 },
  { lat: -34.472346, lng: -58.675379 },
  { lat: -34.473238, lng: -58.672119 },
  { lat: -34.474323, lng: -58.669363 },
  { lat: -34.474932, lng: -58.666928 },
  { lat: -34.475157, lng: -58.665579 },
  { lat: -34.475581, lng: -58.662982 },
  { lat: -34.478711, lng: -58.673324 },
  { lat: -34.484175, lng: -58.667015 },
  { lat: -34.486973, lng: -58.66353 },
  { lat: -34.488223, lng: -58.662253 },
  { lat: -34.489575, lng: -58.661072 },
  { lat: -34.491951, lng: -58.658176 },
  { lat: -34.495112, lng: -58.65457 },
  { lat: -34.496445, lng: -58.652468 },
  { lat: -34.497111, lng: -58.651825 },
  { lat: -34.497919, lng: -58.650408 },
  { lat: -34.498759, lng: -58.649636 },
  { lat: -34.499562, lng: -58.648733 },
  { lat: -34.500316, lng: -58.647821 },
  { lat: -34.501033, lng: -58.647339 },
  { lat: -34.501864, lng: -58.646161 },
  { lat: -34.485913, lng: -58.614233 },
  { lat: -34.484498, lng: -58.621099 },
  { lat: -34.482376, lng: -58.621914 },
  { lat: -34.480394, lng: -58.623116 },
  { lat: -34.479156, lng: -58.623588 },
  { lat: -34.478201, lng: -58.624017 },
  { lat: -34.477105, lng: -58.62436 },
  { lat: -34.476609, lng: -58.624918 },
  { lat: -34.473035, lng: -58.625647 },
  { lat: -34.475831, lng: -58.625305 },
  { lat: -34.475069, lng: -58.625712 },
  { lat: -34.473679, lng: -58.625604 },
  { lat: -34.474343, lng: -58.625883 },
  { lat: -34.472446, lng: -58.625341 },
  { lat: -34.472051, lng: -58.62513 },
  { lat: -34.471585, lng: -58.625027 },
  { lat: -34.471147, lng: -58.624803 },
  { lat: -34.470575, lng: -58.624407 },
  { lat: -34.469463, lng: -58.623807 },
  { lat: -34.468201, lng: -58.623851 },
  { lat: -34.467435, lng: -58.624241 },
  { lat: -34.467151, lng: -58.624359 },
  { lat: -34.466808, lng: -58.624595 },
  { lat: -34.466649, lng: -58.624745 },
  { lat: -34.466569, lng: -58.624801 },
  { lat: -34.46564, lng: -58.625501 },
  { lat: -34.465622, lng: -58.625657 },
  { lat: -34.465207, lng: -58.625881 },
  { lat: -34.464862, lng: -58.626107 },
  { lat: -34.464314, lng: -58.62673 },
  { lat: -34.463606, lng: -58.627373 },
  { lat: -34.462475, lng: -58.628082 },
  { lat: -34.461407, lng: -58.629198 },
  { lat: -34.460199, lng: -58.630057 },
  { lat: -34.458561, lng: -58.631089 },
  { lat: -34.456098, lng: -58.626587 },
  { lat: -34.455205, lng: -58.632816 },
  { lat: -34.454802, lng: -58.63459 },
  { lat: -34.454497, lng: -58.636111 },
  { lat: -34.454232, lng: -58.637254 },
  { lat: -34.454167, lng: -58.638197 },
  { lat: -34.454182, lng: -58.638676 },
  { lat: -34.453682, lng: -58.640661 },
  { lat: -34.453442, lng: -58.641661 },
  { lat: -34.453494, lng: -58.642373 },
  { lat: -34.453755, lng: -58.64341 },
  { lat: -34.453748, lng: -58.645393 },
  { lat: -34.453723, lng: -58.648426 },
  { lat: -34.451991, lng: -58.65432 },
  { lat: -34.450602, lng: -58.659017 },
  { lat: -34.449695, lng: -58.662679 },
  { lat: -34.449477, lng: -58.663808 },
  { lat: -34.449185, lng: -58.665295 },
  { lat: -34.448924, lng: -58.665898 },
  { lat: -34.448415, lng: -58.668023 },
  { lat: -34.448264, lng: -58.668797 },
  { lat: -34.447997, lng: -58.669659 },
  { lat: -34.447665, lng: -58.671682 },
  { lat: -34.447333, lng: -58.672656 },
  { lat: -34.446703, lng: -58.675031 },
  { lat: -34.446153, lng: -58.678278 },
  { lat: -34.447913, lng: -58.698862 },
];

const ZONA_16_COORDS = [
  { lat: -34.601937, lng: -58.903393 },
  { lat: -34.627245, lng: -58.809763 },
  { lat: -34.626052, lng: -58.809377 },
  { lat: -34.625677, lng: -58.809269 },
  { lat: -34.625309, lng: -58.809157 },
  { lat: -34.625083, lng: -58.809023 },
  { lat: -34.624769, lng: -58.809028 },
  { lat: -34.623967, lng: -58.808591 },
  { lat: -34.621402, lng: -58.807572 },
  { lat: -34.62281, lng: -58.801887 },
  { lat: -34.621562, lng: -58.802763 },
  { lat: -34.61907, lng: -58.798964 },
  { lat: -34.619166, lng: -58.798485 },
  { lat: -34.617539, lng: -58.796063 },
  { lat: -34.61516, lng: -58.792551 },
  { lat: -34.61388, lng: -58.79384 },
  { lat: -34.613236, lng: -58.794497 },
  { lat: -34.611131, lng: -58.791247 },
  { lat: -34.610015, lng: -58.789348 },
  { lat: -34.606963, lng: -58.784744 },
  { lat: -34.603362, lng: -58.780306 },
  { lat: -34.601058, lng: -58.777819 },
  { lat: -34.600203, lng: -58.776814 },
  { lat: -34.598308, lng: -58.774569 },
  { lat: -34.601944, lng: -58.769755 },
  { lat: -34.600652, lng: -58.768097 },
  { lat: -34.604127, lng: -58.763728 },
  { lat: -34.618835, lng: -58.742199 },
  { lat: -34.617509, lng: -58.74067 },
  { lat: -34.626327, lng: -58.728884 },
  { lat: -34.632855, lng: -58.752926 },
  { lat: -34.634228, lng: -58.763148 },
  { lat: -34.635231, lng: -58.771102 },
  { lat: -34.635988, lng: -58.784636 },
  { lat: -34.639761, lng: -58.785824 },
  { lat: -34.643141, lng: -58.786999 },
  { lat: -34.645322, lng: -58.788165 },
  { lat: -34.649024, lng: -58.789501 },
  { lat: -34.651103, lng: -58.781738 },
  { lat: -34.652193, lng: -58.778284 },
  { lat: -34.654099, lng: -58.771659 },
  { lat: -34.65602, lng: -58.765235 },
  { lat: -34.658967, lng: -58.769195 },
  { lat: -34.660634, lng: -58.77639 },
  { lat: -34.664032, lng: -58.783541 },
  { lat: -34.66757, lng: -58.78853 },
  { lat: -34.671383, lng: -58.794431 },
  { lat: -34.677798, lng: -58.816168 },
  { lat: -34.684098, lng: -58.81886 },
  { lat: -34.663698, lng: -58.839053 },
  { lat: -34.651624, lng: -58.850769 },
  { lat: -34.638244, lng: -58.86418 },
  { lat: -34.634095, lng: -58.868267 },
  { lat: -34.63177, lng: -58.87077 },
  { lat: -34.631812, lng: -58.870826 },
  { lat: -34.631919, lng: -58.87097 },
  { lat: -34.632058, lng: -58.871161 },
  { lat: -34.632617, lng: -58.871851 },
  { lat: -34.633456, lng: -58.873331 },
  { lat: -34.636873, lng: -58.878352 },
  { lat: -34.641014, lng: -58.884541 },
  { lat: -34.638108, lng: -58.888233 },
  { lat: -34.641452, lng: -58.892783 },
  { lat: -34.638625, lng: -58.895682 },
  { lat: -34.630595, lng: -58.903576 },
  { lat: -34.626297, lng: -58.907905 },
  { lat: -34.623389, lng: -58.91097 },
  { lat: -34.623648, lng: -58.912181 },
  { lat: -34.622776, lng: -58.91189 },
  { lat: -34.62255, lng: -58.911578 },
  { lat: -34.601937, lng: -58.903393 },
];

const ZONA_17_COORDS = [
  { lat: -34.587283, lng: -58.97624 },
  { lat: -34.601732, lng: -58.904399 },
  { lat: -34.619286, lng: -58.910579 },
  { lat: -34.628221, lng: -58.92766 },
  { lat: -34.617767, lng: -58.936135 },
  { lat: -34.622853, lng: -58.945899 },
  { lat: -34.615896, lng: -58.951714 },
  { lat: -34.615507, lng: -58.953752 },
  { lat: -34.613388, lng: -58.962507 },
  { lat: -34.611295, lng: -58.961691 },
  { lat: -34.605176, lng: -58.966712 },
  { lat: -34.5892, lng: -58.978342 },
  { lat: -34.587283, lng: -58.97624 },
];

const zoneOptions = {
    fillColor: "#FF5500",
    fillOpacity: 0.2,
    strokeColor: "#FF7700",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    paths: ZONA_3_COORDS,
    zIndex: 1,
  };




  export {
    ZONA_1_COORDS,
    ZONA_2_COORDS,
    ZONA_3_COORDS,
    ZONA_4_COORDS,
    ZONA_5_COORDS,
    ZONA_6_COORDS,
    ZONA_7_COORDS,
    ZONA_8_COORDS,
    ZONA_9_COORDS,
    ZONA_10_COORDS,
    ZONA_11_COORDS,
    ZONA_13_COORDS,
    ZONA_15_COORDS,
    ZONA_17_COORDS,
    ZONA_16_COORDS,
    zoneOptions,
  };
  
