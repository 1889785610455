import React, { useEffect, useState } from "react";


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function MultipleSelectCheckmarks(props) {

  const [selectedValue, setSelectedValue] = React.useState([]);

  useEffect(() => {
   console.log("data: " , props.data);
  }, props.data);


  const handleChange = (event) => {
    const { target: { value } } = event;

    console.log(event);

    setSelectedValue(typeof value === 'string' ? value.split(',') : value);

    props.multipleSelectCheckmarksChange(event);
  };

  const isChecked = (id) => {
    return props.data.filter(v => v.id == id && v.selected);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">{props.label}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          variant="standard"
          value={props.data.filter(d => d.selected)}
          onChange={handleChange}
          //defaultValue={props.defaultValues}
          //input={<OutlinedInput label={props.label} />}
          renderValue={(selected) => props.data.filter(d => d.selected).map((obj) => obj.name).join(', ')  }
          MenuProps={MenuProps}
        >
          {props.data.map((item) => (
            <MenuItem key={item.id} value={item} name={item.name}>
              <Checkbox checked={props.data.filter(d => d.selected).indexOf(item) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
