import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";

import { Link } from "react-router-dom";

export default function ViewIconButton(props) {

  return (
    <Link to={props.to + "/" + props.id + "/1"}>
      <Tooltip title="ver">
        <IconButton aria-label="ver">
          <RemoveRedEye />
        </IconButton>
      </Tooltip>
    </Link>
  );
}
