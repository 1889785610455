import React, { useState } from "react";

import { Grid, Typography, Chip, Button } from "@mui/material/";


import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material/";

import {
    formatDate,
    formatDateToMonth,
  } from "../../../helpers/dateTimeHelper";
  import { formatCurrency } from "../../../helpers/currencyPercent-helper";

  import {
    Edit,
    Check,
    Cancel,
    RemoveRedEye,
    FindInPage,
    Save,
    Brightness1,
  } from "@mui/icons-material/";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";

export default function DetalleVentasVendedorModal(props) {
  const [open, setOpen] = useState(props.open);
  const [solicitudSelected, setSolicitudSelected] = useState(null);
  const [detalleVentas, setDetalleVentas] = useState([]);
  const [detalleDescuentos, setDetalleDescuentos] = useState([]);
  const [solicitudesADescontar, setSolicitudesADescontar] = useState([]);


  useEffect(() => {
    setOpen(props.open);
  }, [props.open])


  const handleClose = () => {
    props.closeModalCallBack();
    
  };

  const sumaVenta = props.detalleVentas.filter(s => s.cancelada === false).reduce((accumulator, object) => {
    if (object.comisionaVentas) {
      return parseFloat(accumulator) + parseFloat(object.montoTotal);
    } else {
      return parseFloat(accumulator);
    }
  }, 0);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullScreen={false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        {props.vendedor != null ? (
          <>
            <Typography variant="h5">
              {props.vendedor}
            </Typography>

         <p style={{ fontSize: "1rem", color: "#666" }}>
              Semana del {formatDate(props.fecha)}
            </p> 
          </>
        ) : null}
      </DialogTitle>
      <DialogContent style={{ padding: "0px 20px 20px 40px" }}>
        <Typography variant="h6">Ventas</Typography>

        <Table
          aria-label="simple table"
          size="small"
          style={{ fontSize: "1px" }}
        >
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>

              <TableCell align="left">Cliente</TableCell>
              <TableCell align="right">Aprobada</TableCell>

              <TableCell align="right">Venta</TableCell>
              <TableCell alighn="left">Producto(s)</TableCell>
              <TableCell alighn="left">Cancelada</TableCell>
              <TableCell align="center">Estado operación</TableCell>
            </TableRow>
          </TableHead>

          <TableBody style={{ fontSize: "1px" }}>
            {props.detalleVentas.map((row, index) => (
              <TableRow key={index} style={{ fontSize: "1px" }}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ color: row.cancelada ? "#888" : "#111" }}
                >
                  {row.idSolicitud}
                </TableCell>

                <TableCell
                  style={{
                    color: row.cancelada
                      ? "#888"
                      : row.esSolicitudClienteNuevo
                      ? "green"
                      : "#111",
                  }}
                  align="left"
                >
                  {row.nombreCliente}
                  <p>Zona: { row.zona }</p>
                </TableCell>

                <TableCell
                  align="right"
                  style={{ color: row.cancelada ? "#888" : "#111" }}
                >
                  {formatDate(row.fechaAprobacion)}
                </TableCell>

                <TableCell
                  align="right"
                  style={{ color: row.cancelada ? "#888" : "#111" }}
                >
                  {formatCurrency(row.montoTotal)}
                </TableCell>

                <TableCell
                  style={{
                    color: row.cancelada
                      ? "#888"
                      : row.esProductoUsado
                      ? "#ff8000"
                      : "#111",
                  }}
                  align="left"
                >
                  {row.descripcionProductos}
                </TableCell>

                <TableCell align="center">
                  {row.cancelada ? (
                    <>
                    <Chip
                      icon={<Cancel style={{ color: "#8b0000" }} />}
                      style={{
                        color: "#8b0000",
                        backgroundColor: "#ffc5c5",
                        fontWeight: "500",
                      }}
                      label="Cancelada"
                    ></Chip>
                    <Typography variant="caption">
                        { formatDate(row.fechaCambioEstadoSolicitud)}
                    </Typography>
</>
                  ) : null}
                </TableCell>

                <TableCell align="center">
                  <Chip
                    label={row.estadoOperacion}
                    size="small"
                    style={{
                      fontWeight: "500",
                      color: "#FFF",
                      backgroundColor: row.estadoColor,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}

            <TableRow style={{ border: 0 }}>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                <b>{formatCurrency(sumaVenta)}</b>
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {props.detalleDescuentos.length > 0 ? (
          <div>
            <Typography variant="h6">Operaciones a descontar</Typography>

            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>

                  <TableCell align="left">Cliente</TableCell>
                  <TableCell align="right">Aprobada</TableCell>

                  <TableCell align="right">Venta</TableCell>
                  <TableCell alighn="left">Producto(s)</TableCell>
                  <TableCell align="center">Estado operación</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.detalleDescuentos.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.idSolicitud}
                    </TableCell>

                    {row.esSolicitudClienteNuevo ? (
                      <TableCell style={{ color: "green" }} align="left">
                        {row.nombreCliente}
                      </TableCell>
                    ) : (
                      <TableCell align="left">{row.nombreCliente}</TableCell>
                    )}

                    <TableCell align="right">
                      {formatDate(row.fechaAprobacion)}
                    </TableCell>

                    <TableCell align="right">
                      {formatCurrency(row.montoTotal)}
                    </TableCell>

                    {row.esProductoUsado ? (
                      <TableCell style={{ color: "#ff8000" }} align="left">
                        {row.descripcionProductos}
                      </TableCell>
                    ) : (
                      <TableCell align="left">
                        {row.descripcionProductos}
                      </TableCell>
                    )}

                    <TableCell align="center">
                      <Chip
                        label={row.estadoOperacion}
                        size="small"
                        style={{
                          fontWeight: "500",
                          color: "#FFF",
                          backgroundColor: row.estadoColor,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : null}

        {props.solicitudesADescontar.length > 0 ? (
          <div>
            <br />
            <br />
            <Typography variant="h6">Solicitudes a descontar</Typography>

            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>

                  <TableCell align="left">Cliente</TableCell>
                  <TableCell align="right">Aprobada</TableCell>

                  <TableCell align="right">Venta</TableCell>
                  <TableCell alighn="left">Producto(s)</TableCell>
                  <TableCell align="right">Importe a descontar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.solicitudesADescontar.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.idSolicitud}
                    </TableCell>

                    {row.esSolicitudClienteNuevo ? (
                      <TableCell style={{ color: "green" }} align="left">
                        {row.nombreCliente}
                      </TableCell>
                    ) : (
                      <TableCell align="left">{row.nombreCliente}</TableCell>
                    )}

                    <TableCell align="right">
                      {formatDate(row.fechaAprobacion)}
                    </TableCell>

                    <TableCell align="right">
                      {formatCurrency(row.montoTotal)}
                    </TableCell>

                    {row.esProductoUsado ? (
                      <TableCell style={{ color: "#ff8000" }} align="left">
                        {row.descripcionProductos}
                      </TableCell>
                    ) : (
                      <TableCell align="left">
                        {row.descripcionProductos}
                      </TableCell>
                    )}

                    <TableCell align="right">
                      {formatCurrency(row.descuento)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : null}

        <ul style={{ listStyle: "none", marginTop: "20px" }}>
          <li>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Brightness1 style={{ color: "green" }} />
              </Grid>
              <Grid item>
                <p style={{ margin: "1px", color: "#666" }}>Clientes nuevos</p>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Brightness1 style={{ color: "#ff8000" }} />
              </Grid>
              <Grid item>
                <p style={{ margin: "1px", color: "#666" }}>Producto usado</p>
              </Grid>
            </Grid>
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
