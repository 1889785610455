import React, { Component, Fragment } from "react";
import { MenuItem, TextField }  from '@mui/material/';

class CumpliObjetivoSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cumplioObjetivo: 0,
    };
  }


  onChange = async e => {    
    
    this.setState({
      cumplioObjetivo: e.target.value,     
    });


    this.props.onSelectChange(e);
  } 


   
  async componentDidMount() {
    
   
  
};

  render() {
   
    return (
        <Fragment>
        
        
        <TextField
              style={{ width: "200px", marginTop:'3px' }}
              name="cumplioObjetivo"
              variant="standard"
              size="small"
              select 
              label="Cumplió objetivo"
              value={this.state.cumplioObjetivo}       
              onChange={this.onChange}>
              
              <MenuItem value={0}>Todos</MenuItem>
              <MenuItem value={true}>Si</MenuItem>
              <MenuItem value={false}>No</MenuItem>
              
              
          </TextField>
        </Fragment>
      );
    }
  }


  export default CumpliObjetivoSelect;