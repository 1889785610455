import React from "react";
import {
  Paper,
  Typography,
  Divider,
  Grid,
  ListItem,
  ListItemText,
} from "@mui/material/";

import { formatCurrency } from "../../../helpers/currencyPercent-helper";
import moment from "moment";

export default function CobranzaDelDia(props) {

  const resumen = props.data;

  return (
    <Paper
      style={{
        borderRadius: "0.75rem",
      }}
    >
      <div className="p-10">
        <Typography variant="h6">
          Cobranzas del día{" "}
          <small style={{ fontSize: "0.9rem", color: "#444" }}>
            {moment().format("DD/MM/YYYY")}
          </small>
        </Typography>
      </div>

      <Divider />
      {resumen == null ? null : (
        <Grid container dense={true}>
          <Grid item>
            <ListItem>
              <ListItemText
                primary={<>{formatCurrency(resumen.cobranzaEfectivo)}</>}
                secondary="Efectivo"
              />
            </ListItem>
          </Grid>

          <Grid item>
            <ListItem>         
              <ListItemText
                primary={<>{formatCurrency(resumen.cobranzaTransferencias)}</>}
                secondary="Transferencias"
              />
            </ListItem>
          </Grid>
          <Grid item>
            <ListItem>         
              <ListItemText
                primary={<>{formatCurrency(resumen.cobranzaMercadoPago)}</>}
                secondary="Mercado pago"
              />
            </ListItem>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
