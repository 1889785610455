import React, { Component, Fragment } from "react";
import { axiosInstance } from '../../services/api/axiosInstance';
import { MenuItem, TextField }  from '@mui/material/';

class EquipoVentaSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idEquipoDeVenta: '0',
      equiposDeVenta: [],    
    };
  }


  onChange = async e => {    
    
    this.setState({
      idEquipoDeVenta: e.target.value,     
    });


    this.props.onSelectChange(e);
  } 

  renderEquipoVentaOptions() {
    return this.state.equiposDeVenta.map((dt, i) => {
      return (
        <MenuItem
          value={dt.id}
          key={i} name={dt.text}>{dt.text}
        </MenuItem>          
      );
    });
  }
  

   
  async componentDidMount() {
    
    const equiposDeVenta = await axiosInstance.get(`/api/ComisionesVendedores/GetEquiposDeVentasForSelect`);             

    this.setState({
        equiposDeVenta: equiposDeVenta.data,      
    })
  
};

  render() {
   
    return (
        <Fragment>
        
        
        <TextField
              style={{ width: "200px", marginTop:'3px' }}
              name="idEquipoDeVenta"
              variant="standard"
              size="small"
              select 
              label="Equipo de venta"
              value={this.state.idEquipoDeVenta}       
              onChange={this.onChange}>
                    <MenuItem
                  value={0}
              >Todos
                      </MenuItem>        

              {this.renderEquipoVentaOptions()}
          </TextField>
        </Fragment>
      );
    }
  }


  export default EquipoVentaSelect;