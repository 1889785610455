import { Stack } from "@mui/material";
import { InfoWindowF } from "@react-google-maps/api";
import ButtonVerGoogleMaps from "./ButtonVerGoogleMaps";

export default function InfoWindowLogistica(props) {
  var marker = props.marker;

  return (
    <div>
      <InfoWindowF
        onCloseClick={() => props.setActiveMarker(null)}
        position={marker.position}
      >
        <div style={{ padding: "1rem 0.5rem 0.5rem 0.5rem" }}>
          <Stack style={{ width: "200px" }}>
            <p>
              <b>Cliente: </b> {marker.cliente}
            </p>
            <p>
              <b>Fecha estimada entrega: </b> {marker.fechaEstimadaDeEntrega}
            </p>
            <p>
              <b>Producto: </b> {marker.producto}
            </p>
            <p>
              <b>Fletero: </b>
              {marker.fletero}
            </p>

            <p style={{ width: "190px" }}>{marker.direccion}</p>

            <ButtonVerGoogleMaps marker={marker} />

            {/* <a href={`geo:0,0?q=${marker.position.lat},${marker.position.lng}`}>
              Ver en Google Maps
            </a> */}
          </Stack>
        </div>
      </InfoWindowF>
    </div>
  );
}
