import { styled } from '@mui/styles';
import Button from '@mui/material/Button';


const PendingButton = styled(Button)(({ theme }) => ({


    color: '#995b00',
    backgroundColor: '#ffe0b2',
    '&:hover': {
      backgroundColor: '#ffd599',
    },
   
  }));


  const ApproveButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#2e7d32',
    '&:hover': {
      backgroundColor: '#246428',
    },

  }));

  const RejectedButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#d32f2f',
    '&:hover': {
      backgroundColor: '#a82525',
    },


  }));

  const CancelButton = styled(Button)(({ theme }) => ({
    color: '#333',
    
    '&:hover': {
    
    },


  }));

  const ExcelButton = styled(Button)(({ theme }) => ({
    color: '#1d6f42',
    borderColor: '#1d6f42',    
    '&:hover': {
      borderColor: '#248b52',    
      
    },
  }));

  
  export { PendingButton, ApproveButton, RejectedButton, CancelButton, ExcelButton };



