import React, { Component } from "react";

import { Paper, Button } from "@mui/material/";

import { Search } from "@mui/icons-material/";

import { StatusOpinionCobrador } from "./statusOpinionCobrador";
import moment from "moment";
import "moment/locale/es";

export class OpinionCobrador extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    var opinion = this.props.data;

    if (opinion !== null) {
      return (
        <Paper className="paper" square={false}>
          <StatusOpinionCobrador
            titulo="Opinión cobrador"
            calificacion={opinion.calificacion}
          />

          {opinion.realizada ? (
            <>
              <br />

              {/* <p className='long-text'>
                    {this.props.solicitud.opinionCobrador}
                </p> */}

              <div class="bubble bubble-bottom-left" contenteditable>
                {opinion.opinion}
              </div>

              <br />
              <br />
              <b style={{ color: "#3E5060", fontSize: "0.75rem" }}>
                {opinion.cobrador}
              </b>
              {opinion.opinionCobrador !== null ? (
                <p style={{ color: "#3E5060", fontSize: "0.75rem" }}>
                  {moment(opinion.fecha).format("dddd DD MMMM YYYY")}
                </p>
              ) : null}
              <Button
                variant="text"
                color="secondary"
                size="small"
                startIcon={<Search />}
              >
                Ver mas opiniones
              </Button>
            </>
          ) : (

            <>
            <br/>
            <p style={{ color: "#3E5060" }}>Sin opinión</p>
            </>
          )}
        </Paper>
      );
    } else {
      return <p>Espere por favor</p>;
    }
  }
}
