import React, { Component } from "react";
import { axiosInstance } from "../../../services/api/axiosInstance";
import {
  Grid,
  Button,
  Paper,
  Tooltip,
  IconButton,
  Typography,
  Divider,
  ListItem,
  ListItemText,
} from "@mui/material/";
import { Link } from "react-router-dom";
import { Edit, Block } from "@mui/icons-material/";
import CustomDataTable from "../../../components/CustomDataTable";
import routeConfig from "../../../constants/routeConfig";
import { ExcelButton } from "../../../components/buttons";

import { AiFillFileExcel } from "react-icons/ai";
import { formatDate } from "../../../helpers/dateTimeHelper";

import { DateRangePickerFilter } from "../../../components/formComponents/dateRangePickerFilter";
import { ConceptoCajaSelect } from "../../../components/formComponents/conceptoCajaSelect";
import { CuentaSelect } from "../../../components/formComponents/cuentaSelect";
import { FaFilter } from "react-icons/fa";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import { EstadoCaja } from "../cajaTesoreria/estadoCaja";

import UsuarioSelect from "../../../components/formComponents/select/usuarioSelect";
import NewButton from "../../../components/common/buttons/NewButton";
import ViewIconButton from "../../../components/common/buttons/ViewIconButton";

import {
  CajaTesoreriaService,
  AsientosManualesService,
} from "../../../services/moduloCajaService";
import { formatCurrency } from "../../../helpers/currencyPercent-helper";

import SaldosDisponibilidades from "../../../components/widgets/caja/SaldosDisponibilidades";
import DashboardCard from "../../../components/widgets/common/Card";
import AttachMoney from "@mui/icons-material/AttachMoney";

const paths = routeConfig.movimientosCaja;

export class AsientosManualesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      url: paths.url,

      idMovimientoCajaTipo: null,
      idCuenta: null,

      start: moment().startOf("month"),
      end: moment().endOf("month"),

      modalOpen: false,
      params: "",

      saldo: 0,
      estado: null,

      idUsuario: null,

      resumen: null,
    };

    this.dataTableRef = React.createRef();
    this.handleFiltrarClick = this.handleFiltrarClick.bind(this);
    this.handleExcelClick = this.handleExcelClick.bind(this);
    this.handlePendingModalCallBack =
      this.handlePendingModalCallBack.bind(this);
  }

  async componentDidMount() {
    const columns = [
      {
        id: "id",
        name: "Id",
        selector: (row) => row.id,
        sortable: false,
        hide: "sm",
        width: "60px",
      },

      {
        id: "fecha",
        name: "Fecha",
        selector: (row) => row.fecha,
        sortable: false,

        width: "120px",
        cell: (row) => <span>{moment(row.fecha).format("DD/MM/YYYY")}</span>,
      },
      // {
      //   id: "movimientoCajaTipo",
      //   name: "Tipo",
      //   selector: (row) => row.movimientoCajaTipo,
      //   sortable: false,
      //   hide: "sm",
      //   width: "60px",
      // },

      {
        id: "cuenta",
        name: "Cuenta",
        selector: (row) => row.cuenta,
        sortable: false,
        hide: "sm",
        width: "150px",
      },

      {
        id: "concepto",
        name: "Detalle",
        selector: (row) => row.concepto,
        sortable: false,
        cell: (row) => <span>{row.concepto}</span>,
      },

      {
        id: "haber",
        name: "Egreso",
        selector: (row) => row.haber,
        sortable: false,
        hide: "sm",
        width: "110px",

        cell: (row) => (
          <CurrencyFormat
            value={row.haber}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        ),
      },


      {
        id: "debe",
        name: "Ingreso",
        selector: (row) => row.debe,
        sortable: false,
        hide: "sm",
        width: "110px",
        cell: (row) => (
          <CurrencyFormat
            value={row.debe}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
          />
        ),
      },

  

      // {
      //   name: "",
      //   button: true,
      //   cell: (row) => (
      //     <Link to={paths.edit + "/" + row.id}>
      //       <Tooltip title="Editar">
      //         <IconButton aria-label="Editar">
      //           <Edit />
      //         </IconButton>
      //       </Tooltip>
      //     </Link>
      //   ),
      //   allowOverflow: true,
      //   width: "60px",
      // },
      {
        cell: (row) => <ViewIconButton to={"/asientos-manuales"} id={row.id} />,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "60px",
      },
    ];

    this.setState({
      columns: columns,
    });

    this.getEstadoCaja();

    const resumenCajaTesoreria = await CajaTesoreriaService.getResumen();

    this.setState({
      resumen: resumenCajaTesoreria,
    });
  }

  async getEstadoCaja() {
    var estado = await CajaTesoreriaService.getEstado();

    this.setState({
      estado: estado,
    });
  }

  async buildParamsQuery() {
    var params = "&fechaDesde=" + formatDate(this.state.start);
    params += "&fechaHasta=" + formatDate(this.state.end);

    if (this.state.idMovimientoCajaTipo !== null) {
      params += "&idMovimientoCajaTipo=" + this.state.idMovimientoCajaTipo;
    }

    if (this.state.idCuenta !== null) {
      params += "&idCuenta=" + this.state.idCuenta;
    }

    if (this.state.idUsuario !== null) {
      params += "&idUsuario=" + this.state.idUsuario;
    }

    this.setState({
      params: params,
    });

    return params;
  }

  async handleFiltrarClick(e) {
    var params = await this.buildParamsQuery();
    this.dataTableRef.current.refreshDataTable("&" + params);
    //this.getSaldo();
  }

  // async getSaldo(e) {
  //   if (this.state.idCuenta !== null) {
  //     const saldoResponse = await axiosInstance.get(
  //       `/api/AsientosManuales/GetSaldo/${this.state.idCuenta}`
  //     );
  //     this.setState({
  //       saldo: saldoResponse.data,
  //     });
  //   }
  // }

  async handleExcelClick(e) {
    var params = await this.buildParamsQuery();

    axiosInstance({
      method: "GET",
      url: "/api/AsientosManuales/GenerateExcel?" + params,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "caja.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  onSelectConceptoChange = (e) => {
    this.setState(
      {
        idMovimientoCajaTipo: e.target.value === 0 ? null : e.target.value,
      },
      () => {
        //this.getSolicitudesAprobadas();
      }
    );
  };

  onSelectCuentaChange = (e) => {
    this.setState(
      {
        idCuenta: e.target.value === 0 ? null : e.target.value,
      },
      () => {
        //this.getSolicitudesAprobadas();
      }
    );
  };

  onSelectUsuarioChange = (value) => {
    this.setState({
      idUsuario: value === null ? null : value,
    });
  };

  onDateRangePickerChange = (start, end) => {
    this.setState({
      start: start,
      end: end,
    });
  };

  handleModal(value) {
    this.setState({
      modalOpen: value,
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    });
  }

  async handlePendingModalCallBack() {
    this.setState({
      modalOpen: false,
    });

    var params = await this.buildParamsQuery();
    this.dataTableRef.current.refreshDataTable("&" + params);
    this.getSaldo();
  }

  cerrarCaja = async () => {
    const MySwal = withReactContent(Swal);

    if (this.state.estado.cerrada) {
      MySwal.fire({
        icon: "question",
        title: "¿Esta seguro que desea cerrar la abrir del día?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonColor: "#FF0000",
        confirmButtonText: "Si, abrir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          var isOk = AsientosManualesService.abrirCaja();

          if (isOk) {
            this.getEstadoCaja();
          }
        }
      });
    } else {
      MySwal.fire({
        icon: "question",
        title: "¿Esta seguro que desea cerrar la caja del día?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonColor: "#FF0000",
        confirmButtonText: "Si, cerrar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          var isOk = AsientosManualesService.cerrarCaja();

          if (isOk) {
            this.getEstadoCaja();
          }
        }
      });
    }
  };

  renderSaldo(saldo, titulo, color) {
    return (
      <Paper
        style={{
          borderRadius: "0.75rem",
          backgroundColor: color,
          color: "#FFF",
        }}
      >
        <div className="p-10">
          <Typography variant="h6">{titulo}</Typography>
        </div>

        <Divider style={{ background: "#FFF" }} />
        <ListItem>
          <ListItemText
            primary={
              <Typography
                variant="subtitle"
                style={{
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                {" "}
                {formatCurrency(saldo)}
              </Typography>
            }
            secondary={
              <Typography variant="body2" style={{ color: "#FFFFFF" }}>
                {"  Saldo al " + moment().format("DD/MM")}
              </Typography>
            }
          />
        </ListItem>
      </Paper>
    );
  }

  render() {
    var resumen = this.state.resumen;

    return (
      <div>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Caja seguridad</Typography>
          </Grid>

          <Grid item>
         
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={this.cerrarCaja}
                startIcon={
                  this.state.estado && this.state.estado.cerrada == false ? (
                    <Block />
                  ) : null
                }
                style={{ float: "right", width: "100%", marginTop: "5px" }}
              >
                {this.state.estado && this.state.estado.cerrada == false
                  ? "Cerrar caja tesorería"
                  : "Abrir caja tesorería"}
              </Button>
    
          </Grid>

          <Grid item>
            <Link to={paths.add}>
              <NewButton to="add" label="Nuevo asiento" />
            </Link>
          </Grid>
        </Grid>

        {resumen == null ? null : (
          <div>
            <br />

            
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                  <Typography variant="subtitle2">Saldos</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <DashboardCard
                      title="Caja"
                      icon={<AttachMoney />}
                      value={resumen.saldoCaja}
                      valueType="currency"
                      color="#4caf50"
                      trend={0}
                      showTrend={false}
                      
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <DashboardCard
                      title="Seguridad"
                      icon={<AttachMoney />}
                      value={resumen.saldoCajaSeguridad}
                      valueType="currency"
                      color="#777"
                      trend={0}
                      showTrend={false}
                    />
                  </Grid>

         

                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <DashboardCard
                      title="Tesorería"
                      icon={<AttachMoney />}
                      value={resumen.saldoTesoreria}
                      valueType="currency"
                      color="cornflowerblue"
                      trend={0}
                      showTrend={false}
                    />
                  </Grid>

              
                </Grid>
              </Grid>
            </Grid>
          
          </div>
        )}


        <Paper className="mt-10 p-20">
          <ExcelButton
            variant="text"
            size="medium"
            startIcon={<AiFillFileExcel />}
            onClick={this.handleExcelClick}
            type="button"
            className="mt-20"
          >
            Exportar
          </ExcelButton>
          <br />

          <Divider style={{ marginBottom: "10px" }} />

          <Grid container spacing={2}>
            <Grid item xs={6} sm={10} md={10} lg={11}>
              <Grid container spacing={4} alignItems="center">
                <Grid item>
                  <DateRangePickerFilter
                    onDateRangePickerChange={this.onDateRangePickerChange}
                  />
                </Grid>
                <Grid item>
                  <ConceptoCajaSelect
                    onSelectChange={this.onSelectConceptoChange}
                  />
                </Grid>
                <Grid item>
                  <CuentaSelect onSelectChange={this.onSelectCuentaChange} />
                </Grid>

                <Grid item>
                  <UsuarioSelect onSelectChange={this.onSelectUsuarioChange} />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={2} md={2} lg={1}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                startIcon={<FaFilter />}
                style={{ float: "right", width: "100%" }}
                onClick={this.handleFiltrarClick}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>

          {/* <div className="p-10">
            <Typography variant="h5">
              Saldo{" "}
              <CurrencyFormat
                value={this.state.saldo}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </Typography>
          </div> */}
          <CustomDataTable
            url={"/api/AsientosManuales"}
            params={this.state.params}
            columns={this.state.columns}
            handleOpenModal={this.handleOpenModal}
            ref={this.dataTableRef}
            showHeader={true}
            elevation={0}
          />
        </Paper>
      </div>
    );
  }
}
