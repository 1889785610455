import React, { Component, Fragment } from "react";
import { MenuItem, TextField }  from '@mui/material/';
import { AsientosManualesService}  from '../../services/moduloCajaService'

export class ConceptoCajaSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '0',
      options: [],    
    };
  }


  onChange = async e => {    
    
    this.setState({
      id: e.target.value,     
    });


    this.props.onSelectChange(e);
  } 

  renderOptions() {
    return this.state.options.map((dt, i) => {
      return (
        <MenuItem
          value={dt.id}
          key={i} name={dt.text}>{dt.text}
        </MenuItem>          
      );
    });
  }
  

   
  async componentDidMount() {
    
    const response = await AsientosManualesService.getTipoMovimientosCajaForSelect();             

    this.setState({
      options: response,      
    })
  
};

  render() {
   
    return (
        <Fragment>
        
        
        <TextField
              style={{ width: "200px" }}
              name="id"
              variant="standard"
              size="small"
              select 
              label="Tipo de movimiento"
              value={this.state.id}       
              onChange={this.onChange}>
                    <MenuItem
                      value={0}
                    >
                      Todos
                    </MenuItem>        

              {this.renderOptions()}
          </TextField>
        </Fragment>
      );
    }
  }


