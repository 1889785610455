import React, { useState, useEffect } from "react";

import { axiosInstance } from "./../../services/api/axiosInstance";
import {
  Paper,
  Typography,
  ImageList,
  ImageListItem,
  Button,
} from "@mui/material";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { formatDate } from "../../helpers/dateTimeHelper";
import { GaleriaImagenesAPI as API } from "./../../apis/galeriaImagenesAPI";
import appConfig from "./../../constants/appConfig";

import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material/";
import { Delete, MoreVert, Sync } from "@mui/icons-material";
import LoadWrapper from "components/common/LoadWrapper";
import PropTypes from "prop-types";

const pattern = [
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const [valueTab, setValueTab] = React.useState(0);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function GalleryImage(props) {
  const [imagenes, setImagenes] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [modalImagenOpen, setModalImagenOpen] = useState(false);
  const [valueTab, setValueTab] = React.useState(0);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getClienteImagenes(props.idCliente);
  }, [props.idCliente]);

  const getClienteImagenes = async (idCliente) => {
    if (idCliente != undefined) {
      setLoading(true);

      const response = await API.getClienteImagenes(idCliente);

      if (response !== undefined && response.status == "200") {
        const imagenesCopy = [...response.data];

        for (var i = 0; i < imagenesCopy.length; i++) {
          var idImagen = imagenesCopy[i].idImagen;

          await axiosInstance({
            url:
              appConfig.productionURL +
              "/api/GaleriaImagenes/GetImagen/" +
              idImagen,
            method: "GET",
            responseType: "blob",
          }).then(function (response) {
            if (response !== undefined && response.status == "200") {
              var imageUrl = URL.createObjectURL(response.data);

              imagenesCopy[i] = { ...imagenesCopy[i], src: imageUrl };
            }
          });
        }

        setImagenes(imagenesCopy);
      }

      setLoading(false);
    }
  };

  const rotateImage = async (idImagen) => {
    await axiosInstance({
      url: "/api/GaleriaImagenes/RotarImagen/" + idImagen,
      method: "PUT",
      responseType: "blob",
    }).then(function (response) {
      getClienteImagenes(props.idCliente);
    });
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <LoadWrapper loading={loading} error={error}>
      <Paper className="paper">
        <Typography variant="h6" className="mt-0">
          Galería de imagenes
        </Typography>

        <br />
        <Tabs
          value={valueTab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Control" {...a11yProps(0)} />
          <Tab label="Vendedor" {...a11yProps(1)} />
          <Tab label="Verificador" {...a11yProps(2)} />
          <Tab label="Cobranzas" {...a11yProps(3)} />
          <Tab label="Logística" {...a11yProps(4)} />
        </Tabs>

        <CustomTabPanel value={valueTab} index={0}>
          <SimpleReactLightbox>
            <SRLWrapper>
              <ImageList variant="quilted" cols={4} rowHeight={200}>
                {imagenes
                  .filter((i) => i.idTipoImagenCliente == 1)
                  .map((item, index) => (
                    <ImageListItem
                      key={item.src}
                      cols={
                        pattern[
                          index -
                            Math.floor(index / pattern.length) * pattern.length
                        ].cols
                      }
                      rows={
                        pattern[
                          index -
                            Math.floor(index / pattern.length) * pattern.length
                        ].rows
                      }
                      sx={{
                        opacity: ".75",
                        transition: "opacity .3s linear",
                        cursor: "pointer",
                        "&:hover": { opacity: 1 },
                      }}
                    >
                  
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip title="Options">
                            <IconButton
                              onClick={handleClick}
                              sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                              }}
                            >
                              <MoreVert fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 2px rgba(0,0,0,0.02))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem>
                            <ListItemIcon>
                              <Delete />
                            </ListItemIcon>
                            Eliminar imagen
                          </MenuItem>
                          <MenuItem onClick={() => rotateImage(item.idImagen)}>
                            <ListItemIcon>
                              <Sync />
                            </ListItemIcon>
                            Rotar imagen
                          </MenuItem>
                        </Menu>
                 

                      <img
                        src={item.src}
                        // {...srcset(
                        //   item.src,
                        //   200,
                        //   pattern[
                        //     index - Math.floor(index / pattern.length) * pattern.length
                        //   ].rows,
                        //   pattern[
                        //     index - Math.floor(index / pattern.length) * pattern.length
                        //   ].cols
                        // )}
                        alt={item.idImagen}
                        loading="lazy"
                      />
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          color: "white",
                          background: "rgba(0,0,0, .4)",
                          p: "5px",

                          width: "100%",
                        }}
                      >
                        {formatDate(item.fecha)}

                        {" - " + item.idTipoImagenCliente}
                      </Typography>
                    </ImageListItem>
                  ))}
              </ImageList>
            </SRLWrapper>
          </SimpleReactLightbox>
        </CustomTabPanel>

        <CustomTabPanel value={valueTab} index={1}>
          <SimpleReactLightbox>
            <SRLWrapper>
              <ImageList variant="quilted" cols={4} rowHeight={200}>
                {imagenes
                  .filter((i) => i.idTipoImagenCliente == 2)
                  .map((item, index) => (
                    <ImageListItem
                      key={item.src}
                      cols={
                        pattern[
                          index -
                            Math.floor(index / pattern.length) * pattern.length
                        ].cols
                      }
                      rows={
                        pattern[
                          index -
                            Math.floor(index / pattern.length) * pattern.length
                        ].rows
                      }
                      sx={{
                        opacity: ".75",
                        transition: "opacity .3s linear",
                        cursor: "pointer",
                        "&:hover": { opacity: 1 },
                      }}
                    >
                
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip title="Options">
                            <IconButton
                              onClick={handleClick}
                              sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                              }}
                            >
                              <MoreVert fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 2px rgba(0,0,0,0.02))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem>
                            <ListItemIcon>
                              <Delete />
                            </ListItemIcon>
                            Eliminar imagen
                          </MenuItem>
                          <MenuItem onClick={() => rotateImage(item.idImagen)}>
                            <ListItemIcon>
                              <Sync />
                            </ListItemIcon>
                            Rotar imagen
                          </MenuItem>
                        </Menu>
                 

                      <img
                        src={item.src}
                        // {...srcset(
                        //   item.src,
                        //   200,
                        //   pattern[
                        //     index - Math.floor(index / pattern.length) * pattern.length
                        //   ].rows,
                        //   pattern[
                        //     index - Math.floor(index / pattern.length) * pattern.length
                        //   ].cols
                        // )}
                        alt={item.idImagen}
                        loading="lazy"
                      />
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          color: "white",
                          background: "rgba(0,0,0, .4)",
                          p: "5px",

                          width: "100%",
                        }}
                      >
                        {formatDate(item.fecha)}

                        {" - " + item.idTipoImagenCliente}
                      </Typography>
                    </ImageListItem>
                  ))}
              </ImageList>
            </SRLWrapper>
          </SimpleReactLightbox>
        </CustomTabPanel>

        <CustomTabPanel value={valueTab} index={2}>
          <SimpleReactLightbox>
            <SRLWrapper>
              <ImageList variant="quilted" cols={4} rowHeight={200}>
                {imagenes
                  .filter((i) => i.idTipoImagenCliente == 3)
                  .map((item, index) => (
                    <ImageListItem
                      key={item.src}
                      cols={
                        pattern[
                          index -
                            Math.floor(index / pattern.length) * pattern.length
                        ].cols
                      }
                      rows={
                        pattern[
                          index -
                            Math.floor(index / pattern.length) * pattern.length
                        ].rows
                      }
                      sx={{
                        opacity: ".75",
                        transition: "opacity .3s linear",
                        cursor: "pointer",
                        "&:hover": { opacity: 1 },
                      }}
                    >
              
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip title="Options">
                            <IconButton
                              onClick={handleClick}
                              sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                              }}
                            >
                              <MoreVert fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 2px rgba(0,0,0,0.02))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem>
                            <ListItemIcon>
                              <Delete />
                            </ListItemIcon>
                            Eliminar imagen
                          </MenuItem>
                          <MenuItem onClick={() => rotateImage(item.idImagen)}>
                            <ListItemIcon>
                              <Sync />
                            </ListItemIcon>
                            Rotar imagen
                          </MenuItem>
                        </Menu>
                  

                      <img
                        src={item.src}
                        // {...srcset(
                        //   item.src,
                        //   200,
                        //   pattern[
                        //     index - Math.floor(index / pattern.length) * pattern.length
                        //   ].rows,
                        //   pattern[
                        //     index - Math.floor(index / pattern.length) * pattern.length
                        //   ].cols
                        // )}
                        alt={item.idImagen}
                        loading="lazy"
                      />
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          color: "white",
                          background: "rgba(0,0,0, .4)",
                          p: "5px",

                          width: "100%",
                        }}
                      >
                        {formatDate(item.fecha)}

                        {" - " + item.idTipoImagenCliente}
                      </Typography>
                    </ImageListItem>
                  ))}
              </ImageList>
            </SRLWrapper>
          </SimpleReactLightbox>
        </CustomTabPanel>

        <CustomTabPanel value={valueTab} index={3}>
          <SimpleReactLightbox>
            <SRLWrapper>
              <ImageList variant="quilted" cols={4} rowHeight={200}>
                {imagenes
                  .filter((i) => i.idTipoImagenCliente == 4)
                  .map((item, index) => (
                    <ImageListItem
                      key={item.src}
                      cols={
                        pattern[
                          index -
                            Math.floor(index / pattern.length) * pattern.length
                        ].cols
                      }
                      rows={
                        pattern[
                          index -
                            Math.floor(index / pattern.length) * pattern.length
                        ].rows
                      }
                      sx={{
                        opacity: ".75",
                        transition: "opacity .3s linear",
                        cursor: "pointer",
                        "&:hover": { opacity: 1 },
                      }}
                    >
            
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip title="Options">
                            <IconButton
                              onClick={handleClick}
                              sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                              }}
                            >
                              <MoreVert fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 2px rgba(0,0,0,0.02))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem>
                            <ListItemIcon>
                              <Delete />
                            </ListItemIcon>
                            Eliminar imagen
                          </MenuItem>
                          <MenuItem onClick={() => rotateImage(item.idImagen)}>
                            <ListItemIcon>
                              <Sync />
                            </ListItemIcon>
                            Rotar imagen
                          </MenuItem>
                        </Menu>
            

                      <img
                        src={item.src}
                        // {...srcset(
                        //   item.src,
                        //   200,
                        //   pattern[
                        //     index - Math.floor(index / pattern.length) * pattern.length
                        //   ].rows,
                        //   pattern[
                        //     index - Math.floor(index / pattern.length) * pattern.length
                        //   ].cols
                        // )}
                        alt={item.idImagen}
                        loading="lazy"
                      />
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          color: "white",
                          background: "rgba(0,0,0, .4)",
                          p: "5px",

                          width: "100%",
                        }}
                      >
                        {formatDate(item.fecha)}

                        {" - " + item.idTipoImagenCliente}
                      </Typography>
                    </ImageListItem>
                  ))}
              </ImageList>
            </SRLWrapper>
          </SimpleReactLightbox>
        </CustomTabPanel>

        <CustomTabPanel value={valueTab} index={4}>
          <SimpleReactLightbox>
            <SRLWrapper>
              <ImageList variant="quilted" cols={4} rowHeight={200}>
                {imagenes
                  .filter((i) => i.idTipoImagenCliente == 5)
                  .map((item, index) => (
                    <ImageListItem
                      key={item.src}
                      cols={
                        pattern[
                          index -
                            Math.floor(index / pattern.length) * pattern.length
                        ].cols
                      }
                      rows={
                        pattern[
                          index -
                            Math.floor(index / pattern.length) * pattern.length
                        ].rows
                      }
                      sx={{
                        opacity: ".75",
                        transition: "opacity .3s linear",
                        cursor: "pointer",
                        "&:hover": { opacity: 1 },
                      }}
                    >
                 
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip title="Options">
                            <IconButton
                              onClick={handleClick}
                              sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                              }}
                            >
                              <MoreVert fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 2px rgba(0,0,0,0.02))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem>
                            <ListItemIcon>
                              <Delete />
                            </ListItemIcon>
                            Eliminar imagen
                          </MenuItem>
                          <MenuItem onClick={() => rotateImage(item.idImagen)}>
                            <ListItemIcon>
                              <Sync />
                            </ListItemIcon>
                            Rotar imagen
                          </MenuItem>
                        </Menu>
                   

                      <img
                        src={item.src}
                        // {...srcset(
                        //   item.src,
                        //   200,
                        //   pattern[
                        //     index - Math.floor(index / pattern.length) * pattern.length
                        //   ].rows,
                        //   pattern[
                        //     index - Math.floor(index / pattern.length) * pattern.length
                        //   ].cols
                        // )}
                        alt={item.idImagen}
                        loading="lazy"
                      />
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          color: "white",
                          background: "rgba(0,0,0, .4)",
                          p: "5px",

                          width: "100%",
                        }}
                      >
                        {formatDate(item.fecha)}

                        {" - " + item.idTipoImagenCliente}
                      </Typography>
                    </ImageListItem>
                  ))}
              </ImageList>
            </SRLWrapper>
          </SimpleReactLightbox>
        </CustomTabPanel>
      </Paper>
    </LoadWrapper>
  );
}
