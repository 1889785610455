// src/apis/pageAPI.js

import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const GaleriaImagenesAPI = {

  guardarImagen: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/api/GaleriaImagenes/GuardarImagen/${id}`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.guardarImagen.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },

  guardarClienteImagen: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/api/GaleriaImagenes/guardarClienteImagen/${id}`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.guardarClienteImagen.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },

  

  getClienteImagenes: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/GetClienteImagenes/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getClienteImagenes.name].handleRequestCancellation().signal
        : undefined,
    });

    // returning the page returned by the API
    return response;
  },
};

// defining the cancel API object for pageAPI
const cancelApiObject = defineCancelApiObject(GaleriaImagenesAPI);
