import React from "react";
import { Route } from "react-router-dom";

import { AgendaList } from "./agenda/agendaList";

import { CentroDeCostosList } from "./moduloCaja/centroDeCostosList";

import Dashboard from "./dashboard/dashboard";

import { AsientosManualesList } from "./moduloCaja/asientosManuales/AsientosManualesList";

import { MovimientoNoPagoList } from "./movimientosNoPagos/movimientoNoPagoList";

import { ComisionesVendedores } from "./moduloComisiones/comisionesVendedores/comisionesVendedores";

import { PerformanceVendedores } from "./moduloComisiones/performanceVendedores/performanceVendedores";

import { PerformanceVendedoresMensual } from "./moduloComisiones/performanceVendedores/performanceVendedoresMensual";

import Chat from "./chat/chat";

import { CajaTesoreriaList } from "./moduloCaja/cajaTesoreria/cajaTesoreriaList";

import CarpetaLogistica from "./etapas/logistica/carpetaLogistica";

import { ProspectoForm } from "./prospectos/prospectoForm";
import { AssistantDirection } from "@mui/icons-material";

const RemuneracionList = React.lazy(() =>
  import("./remuneraciones/remuneraciones/remuneracionList")
);

const RemuneracionForm = React.lazy(() =>
  import("./remuneraciones/remuneraciones/RemuneracionForm")
);

const Remuneracion = React.lazy(() =>
  import("./remuneraciones/remuneraciones/Remuneracion")
);

const Balance = React.lazy(() => import("./moduloCaja/balance"));

const ClienteList = React.lazy(() => import("./clientes/clienteList"));

const ClienteForm = React.lazy(() => import("./clientes/clienteForm"));

const ProspectoList = React.lazy(() => import("./prospectos/prospectoList"));

const ProspectoXSList = React.lazy(() =>
  import("./prospectos/prospectoXSList")
);

const ProspectoXSForm = React.lazy(() =>
  import("./prospectos/prospectoXSForm")
);

const MercadoLibre = React.lazy(() => import("./mercadoLibre/MercadoLibre"));

const GestionDePrecios = React.lazy(() => import("./apiWest/GestionDePrecios"));

const PlanDeCuentas = React.lazy(() => import("./moduloCaja/PlanDeCuentas"));

const EquiposDeVentas = React.lazy(() =>
  import("./configuraciones/ventas/equiposDeVentas/equiposDeVentas")
);

const TasaInteresList = React.lazy(() =>
  import("./configuraciones/ventas/tasasDeInteres/tasaInteresList")
);

const TasaDeInteresFormRoute = React.lazy(() =>
  import("./configuraciones/ventas/tasasDeInteres/TasaDeInteresFormRoute")
);

const CuentaList = React.lazy(() =>
  import("./configuraciones/moduloCaja/cuentas/cuentaList")
);

const CuentaFormRoute = React.lazy(() =>
  import("./configuraciones/moduloCaja/cuentas/CuentaFormRoute")
);

const AsientoPrearmadoList = React.lazy(() =>
  import("./configuraciones/moduloCaja/asientosPrearmados/AsientoPrearmadoList")
);

const AsientoPrearmadoFormRoute = React.lazy(() =>
  import(
    "./configuraciones/moduloCaja/asientosPrearmados/AsientoPrearmadoFormRoute"
  )
);

//Agrupaciones de cuentas
const AgrupacionesCuentasList = React.lazy(() =>
  import(
    "./configuraciones/moduloCaja/agrupacionesDeCuentas/AgrupacionesCuentasList"
  )
);

const AgrupacionesCuentasFormRoute = React.lazy(() =>
  import(
    "./configuraciones/moduloCaja/agrupacionesDeCuentas/AgrupacionesCuentasFormRoute"
  )
);

//Caja tesoreria
const CajaTesoreriaFormRoute = React.lazy(() =>
  import("./moduloCaja/cajaTesoreria/CajaTesoreriaFormRoute")
);

const AsientosManualesFormRoute = React.lazy(() =>
  import("./moduloCaja/asientosManuales/AsientosManualesFormRoute")
);

//Escalas comisiones
const EscalaComisionList = React.lazy(() =>
  import("./moduloComisiones/escalasComisiones/escalaComisionList")
);

const EscalasComisionesFormRoute = React.lazy(() =>
  import("./moduloComisiones/escalasComisiones/EscalasComisionesFormRoute")
);

//Comision parametro
const ComisionParametroList = React.lazy(() =>
  import("./moduloComisiones/comisionesParametros/comisionParametroList")
);

const ComisionesParametrosFormRoute = React.lazy(() =>
  import(
    "./moduloComisiones/comisionesParametros/ComisionesParametrosFormRoute"
  )
);

//Objetivos de ventas
const ObjetivoVentaList = React.lazy(() =>
  import("./moduloComisiones/objetivosDeVenta/objetivoVentaList")
);

const ObjetivoVentaFormRoute = React.lazy(() =>
  import("./moduloComisiones/objetivosDeVenta/ObjetivoVentaFormRoute")
);

//Historial de comisiones
const ComisionesVendedoresCapturaList = React.lazy(() =>
  import("./moduloComisiones/historial/comisionesVendedoresCapturaList")
);

const ComisionesVendedoresCaptura = React.lazy(() =>
  import("./moduloComisiones/historial/comisionesVendedoresCaptura")
);

//Reportes PYL
const ReportePerdidaGanancia = React.lazy(() =>
  import("./moduloCaja/reportePerdidaGanancia")
);

//Fleteros
const FleteroList = React.lazy(() =>
  import("./etapas/logistica/fleteros/fleteroList")
);

const ControlList = React.lazy(() => import("./etapas/control/controlList"));

const CarpetaControl = React.lazy(() =>
  import("./etapas/control/carpetaControl")
);

const CalificacionCrediticiaList = React.lazy(() =>
  import("./etapas/calificacionCrediticia/calificacionCrediticiaList")
);

const CarpetaCalificacionCrediticia = React.lazy(() =>
  import("./etapas/calificacionCrediticia/carpetaCalificacionCrediticia")
);

const LogisticaList = React.lazy(() =>
  import("./etapas/logistica/logisticaList")
);

const FleteroFormRoute = React.lazy(() =>
  import("./etapas/logistica/fleteros/FleteroFormRoute")
);

const FleteroTipoVehiculoList = React.lazy(() =>
  import("./etapas/logistica/fleterosTiposVehiculos/fleteroTipoVehiculoList")
);

const FleteroTipoVehiculoFormRoute = React.lazy(() =>
  import(
    "./etapas/logistica/fleterosTiposVehiculos/FleteroTipoVehiculoFormRoute"
  )
);

const AgendaFormRoute = React.lazy(() => import("./agenda/AgendaFormRoute"));

const SoapFormRoute = React.lazy(() => import("./SOAP/Soap"));
const SoapVendedores = React.lazy(() => import("./SOAP/SoapVendedores"));

const VentanaDeClientes = React.lazy(() =>
  import("./ventanaDeClientes/VentanaDeClientes")
);

const ReporteFleteros = React.lazy(() =>
  import("./etapas/logistica/fleteros/ReporteFleteros")
);

const EmpleadosList = React.lazy(() =>
  import("./configuraciones/grupoWest/empleados/EmpleadosList")
);

const EmpleadoForm = React.lazy(() =>
  import("./configuraciones/grupoWest/empleados/EmpleadoForm")
);

const OperacionList = React.lazy(() => import("./operaciones/OperacionList"));

const OperacionCarpeta = React.lazy(() =>
  import("./operaciones/OperacionCarpeta")
);

const ObjetivosDeCobranzaList = React.lazy(() =>
  import("./cobranza/comisiones/objetivosDeCobranza/ObjetivosDeCobranzaList")
);

const ObjetivoDeCobranzaForm = React.lazy(() =>
  import("./cobranza/comisiones/objetivosDeCobranza/ObjetivoDeCobranzaForm")
);

const ComisionesCobradores = React.lazy(() =>
  import("./cobranza/comisiones/Comisiones/ComisionesCobradores")
);

const PerformanceCobradores = React.lazy(() =>
  import("./cobranza/comisiones/Performance/PerformanceCobradores")
);

const EscalaComisionCobranzaList = React.lazy(() =>
  import("./cobranza/comisiones/Escalas/escalaComisionCobranzaList")
);

const ReporteSaludCartera = React.lazy(() =>
  import("./cobranza/reportes/ReporteSaludCartera")
);

const Sincronizaciones = React.lazy(() =>
  import("./cobranza/sincronizaciones/Sincronizaciones")
);

const CatalogoList = React.lazy(() =>
  import("./moduloVentas/cotizaciones/catalogoList")
);

const ProductoInfo = React.lazy(() =>
  import("./moduloVentas/cotizaciones/producto")
);

const Inicio = React.lazy(() => import("./moduloVentas/cotizaciones/Inicio"));

const CotizacionList = React.lazy(() =>
  import("./moduloVentas/cotizaciones/cotizacionList")
);

const Carrito = React.lazy(() => import("./moduloVentas/cotizaciones/Carrito"));

const DeduccionHaberList = React.lazy(() =>
  import("./remuneraciones/deduccionesHaberes/DeduccionHaberList")
);

const DeduccionHaberForm = React.lazy(() =>
  import("./remuneraciones/deduccionesHaberes/DeduccionHaberForm")
);

const ClienteContainer = React.lazy(() =>
  import("./moduloVentas/cotizaciones/Clientes/ClienteContainer")
);

const CotizacionesList = React.lazy(() =>
  import("./cotizaciones/cotizacionesList")
);

const Cotizacion = React.lazy(() => import("./cotizaciones/cotizacion"));

const Imagenes = React.lazy(() => import("./imagenes/imagenes"));

const ReclamoList = React.lazy(() => import("./reclamos/reclamoList"));
const ReclamoForm = React.lazy(() => import("./reclamos/reclamoForm"));

const Anexo4 = React.lazy(() => import("./cobranza/objetivos/Anexo4"));

const AbmCobranzaList = React.lazy(() =>
  import("./cobranza/abmCobranza/abmCobranzaList")
);
const CobranzaForm = React.lazy(() =>
  import("./cobranza/abmCobranza/cobranzaForm")
);

const OpinionCobradorList = React.lazy(() =>
  import("./cobranza/opiniones/OpinionCobradorList")
);

const VerificacionesAmbientalesList = React.lazy(() =>
  import("./verificacionAmbiental/VerificacionesAmbientalesList")
);

const CarpetaVerificacionAmbiental = React.lazy(() =>
  import("./verificacionAmbiental/carpetaVerificacionAmbiental")
);

const VerificacionAmbientalForm = React.lazy(() =>
  import("./verificacionAmbiental/VerificacionAmbientalForm")
);

const VerificacionAmbientalImagenesForm = React.lazy(() =>
  import("./verificacionAmbiental/VerificacionAmbientalImagenesForm")
);

const ReporteSolicitudes = React.lazy(() =>
  import("./reportesSolicitudes/ReporteSolicitudes")
);
const ReporteSolicitudesRechazadas = React.lazy(() =>
  import("./reportesSolicitudes/ReporteSolicitudesRechazadas")
);
const ProductoList = React.lazy(() => import("./productos/ProductoList"));
const ProductoForm = React.lazy(() => import("./productos/ProductoForm"));

const ObjetivosDelDiaList = React.lazy(() =>
  import("./cobranza/objetivosDelDia/ObjetivosDelDiaList")
);

const VerificacionesTelefonicasList = React.lazy(() =>
  import("./verificacionTelefonica/VerificacionesTelefonicasList")
);

const VerificacionTelefonicaForm = React.lazy(() =>
  import("./verificacionTelefonica/VerificacionTelefonicaForm")
);

const CarpetaOpinionCobrador = React.lazy(() =>
  import("./cobranza/opiniones/CarpetaOpinionCobrador")
);

const SolicitudesList = React.lazy(() =>
  import("./solicitudes/SolicitudesList")
);

const SolicitudForm = React.lazy(() => import("./solicitudes/SolicitudForm"));

const CarpetaSolicitudVendedor = React.lazy(() =>
  import("./solicitudes/CarpetaSolicitudVendedor")
);

export default [
  <Route path="/agenda">
    <Route index element={<AgendaList />} />
    <Route path="add" element={<AgendaFormRoute />} />
    <Route path=":id/" element={<AgendaFormRoute />} />
    <Route path=":id/:view" element={<AgendaFormRoute />} />
  </Route>,

  <Route path="/chat">
    <Route index element={<Chat />} />
  </Route>,

  <Route path="/imagenes">
    <Route index element={<Imagenes />} />
  </Route>,

  <Route path="/modulo-ventas">
    <Route index element={<Inicio />} />
  </Route>,

  <Route path="/remuneraciones">
    <Route index element={<RemuneracionList url="/remuneraciones" />} />
    <Route path="add" element={<RemuneracionForm url="/remuneraciones" />} />
    <Route path=":id" element={<Remuneracion url="/remuneraciones" />} />
  </Route>,

  <Route path="/adelantos">
    <Route index element={<DeduccionHaberList />} />
    <Route path="add" element={<DeduccionHaberForm url="/adelantos" />} />
    <Route path=":id" element={<DeduccionHaberForm url="/adelantos" />} />
  </Route>,

  <Route path="/carrito">
    <Route index element={<Carrito />} />
  </Route>,

  <Route path="/nueva-cotizacion">
    <Route index element={<CatalogoList />} />
    <Route path=":id/" element={<ProductoInfo />} />
  </Route>,

  <Route path="/cotizaciones">
    <Route index element={<CotizacionList />} />
  </Route>,

  <Route path="/cotizaciones-oficina">
    <Route index element={<CotizacionesList />} />
    <Route path=":id" element={<Cotizacion url="/cotizaciones-oficina" />} />
  </Route>,

  <Route path="/buscador-clientes">
    <Route index element={<ClienteContainer />} />
  </Route>,

  <Route path="/gestion-de-precios">
    <Route index element={<GestionDePrecios />} />
  </Route>,

  <Route path="/reporte-fleteros">
    <Route index element={<ReporteFleteros />} />
  </Route>,

  <Route path="/ventana-de-clientes">
    <Route index element={<VentanaDeClientes />} />
  </Route>,

  <Route path="/dashboard">
    <Route index element={<Dashboard />} />
  </Route>,

  <Route path="/solicitudes-aprobadas">
    <Route index element={<SoapFormRoute />} />
  </Route>,

  <Route path="/solicitudes-aprobadas-vendedores">
    <Route index element={<SoapVendedores />} />
  </Route>,

  <Route path="/control">
    <Route index element={<ControlList />} />
    <Route path=":id/" element={<CarpetaControl />} />
  </Route>,

  <Route path="/calificacion-crediticia">
    <Route index element={<CalificacionCrediticiaList />} />
    <Route path=":id/" element={<CarpetaCalificacionCrediticia />} />
  </Route>,

  <Route path="/logistica">
    <Route index element={<LogisticaList />} />
    <Route path=":id/" element={<CarpetaLogistica />} />
  </Route>,

  <Route path="/busquedas-mercado-libre">
    <Route index element={<MercadoLibre />} />
  </Route>,

  <Route path="/centro-de-costos">
    <Route index element={<CentroDeCostosList />} />
  </Route>,

  <Route path="/balance">
    <Route index element={<Balance />} />
  </Route>,

  <Route path="/plan-de-cuentas">
    <Route index element={<PlanDeCuentas />} />
  </Route>,

  <Route path={"/no-pagos"}>
    <Route index element={<MovimientoNoPagoList />} />
  </Route>,

  <Route path={"/performance-vendedores"}>
    <Route index element={<PerformanceVendedores />} />
  </Route>,
  <Route path={"/performance-vendedores-mensual"}>
    <Route index element={<PerformanceVendedoresMensual />} />
  </Route>,
  <Route path={"/reporte-perdida-ganancia"}>
    <Route index element={<ReportePerdidaGanancia />} />
  </Route>,
  <Route path={"/equipos-de-ventas"}>
    <Route index element={<EquiposDeVentas />} />
  </Route>,

  <Route path="/tasas-de-interes">
    <Route index element={<TasaInteresList />} />
    <Route path="add" element={<TasaDeInteresFormRoute />} />
    <Route path=":id/" element={<TasaDeInteresFormRoute />} />
    <Route path=":id/:view" element={<TasaDeInteresFormRoute />} />
  </Route>,

  <Route path="/cuentas">
    <Route index element={<CuentaList />} />
    <Route path="add" element={<CuentaFormRoute />} />
    <Route path=":id/" element={<CuentaFormRoute />} />
    <Route path=":id/:view" element={<CuentaFormRoute />} />
  </Route>,

  <Route path="/asientos-prearmados">
    <Route index element={<AsientoPrearmadoList />} />
    <Route path="add" element={<AsientoPrearmadoFormRoute />} />
    <Route path=":id/" element={<AsientoPrearmadoFormRoute />} />
    <Route path=":id/:view" element={<AsientoPrearmadoFormRoute />} />
  </Route>,

  <Route path="/agrupaciones-de-cuentas">
    <Route index element={<AgrupacionesCuentasList />} />
    <Route path="add" element={<AgrupacionesCuentasFormRoute />} />
    <Route path=":id/" element={<AgrupacionesCuentasFormRoute />} />
    <Route path=":id/:view" element={<AgrupacionesCuentasFormRoute />} />
  </Route>,

  <Route path="/caja-tesoreria">
    <Route index element={<CajaTesoreriaList />} />
    <Route path="add" element={<CajaTesoreriaFormRoute />} />
    <Route path=":id/:view" element={<CajaTesoreriaFormRoute />} />
  </Route>,

  <Route path="/asientos-manuales">
    <Route index element={<AsientosManualesList />} />
    <Route path="add" element={<AsientosManualesFormRoute />} />
    <Route path=":id/:view" element={<AsientosManualesFormRoute />} />
  </Route>,

  <Route path="/escalas-comisiones">
    <Route index element={<EscalaComisionList />} />
    <Route path="add" element={<EscalasComisionesFormRoute />} />
    <Route path=":id/" element={<EscalasComisionesFormRoute />} />
    <Route path=":id/:view" element={<EscalasComisionesFormRoute />} />
  </Route>,

  <Route path="/comisiones-parametros">
    <Route index element={<ComisionParametroList />} />
    <Route path="add" element={<ComisionesParametrosFormRoute />} />
    <Route path=":id/" element={<ComisionesParametrosFormRoute />} />
    <Route path=":id/:view" element={<ComisionesParametrosFormRoute />} />
  </Route>,

  <Route path="/objetivos-de-ventas">
    <Route index element={<ObjetivoVentaList />} />
    <Route path="add" element={<ObjetivoVentaFormRoute />} />
    <Route path=":id/" element={<ObjetivoVentaFormRoute />} />
    <Route path=":id/:view" element={<ObjetivoVentaFormRoute />} />
  </Route>,

  <Route path="/comisiones-vendedores">
    <Route index element={<ComisionesVendedores />} />
  </Route>,

  <Route path="/historial-comisiones">
    <Route index element={<ComisionesVendedoresCapturaList />} />
    <Route path=":id/" element={<ComisionesVendedoresCaptura />} />
  </Route>,

  <Route path="/fleteros">
    <Route index element={<FleteroList />} />
    <Route path="add" element={<FleteroFormRoute />} />
    <Route path=":id/" element={<FleteroFormRoute />} />
    <Route path=":id/:view" element={<FleteroFormRoute />} />
  </Route>,

  <Route path="/tipos-de-vehiculos">
    <Route index element={<FleteroTipoVehiculoList />} />
    <Route path="add" element={<FleteroTipoVehiculoFormRoute />} />
    <Route path=":id/" element={<FleteroTipoVehiculoFormRoute />} />
    <Route path=":id/:view" element={<FleteroTipoVehiculoFormRoute />} />
  </Route>,

  <Route path="/empleados">
    <Route index element={<EmpleadosList />} />
    <Route path="add" element={<EmpleadoForm />} />
  </Route>,

  <Route path="/clientes">
    <Route index element={<ClienteList />} />
    <Route path=":id/" element={<ClienteForm />} />
  </Route>,

  <Route path="/prospectos">
    <Route index element={<ProspectoList url="/prospectos" />} />
    <Route path="add" element={<ProspectoForm url="/prospectos" />} />
    <Route path=":id/" element={<ProspectoForm url="/prospectos" />} />
  </Route>,

  <Route path="/prospectosxs">
    <Route index element={<ProspectoXSList url="/prospectosxs" />} />
    <Route path="addxs" element={<ProspectoXSForm url="/prospectosxs" />} />
    <Route path="edit/:id/" element={<ProspectoXSForm url="/prospectosxs" />} />
  </Route>,

  <Route path="/operaciones">
    <Route index element={<OperacionList url="/operaciones" />} />
    <Route path="add" element={<OperacionCarpeta url="/operaciones" />} />
    <Route path=":id/" element={<OperacionCarpeta url="/operaciones" />} />
  </Route>,

  <Route path="/objetivos-de-cobranza">
    <Route
      index
      element={<ObjetivosDeCobranzaList url="/objetivos-de-cobranza" />}
    />
    <Route
      path="add"
      element={<ObjetivoDeCobranzaForm url="/objetivos-de-cobranza" />}
    />
    <Route
      path=":id/"
      element={<ObjetivoDeCobranzaForm url="/objetivos-de-cobranza" />}
    />
  </Route>,

  <Route path="/comisiones-cobradores">
    <Route
      index
      element={<ComisionesCobradores url="/comisiones-cobradores" />}
    />
  </Route>,

  <Route path="/performance-cobradores">
    <Route
      index
      element={<PerformanceCobradores url="/performance-cobradores" />}
    />
  </Route>,

  <Route path="/escalas-comisiones-cobranzas">
    <Route
      index
      element={
        <EscalaComisionCobranzaList url="/escalas-comisiones-cobranzas" />
      }
    />
  </Route>,

  <Route path="/reporte-salud-cartera">
    <Route
      index
      element={<ReporteSaludCartera url="/reporte-salud-cartera" />}
    />
  </Route>,

  <Route path="/sincronizaciones-movimientos">
    <Route
      index
      element={<Sincronizaciones url="/sincronizaciones-movimientos" />}
    />
  </Route>,

  <Route path="/reclamos">
    <Route index element={<ReclamoList url="/reclamo" />} />
    <Route path=":id/" element={<ReclamoForm />} />
    <Route path="add" element={<ReclamoForm />} />
  </Route>,

  <Route path="/anexo-4">
    <Route index element={<Anexo4 url="/generar-objetivos-cobranza" />} />
  </Route>,

  <Route path="/abm-cobranza">
    <Route index element={<AbmCobranzaList url="/abm-cobranza" />} />
    <Route path=":id/" element={<CobranzaForm />} />
    <Route path="add" element={<CobranzaForm />} />
  </Route>,

  <Route path="/productos">
    <Route index element={<ProductoList url="/productos" />} />
    <Route path=":id/" element={<ProductoForm />} />
    <Route path="add" element={<ProductoForm />} />
  </Route>,

  <Route path="/opinion-cobrador">
    <Route index element={<OpinionCobradorList url="/opinion-cobrador" />} />
    <Route path=":id/" element={<CarpetaOpinionCobrador />} />
    <Route path="" element={<CobranzaForm />} />
  </Route>,

  <Route path="/verificacion-ambiental">
    <Route
      index
      element={<VerificacionesAmbientalesList url="/opinion-cobrador" />}
    />
    <Route path=":id/" element={<VerificacionAmbientalForm />} />
    <Route
      path="imagenes/:id"
      element={<VerificacionAmbientalImagenesForm />}
    />

    <Route
      path="carpeta-verificacion-ambiental/:id"
      element={<CarpetaVerificacionAmbiental />}
    />
  </Route>,

  <Route path="/verificacion-telefonica">
    <Route index element={<VerificacionesTelefonicasList />} />
    <Route path=":id/" element={<VerificacionTelefonicaForm />} />
  </Route>,

  <Route path="/reporte-solicitudes">
    <Route index element={<ReporteSolicitudes />} />
  </Route>,

  <Route path="/reporte-solicitudes-rechazadas">
    <Route index element={<ReporteSolicitudesRechazadas />} />
  </Route>,

  <Route path="/objetivos-del-dia">
    <Route index element={<ObjetivosDelDiaList />} />
  </Route>,

  <Route path="/solicitudes">
    <Route index element={<SolicitudesList />} />
    <Route path="add" element={<SolicitudForm />} />
    <Route path=":id/" element={<CarpetaSolicitudVendedor />} />

    
  </Route>,
];
