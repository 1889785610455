// src/apis/pageAPI.js

import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const ProspectoAPI = {
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/prospecto/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    // returning the page returned by the API
    return response;
  },
  create: async function (prospecto, cancel = false) {
    const response = await api.request({
      url: `/api/prospecto`,
      method: "POST",
      data: prospecto,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },
  update: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/api/prospecto/${id}`,
      method: "PUT",
      data: data,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/prospecto/${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },
  getPaginated: async function ({ page, perPage, sortColumn, sortDirection, query, filters }, cancel = false) {
    var params = {
      page: page,
      per_page: perPage,
      column: sortColumn,
      sortDirection: sortDirection,
      query: query,
    };

    Object.assign(params, filters);

    const response = await api.request({
      url: "api/prospecto",
      method: "GET",
      params: params,
      signal: cancel
        ? cancelApiObject[this.getPaginated.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response;
  },




  generateExcel: async function ( { sortColumn, sortDirection, query, filters }, cancel = false) {

    var params = {
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      query: query,        
    };

    Object.assign(params, filters);


    const response = await api.request({
      url: "/api/prospecto/GenerateExcel",
      method: "GET",
      params: params,      
      responseType: "blob",
      signal: cancel
        ? cancelApiObject[this.generateExcel.name].handleRequestCancellation().signal
        : undefined,
    });

       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", "prospectos.xlsx");
       document.body.appendChild(link);
       link.click();

       return response;
  },



  generatePdf: async function ( { sortColumn, sortDirection, query, filters }, cancel = false) {

    var params = {
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      query: query,        
    };

    Object.assign(params, filters);


    const response = await api.request({
      url: "/api/prospecto/GeneratePdf",
      method: "GET",
      params: params,      
      responseType: "blob",
      signal: cancel
        ? cancelApiObject[this.generatePdf.name].handleRequestCancellation().signal
        : undefined,
    });

       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", "prospectos.pdf");
       document.body.appendChild(link);
       link.click();

       return response;

  },

  

};

// defining the cancel API object for pageAPI
const cancelApiObject = defineCancelApiObject(ProspectoAPI);
