import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Edit, LocalShipping, CalendarMonth, LockClock } from "@mui/icons-material/";


export default function CustomCard(props) {




  return (
    <Card sx={{ maxWidth: 345 }} elevation={0} style={{border: '1px solid #eee', borderRadius:'12px'}}>
      <CardHeader
        avatar={
          <Avatar
          style={{
            backgroundColor: props.title  ?"#368551" : "#880000",
          }}
        >
          <LocalShipping style={{ color: "#fff" }} />
        </Avatar>
        }
    
    
        title= { props.title ? <p style={{marginBottom:'0px'}}>{props.title}</p > : <p style={{marginBottom:'0px', color:'#880000'}}> Sin información</p>}
        subheader={props.subTitle}
      />
        <Divider />
    
      <CardContent>
       { props.children }
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
     
      </CardActions> */}
    
    </Card>
  );
}