import { Button, CircularProgress } from "@mui/material";

export default function LoadingButton(props) {
  return (
    <Button
      variant="contained"
      color= { props.color ? props.color :  "primary" }
      size="small"
      type="submit"
      fullWidth={false}
      startIcon={
        props.isLoading ? (
          <CircularProgress color="inherit" size="1.5rem" />
        ) : (
          <div>{props.icon}</div>
        )
      }
      disabled={props.isLoading}
    >
      {props.isLoading ? props.loadingText : props.text}
    </Button>
  );
}
