import React, { Component } from "react";
import {
  Grid,
  Button,
  Paper,
  Tooltip,
  IconButton,
  ListItem,
  Divider,
  Typography,
} from "@mui/material/";
import { Link } from "react-router-dom";
import { RemoveRedEye, AccountBalance } from "@mui/icons-material/";
import { FaFilter } from "react-icons/fa";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

import ListItemText from "@mui/material/ListItemText";

import CustomDataTable from "../../../components/CustomDataTable";
import routeConfig from "../../../constants/routeConfig";
import { ExcelButton } from "../../../components/buttons";

import { AiFillFileExcel } from "react-icons/ai";
import { formatDate, getHour } from "helpers/dateTimeHelper";

import { DateRangePickerFilter } from "../../../components/formComponents/dateRangePickerFilter";
import { ConceptoCajaAzulSelect } from "../../../components/formComponents/conceptoCajaAzulSelect";

import NewButton from "../../../components/common/buttons/NewButton";

import { CajaTesoreriaService } from "../../../services/moduloCajaService";

import CobranzaDelDia from "../../../components/widgets/cobranzas/CobranzaDelDia";
import SaldosDisponibilidades from "../../../components/widgets/caja/SaldosDisponibilidades";
import { formatCurrency } from "../../../helpers/currencyPercent-helper";

import { EstadoCaja } from "../cajaTesoreria/estadoCaja";
import { Save, Block } from "@mui/icons-material/";

const paths = routeConfig.movimientosCajaSimplificado;

export class CajaTesoreriaList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      url: paths.url,

      idMovimientoCajaTipo: null,
      idCuenta: null,

      start: moment(),
      end: moment(),

      modalOpen: false,
      params: "",

      subTotal: 0,
      resumen: null,
      estado: null,
    };

    this.dataTableRef = React.createRef();
    this.handleFiltrarClick = this.handleFiltrarClick.bind(this);

    this.handleExcelClick = this.handleExcelClick.bind(this);


    
  }

  async componentDidMount() {
    const columns = [
      {
        id: "id",
        name: "Id",
        selector: (row) => row.id,
        sortable: false,
        hide: "sm",
        width: "60px",
      },

      {
        id: "fecha",
        name: "Fecha",
        selector: (row) => row.fecha,
        sortable: false,

        width: "105px",
        cell: (row) => <span>{moment(row.fecha).format("DD/MM/YYYY")}</span>,
      },

      {
        id: "fechaCreacion",
        name: "Hora",
        selector: (row) => row.fechaCreacion,
        sortable: false,

        width: "105px",
        cell: (row) => <span>{getHour(row.fechaCreacion)}</span>,
      },

      

      {
        id: "cuenta",
        name: "cuenta",
        selector: (row) => row.cuenta,
        sortable: false,
        hide: "sm",
        width: "150px",
      },
      {
        id: "concepto",
        name: "Detalle",
        selector: (row) => row.concepto,
        sortable: false,

        cell: (row) => <span>{row.concepto}</span>,
      },

      {
        id: "haber",
        name: "Egreso",
        selector: (row) => row.haber,
        sortable: false,
        hide: "sm",
        width: "110px",

        cell: (row) => formatCurrency(row.haber),
      },

      {
        id: "debe",
        name: "Ingreso",
        selector: (row) => row.debe,
        sortable: false,
        hide: "sm",
        width: "110px",
        cell: (row) => formatCurrency(row.debe),
      },

      {
        cell: (row) => (
          <Link to={row.id + "/" + 1}>
            <Tooltip title="Ver">
              <IconButton aria-label="ver">
                <RemoveRedEye />
              </IconButton>
            </Tooltip>
          </Link>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "60px",
      },
    ];

    await this.buildParamsQuery();

    this.setState({
      columns: columns,
    });

    const resumenCajaTesoreria = await CajaTesoreriaService.getResumen();

    this.setState({
      resumen: resumenCajaTesoreria,
    });

    const estado = await CajaTesoreriaService.getEstado();

    this.setState({
      estado: estado,
    });
  }

  async buildParamsQuery() {
    var params = "&fechaDesde=" + formatDate(this.state.start);
    params += "&fechaHasta=" + formatDate(this.state.end);

    if (this.state.idMovimientoCajaTipo !== null) {
      params += "&idMovimientoCajaTipo=" + this.state.idMovimientoCajaTipo;
    }

    this.setState({
      params: params,
    });

    return params;
  }

  async handleFiltrarClick(e) {
    var params = await this.buildParamsQuery();
    this.dataTableRef.current.refreshDataTable("&" + params);
    this.getSubtotal();
  }

  async getSubtotal(e) {
    if (this.state.idMovimientoCajaTipo !== null) {
      var params = await this.buildParamsQuery();

      const subtotalResponse = await CajaTesoreriaService.getSubTotal(params);

      this.setState({
        subTotal: subtotalResponse,
      });
    }
  }

  onSelectConceptoChange = (e) => {
    this.setState(
      {
        idMovimientoCajaTipo: e.target.value === 0 ? null : e.target.value,
      },
      () => {
        //this.getSolicitudesAprobadas();
      }
    );
  };

  onSelectCuentaChange = (e) => {
    this.setState(
      {
        idCuenta: e.target.value === 0 ? null : e.target.value,
      },
      () => {
        //this.getSolicitudesAprobadas();
      }
    );
  };

  onDateRangePickerChange = (start, end) => {
    this.setState({
      start: start,
      end: end,
    });
  };

  async handleExcelClick(e) {

    var params = await this.buildParamsQuery();


    CajaTesoreriaService.generateExcel(params);
  }

  renderSaldo(saldo, titulo, color) {
    return (
      <Paper
        style={{
          borderRadius: "0.75rem",
          backgroundColor: color,
          color: "#FFF",
        }}
      >
        <div className="p-10">
          <Typography variant="h6">{titulo}</Typography>
        </div>

        <Divider style={{ background: "#FFF" }} />
        <ListItem>
          <ListItemText
            primary={
              <Typography
                variant="subtitle"
                style={{
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                {" "}
                {formatCurrency(saldo)}
              </Typography>
            }
            secondary={
              <Typography variant="body2" style={{ color: "#FFFFFF" }}>
                {"  Saldo al " + moment().format("DD/MM")}
              </Typography>
            }
          />
        </ListItem>
      </Paper>
    );
  }

  render() {
    var title = paths.label;
    var resumen = this.state.resumen;

    return (
      <div>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">
              {title}. <small>Asientos manuales</small>
            </Typography>
          </Grid>

          {/* <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={this.cerrarCaja}
              startIcon={<Save />}
            >
              Guardar captura saldos
            </Button>
          </Grid> */}
        </Grid>

        {resumen == null ? null : (
          <div>
            <br />
            <Grid container spacing={2}>
              <Grid item lg={2} md={4} sm={4} xs={12}>
                {this.renderSaldo(
                  resumen.saldoTesoreria,
                  "Tesorería",
                  "cornflowerblue"
                )}
                <br />
              </Grid>

              <Grid item lg={2} md={4} sm={4} xs={12}>
                {this.renderSaldo(
                  resumen.saldoCajaSeguridad,
                  "Seguridad",
                  "#777"
                )}
                <br />
              </Grid>

              <Grid item lg={2} md={4} sm={4} xs={12}>
                {this.renderSaldo(
                  resumen.saldo1001,
                  "Saldo 1001",
                  "green"
                )}
                <br />
              </Grid>

              <Grid item lg={4} md={12} sm={12} xs={12}>
                <SaldosDisponibilidades data={resumen} />
              </Grid>

              <Grid item lg={4} md={6} sm={8} xs={12}>
                <CobranzaDelDia data={resumen} />
              </Grid>

              
            </Grid>
          </div>
        )}

        <br />

        <Paper className="mt-10 p-20">
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
              <Grid container spacing={4} alignItems="center">
                <Grid item>
                  <DateRangePickerFilter
                    onDateRangePickerChange={this.onDateRangePickerChange}
                    range="today"
                  />
                </Grid>
                <Grid item>
                  <ConceptoCajaAzulSelect
                    onSelectChange={this.onSelectConceptoChange}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    SubTotal {formatCurrency(this.state.subTotal)}
                  </Typography>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<FaFilter />}
                    style={{ float: "right", width: "100%" }}
                    onClick={this.handleFiltrarClick}
                  >
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  {this.state.estado && this.state.estado.cerrada == false ? (
                    <NewButton to="add" label="Nuevo asiento" />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      disabled
                      style={{ float: "right", width: "100%" }}
                    >
                      {"Caja cerrada "}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ExcelButton
                variant="text"
                size="medium"
                startIcon={<AiFillFileExcel />}
                onClick={this.handleExcelClick}
                type="button"
                className="mt-20"
              >
                Excel
              </ExcelButton>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomDataTable
                url={"api/AsientosManualesTesoreria"}
                params={this.state.params}
                columns={this.state.columns}
                handleOpenModal={this.handleOpenModal}
                ref={this.dataTableRef}
                showHeader={true}
                elevation={0}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}></Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}
