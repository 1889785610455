import React, { Component, Fragment } from "react";
import { Grid } from "@mui/material/";
import UserContext from "../../userContext";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { getHour } from "../../helpers/dateTimeHelper";

export class CurrentUserMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static contextType = UserContext;

  async componentDidMount() {}

  render() {
    var { user } = this.context;

    return (
      <div>
        {user.name === this.props.message.senderUser ? (
          <ListItem>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <ListItemText
                  align="right"
                  primary={this.props.message.message}
                  style={{
                    backgroundColor: "#075E54",
                    color: "#fff",
                    padding: "8px",
                    borderRadius: "6px",
                  }}
                ></ListItemText>
              </Grid>
              <Grid item xs={12}>
                <ListItemText
                  align="right"
                  secondary={getHour(this.props.message.date)}
                ></ListItemText>
              </Grid>
            </Grid>
          </ListItem>
        ) : (
          <ListItem>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <ListItemText
                  align="left"
                  primary={this.props.message.message}
                  style={{
                    backgroundColor: "#333",
                    color: "#fff",
                    padding: "8px",
                    borderRadius: "6px",
                  }}
                ></ListItemText>
              </Grid>
              <Grid item xs={12}>
                <ListItemText
                  align="left"
                  secondary={getHour(this.props.message.date)}
                ></ListItemText>
              </Grid>
            </Grid>
          </ListItem>
        )}
      </div>
    );
  }
}
