import React from "react";
import { Stack, Grid, Avatar } from "@mui/material";
import { BsCheckLg } from "react-icons/bs";

import { Store, Check, RequestQuote } from "@mui/icons-material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

export default function SolicitudStatus(props) {
  const Widget = (props) => {
    return (
      <Grid
        container
        style={{
          border: "1px solid #ccc",
          borderColor: "#fff",
          borderRadius: "50px",
          paddingRight: "15px",
        }}
        alignItems="center"
      >
        {props.verified ? (
          <Avatar
            style={{
              backgroundColor: "#c7e3d0",
            }}
          >
            <Check style={{ color: "#368551" }} />
          </Avatar>
        ) : null}
        <Grid item style={{ marginLeft: "8px" }}>
          <p
            style={{
              fontWeight: "500",
              fontSize: "0.85rem",
              color: "#333",
              margin: "0px",
            }}
          >
            <b>{props.title}</b>
          </p>
          <p
            style={{
              color: "#666",
              lineHeight: "0.90rem",
              fontSize: "0.75rem",
              marginBottom: "4px",
            }}
          >
            {props.description}
          </p>
        </Grid>
      </Grid>
    );
  };

  return props.solicitud !== null ? (
    <Stack direction="row" spacing={2}>
      <div>
        <Widget
          title="Verificación de datos"
          description="Cliente/Solicitud"
          verified={props.solicitud.tieneVerificacionDatos}
        />
      </div>

      <div>
        <Grid
          container
          style={{
            border: "1px solid #ccc",
            borderColor: "#fff",
            borderRadius: "50px",
            paddingRight: "15px",
          }}
          alignItems="center"
        >
          {props.solicitud.tieneVerificacionAmbientalTelefonica == "True" ? (
            <Avatar
              style={{
                backgroundColor: "#c7e3d0",
              }}
            >
              <Check style={{ color: "#368551" }} />
            </Avatar>
          ) : null}

          {props.solicitud.tieneVerificacionAmbientalTelefonica == "False" ? (
            <Avatar
              style={{
                backgroundColor: "#ffd2d2",
              }}
            >
              <QuestionMarkIcon style={{ color: "red" }} />
            </Avatar>
          ) : null}

          <Grid item style={{ marginLeft: "8px" }}>
            <p
              style={{
                fontWeight: "500",
                fontSize: "0.85rem",
                color: "#333",
                margin: "0px",
              }}
            >
              <b>Verificación</b>
            </p>
            <p
              style={{
                color: "#666",
                lineHeight: "0.90rem",
                fontSize: "0.75rem",
                marginBottom: "4px",
              }}
            >
              Ambiental/Teléfonica
            </p>
          </Grid>
        </Grid>
      </div>

      <div>
        <Widget
          title="Opinión cobrador"
          description=""
          verified={props.solicitud.tieneOpinionCobrador}
        />
      </div>

      <div>
        <Grid
          container
          style={{
            border: "1px solid #ccc",
            borderColor: "#fff",
            borderRadius: "50px",
            paddingRight: "15px",
          }}
          alignItems="center"
        >
          <Avatar
            style={{
              backgroundColor: props.solicitud.idOperacion ? "#fff3cd" : "#999",
            }}
          >
            <RequestQuote
              style={{
                color: props.solicitud.idOperacion ? "#856404" : "#eee",
              }}
            />
          </Avatar>

          <Grid item style={{ marginLeft: "8px" }}>
            <p
              style={{
                fontWeight: "500",
                fontSize: "0.85rem",
                color: "#333",
                margin: "0px",
              }}
            >
              <b>{props.solicitud.idOperacion}</b>
            </p>
            <p
              style={{
                color: "#666",
                lineHeight: "0.90rem",
                fontSize: "0.75rem",
                marginBottom: "4px",
              }}
            >
              # Operación
            </p>
          </Grid>
        </Grid>
      </div>
    </Stack>
  ) : null;
}
