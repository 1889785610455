import React, { Component, Fragment } from "react";
import { axiosInstance } from "../../services/api/axiosInstance";
import { Grid, Button, Badge } from "@mui/material/";
import { HubConnectionBuilder, LogLevel, HttpTransportType, JsonHubProtocol, withTransport } from "@microsoft/signalr";
import UserContext from "../../userContext";
import Paper from "@mui/material/Paper";

import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Fab from "@mui/material/Fab";

import SendIcon from "@mui/icons-material/Send";
import { CurrentUserMessage } from "./currentUserMessage";

import { withStyles } from "@mui/styles";
import moment from 'moment';

import  appConfig from './../../constants/appConfig'


const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "75vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "60vh",
    overflowY: "auto",
  },
  customBadge: {
    backgroundColor: (props) => props.color,
    color: "white",
  },
});

function SimpleBadge(props) {
  const { classes } = props;
  return (
    <div>
      <Badge
        classes={{ badge: classes.customBadge }}
        className={classes.margin}
        overlap="circular"
        badgeContent=" "
        variant="dot"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Avatar />
      </Badge>
    </div>
  );
}
const StyledBadge = withStyles(styles)(SimpleBadge);

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connection: null,
      messages: [],
      message: "",

      connected: false,
      connectionId: "",
      users: [],

      selectedIndex: 0,

      chatHistory: [],
    };

    this.joinRoom = this.joinRoom.bind(this);
    this.messageListRef = React.createRef();
  }

  componentWillUnmount() {
    this.closeConnection();
    console.log("closeConnection");
  }

  static contextType = UserContext;

  async joinRoom(room) {
    var { user } = this.context;


    var user = user;

    try {

      const connection = new HubConnectionBuilder()
      .withUrl(appConfig.baseURL + "/chat")
      .configureLogging(LogLevel.Information)
  .withAutomaticReconnect()
  .build();

   
      connection.on("ReceiveMessage", (user, message, date) => {
        this.setMessages(user, message, date);
      });

      connection.on("UsersInRoom", (users) => {
        this.setState({
          users: users,
        });
      });

      await connection.start();

      console.log("🚀 ~ file: chat.js:108 ~ Chat ~ joinRoom ~ connection:", connection)


      await connection.invoke("JoinRoom", { user, room });

      var connectionId;

      await connection.invoke("GetConnectionId").then(function (id) {
        connectionId = id;
      });

      this.setState({
        connection,
        connected: true,
        connectionId: connectionId,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    this.joinRoom("chat");
  }

  async getHistoryChat() {
    // var user = this.state.selectedUser.user;

    // await axiosInstance
    //   .get(`api/chatHistory?senderUserId=${user}`)
    //   .then((response) => {
    //     this.setState({
    //       messages: response.data.data,
    //     }, () => {
    //           this.scrollToMyRef();
    //           this.clearUnReadMessage(user);
    //     });
    //   });
  }

  async closeConnection() {
    try {
      await this.state.connection.stop();
    } catch (e) {
      console.log(e);
    }

    this.setState({
      connected: false,
      users: [],
      messages:[],
    });
  }

  scrollToMyRef = () => {
    if (this.messageListRef && this.messageListRef) {
      var { scrollHeight, clientHeight } = this.messageListRef.current;

      this.messageListRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }
  };

  addUnReadMessage = (user) =>{

    const newState = this.state.users.map((obj) => {
      if (obj.user === user) {

        var count = obj.unReadMessages + 1;
        return { ...obj, unReadMessages: count };
      }
      return obj;
    });

    this.setState((prevState) => {
      let users = Object.assign({}, prevState.users);
      users = newState;
      return { users };
    });
  }

  clearUnReadMessage = (user) =>{

    const newState = this.state.users.map((obj) => {
      if (obj.user === user) {
        
        return { ...obj, unReadMessages: 0 };
      }
      return obj;
    });

    this.setState((prevState) => {
      let users = Object.assign({}, prevState.users);
      users = newState;
      return { users };
    });
  }

  setMessages(user, message, date) {
    var selectedUser = this.state.users[this.state.selectedIndex];

    if (selectedUser.user === user) {
      this.setState({
        messages: [...this.state.messages, { user, message, date }],
      });
    } else {

      this.addUnReadMessage(user);
     
    }

    this.scrollToMyRef();
  }

 

  async sendMessage() {
    try {
      var { user } = this.context;

      var receiverUser = this.state.users[this.state.selectedIndex];

      var senderUser = user;

      var message = this.state.message;

      var date =  moment();
      //console.log("🚀 ~ file: chat.js:247 ~ Chat ~ sendMessage ~ message:", message)

      this.state.connection
        .invoke(
          "SendMessageToUser",
          receiverUser.connectionId,
          this.state.message
        )
        .catch(function (err) {
          return console.error(err.toString());
        });

      this.setState({
        messages: [...this.state.messages, { senderUser, message, date }],
      },()=>{
    this.scrollToMyRef();

      });
    } catch (e) {
      console.log(e);
    }
    this.setState({
      message: "",
      
    });
    
  }

  onChange = (event, value) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleListItemClick = (event, index) => {
    this.setState(
      {
        selectedIndex: index,
        selectedUser: this.state.users[index],
        messages: [],
      },
      () => {
        this.getHistoryChat();

   
      }
    );
  };

  render() {
    const { classes } = this.props;
    var { user } = this.context;
    var { connected } = this.state;
    var { connection } = this.state;

    return (
      <Fragment>
        {!connected ? (
   
          <Button
          color="primary"
          onClick={() => this.joinRoom("chat")}
          variant="outlined"
        >
          Ingresar
        </Button>

          
        ) : (
          <Button
            color="secondary"
            onClick={() => this.closeConnection()}
            variant="outlined"
          >
            Abandonar la sala
          </Button>
        )}

        <div style={{ marginTop: "5px" }}>
          <Grid container component={Paper} className={classes.chatSection}>
            <Grid item xs={3} className={classes.borderRight500}>
              <List>
                <ListItem button key="RemySharp">                  
                  <ListItemText primary={<Typography variant="h6"> {user.name}</Typography>}></ListItemText>
                </ListItem>
              </List>
              <Divider />
              {/* <Grid item xs={12} style={{ padding: "10px" }}> */}
                {/* <TextField
                  id="outlined-basic-email"
                  label="Search"
                  variant="outlined"
                  fullWidth
                /> */}
              {/* </Grid> */}
              <Divider />
              <List>
                {this.state.users.map((u, idx) => (
                  <div key={idx}>
                    {u.user !== user.name ? (
                      <ListItemButton
                        key={idx}
                        selected={this.state.selectedIndex === idx}
                        onClick={(event) =>
                          this.handleListItemClick(event, idx)
                        }
                      >
                        <ListItemIcon>
                          <StyledBadge color="#25D366" />
                        </ListItemIcon>
                        <ListItemText
                          primary={u.user}
                          secondary={u.unReadMessages + " mensajes sin leer"}
                        >
                          {u.user}
                        </ListItemText>

                        {/* <ListItemText primary={u.user} secondary={u.connectionId}>{u.user}</ListItemText> */}

                        <ListItemText
                          secondary="online"
                          align="right"
                        ></ListItemText>
                      </ListItemButton>
                    ) : null}
                  </div>
                ))}
              </List>
            </Grid>
            <Grid item xs={9}>
              {/* {this.state.users.length > 0
                ? this.state.users[this.state.selectedIndex].user
                : "nada"} */}

              <List className={classes.messageArea} ref={this.messageListRef}>
                {this.state.messages.map((message, index) => {
                  return <CurrentUserMessage message={message} key={index} />;
                })}
              </List>

              <Divider />
              <Grid container style={{ padding: "20px" }}>
                <Grid item xs={11}>
                  <TextField
                    id="message"
                    label=""
                    name="message"
                    variant="outlined"
                    fullWidth
                    onChange={this.onChange}
                    value={this.state.message}
                  />
                </Grid>
                <Grid item xs={1} align="right">
                  <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => this.sendMessage()}
                  >
                    <SendIcon />
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Chat);
