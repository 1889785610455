import React, { useEffect } from "react";

import {  Dialog,  DialogTitle,  DialogContent,  Table,  TableRow,  TableCell,  TableHead,  TableBody } from "@mui/material/";

import { formatDate } from "../../helpers/dateTimeHelper";
import { formatCurrency } from "../../helpers/currencyPercent-helper";

export default function BalanceDetalle(props) {
  useEffect(() => {}, [props.open]);

  const handleClose = () => {
    props.handleModalClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>Detalles</DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Concepto</TableCell>
              <TableCell>Cuenta</TableCell>
              <TableCell align="right">Ingreso</TableCell>
              <TableCell align="right">Egreso</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.detalles.map((detalle, index) => {
              return (
                <TableRow>
                  <TableCell>{formatDate(detalle.fecha)}</TableCell>

                  <TableCell>{detalle.concepto}</TableCell>
                  <TableCell>{detalle.conceptoParticular}</TableCell>
                  <TableCell align="right">{formatCurrency(detalle.debe)}</TableCell>
                  <TableCell align="right">{formatCurrency(detalle.haber)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}
