import React, { Component  } from 'react';
import { Chip }  from '@mui/material/';

import { AprobadaIcon } from "./../../components/common/Icons";

 export class ChipCalificacion extends Component {
    constructor(props) {

        super(props);

        this.state = {
         
        };    
    }

    render(){
        var idCalificacion = this.props.idCalificacion;
        var calificacion = this.props.etapa;


        var color ="";
        var backgroundColor = "";
        var icon = "";

        if(idCalificacion !== null && calificacion !== null){

        
            switch(idCalificacion){
                case 1:                        
                    color = '#155724';
                    backgroundColor ='#d4edda';
                    icon = <AprobadaIcon />;
                    break;
                   
                case 2:                        
                    color = '#856404';
                    backgroundColor ='#fff3cd';
                    break;

                case 3:                        
                    color = '#721c24';
                    backgroundColor ='#f8d7da';
                    break;

                default:                        
                    color = '#333';
                    backgroundColor ='#FFF';
                    break;
             

            }

            return (
                <Chip 
                icon={icon}
                style={{ padding:'6px', color: color, backgroundColor: backgroundColor, fontWeight:'500' }} 
                label={this.props.label}>   
                   </Chip>                            
            )
        }
        else{
            return(<p>-</p>);
        }
            
    }

 }

