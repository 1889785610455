import React, { Component } from 'react';
import DataTable, {createTheme} from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';
import { axiosInstance } from '../services/api/axiosInstance';
import { withStyles } from "@mui/styles";

const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de', selectAllRowsItem: false, selectAllRowsItemText: 'Todos' };

const paginationServerOptions = { persistSelectedOnPageChange: true, persistSelectedOnSort: true };

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px',        
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(1),
        },
    },
}));



const LinearIndeterminate = () => {
    const classes = useStyles();

    return (
        <div style={{padding:'1.5rem'}} className={classes.root}>       
            <LinearProgress />
        </div>
    );
};


createTheme("dark", {
    background: {
      default: "transparent",
    },
  });
  
  
  createTheme("light", {
      background: {
        default: "#fafbfb",
      },
    });
  
  const styles = (theme) => ({
    // here I can use theme provided by ThemeProvider
  });





  const customStyles = {
    rows: {
        // style: {
        //     minHeight: '72px', // override the row height
        // },
    },
    headCells: {
        style: {
            paddingLeft: '12px', // override the cell padding for head cells
            paddingRight: '12px',
        },
    },
    cells: {
        style: {
            paddingLeft: '10px', // override the cell padding for data cells
            paddingRight: '10px',
        },
    },
};


class CustomDataTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pending: true,
            data: [],
            columns: props.columns,         
            totalRows: 0,
            perPage: 0,
            page: 0,
            query: '',
            sortColumn: '',
            sortdirection: '',
            conditionalRowStyles: props.conditionalRowStyles,
            elevation: props.elevation == null ? 1 : props.elevation,         
        };             
    } 

    async componentDidMount() {

        var tableState = JSON.parse(localStorage.getItem(`GW${this.props.url}`))


        if (tableState !== null) {

            this.setState({
                perPage: tableState.perPage,
                page: tableState.page,
                query: tableState.query,
                sortColumn: tableState.sortColumn,
                sortdirection: tableState.sortdirection,
            }, () => {
                this.refreshDataTable();
            });
        }
        else {
            this.setState({
                perPage: 10,
                page: 1,
                query: ''
                }, () => {
                this.refreshDataTable();
            });

		}

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.params !== this.props.params) {
               console.log('params has changed.')          
        }
    }

    onChange = async (event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
                this.handleDataTableQueryChange();
        });   

      
    }

    async refreshDataTable(filter) {    

        const { page, perPage, query, sortColumn, sortdirection } = this.state;

        this.setState({ pending: true });

        var getUrl = `${this.props.url}?page=${page}&per_page=${perPage}&query=${query}&column=${sortColumn}&sortdirection=${sortdirection}`;

        console.log("props: " + this.props.params);
        
        if(this.props.params !== undefined){            
            getUrl = getUrl + this.props.params;
        }

        const response = await axiosInstance.get(getUrl);

        this.setState({
            pending: false,
            data: response.data.data,
            totalRows: response.data.total,
            
        });

        this.refreshTableState();

    }

    refreshTableState() {

        var tableState = {
            perPage: this.state.perPage,
            page: this.state.page,
            query: this.state.query,
            sortColumn: this.state.sortColumn,
            sortdirection: this.state.sortdirection
		}

        localStorage.setItem( `GW${this.props.url}`, JSON.stringify(tableState))
	}

    handleDataTableQueryChange = async () => {        
        this.setState({
            page: 1,
        }, () => {
            this.refreshDataTable();
        });   
    }

    handleDataTablePageChange = async page => {       
        this.setState({
            page: page,
        }, () => {
            this.refreshDataTable();
        });   

    }

    handleDataTablePerRowsChange = async (perPage, page) => {
        this.setState({
            perPage: perPage,
            page: page,
        }, () => {
            this.refreshDataTable();
        });   

    }

    handleSort = async (column, sortdirection) => {

        this.setState({
            sortColumn: column.id,
            sortdirection: sortdirection
        }, () => {
            this.refreshDataTable();
        });   
    }










    rendersubHeaderComponent() {
        if (this.props.showHeader === true) {
            return (

                <Grid container direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                
                    >
                 

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField id="query"
                            label="Búsqueda global"
                            variant="outlined"
                            size="small"
                            name="query"
                            style={{ float: 'right' }}
                            //onKeyUp={this.handleGlobalSearchInputKeyUp}
                            onChange={this.onChange}
                            value={this.state.query}
                        />
                    </Grid>



                </Grid>
            );
    }
    }


    render() {
        const { theme } = this.props;

        const { data, totalRows, perPage, elevation } = this.state;

        if (perPage !== 0) {
            return (
                <Paper elevation={elevation}  className="dataTable">
                    <DataTable                        
                        customStyles={customStyles} 
                        columns={this.props.columns}
                        data={data}
                        noHeader
                
                        paginationDefaultPage={this.state.page}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10,15,20,25,30,40,50]}
                        //selectableRows 
                        //customStyles={customStyles}
                        //Clicked={this.handleClick}
                        //Selected={this.handleChange}
                        highlightOnHover
                        selectableRowsHighlight
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationComponentOptions={paginationOptions}
                        paginationServerOptions={paginationServerOptions}
                        onSort={this.handleSort}
                        sortServer

                        onChangeRowsPerPage={this.handleDataTablePerRowsChange}
                        onChangePage={this.handleDataTablePageChange}


                        persistTableHead

                        progressPending={this.state.pending}
                        progressComponent={<LinearIndeterminate />}

                         subHeader={this.props.showHeader}
                         subHeaderComponent={this.rendersubHeaderComponent()}
                         conditionalRowStyles={this.props.conditionalRowStyles}
                         theme={theme.palette.mode == "dark" ? "dark" : "light"}

                         //expandableRows
                         //expandableRowsComponent={this.props.expandableRowsComponent}

                    />
                </Paper>
            );
        }
        else {
            return null;
		}
    }
}
export default withStyles(styles, { withTheme: true })(CustomDataTable);
