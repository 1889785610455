import React from "react";

import { Table, TableRow, TableCell } from "@mui/material";
import { formatDate } from "helpers/dateTimeHelper";
import { formatCurrency } from "helpers/currencyPercent-helper";

export default function CotizacionCarpeta(props) {
  const cotizacion = props.cotizacion;

  return (
    <Table size="small">
      <TableRow>
        <TableCell>
          <b>#</b>
        </TableCell>
        <TableCell> {cotizacion.idCotizacion}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Fecha</b>
        </TableCell>
        <TableCell> {formatDate(cotizacion.fecha)}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <b>Plan</b>
        </TableCell>
        <TableCell>{cotizacion.plan}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={2}>
          <b>Productos</b>
        </TableCell>
      </TableRow>

      {cotizacion.detalles.map((detalle, index) => {
        return (
          <TableRow key={index}>
            <TableCell>
              <img src={detalle.articuloImagen} width="50px" />
            </TableCell>
            <TableCell>
              {detalle.articuloNombre}

              <br />

              <p
                style={{
                  color: "#3E5060",
                  fontSize: "0.8rem",
                }}
              >
                {formatCurrency(detalle.precio)}
              </p>


              

              <p style={{ color: "#3E5060", fontSize: "0.8rem" }}>
                {detalle.canal}
              </p>


              {detalle.permalink ? (
                <a target="_blank" href={detalle.permalink}>
                  Link mercado libre{" "}
                </a>
              ) : null}
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
}
