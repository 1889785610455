import axios from "axios";
import { notification } from "antd";
import { getToken } from "./../../helpers/auth-helper";

import appConfig from "./../../constants/appConfig";

import store from "./../../store";

export const api = axios.create({
  baseURL: appConfig.baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    siteId: 1,
  },
});

// defining a custom error handler for all APIs
const errorHandler = (error) => {


  const statusCode = error.response?.status;

 

  // if (error.code === "ERR_CANCELED") {

  //return Promise.resolve();
  // }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {

    notification.error({
      placement: "bottomRight",
      message: error.message,
      description: error.response.data,
    });

    // store.dispatch({
    //   type: "SNACKBAR_SUCCESS",
    //   message: error.response?.data.title,
    //   snackBarType: "error",
    // });
  }

  return Promise.resolve();
  //return Promise.reject(error);
};

api.interceptors.request.use(async (config) => {
  var token = await getToken();

  if (token !== null) {
    config.headers["Authorization"] = `Bearer ${token}`;
  } else {
    config.headers["Authorization"] = `Bearer 1`;
  }

  return config;
});

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
