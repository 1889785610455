import React, { Component } from "react";

import {
  Grid,
  Button,
  Paper,
  Tooltip,
  TextField,
  IconButton,
  Divider,
} from "@mui/material/";
import { Check, Cancel, FindInPage, Brightness1 } from "@mui/icons-material/";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import esLocale from "date-fns/locale/es";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CurrencyFormat from "react-currency-format";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { AiFillFileExcel } from "react-icons/ai";
import { Typography } from "@mui/material";

import routeConfig from "./../../../constants/routeConfig";
import { ExcelButton } from "./../../../components/buttons";
import {
  formatDate,
  jsDateToLocalISO8601DateString,
} from "../../../helpers/dateTimeHelper";
import EquipoVentaSelect from "./../../../components/formComponents/equipoVentaSelect";
import CumplioObjetivoSelect from "./../../../components/formComponents/cumplioObjetivoSelect";
import VendedorSelect from "./../../../components/formComponents/vendedorSelect";
import store from "./../../../store";
import { axiosInstance } from "./../../../services/api/axiosInstance";

import { green, pink, grey, orange, red, blue } from "@mui/material/colors";
import Assignment from "@mui/icons-material/Assignment";
import Person from "@mui/icons-material/Person";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Percent from "@mui/icons-material/Percent";

import DashboardCard from "./../../../components/widgets/common/Card";
import DashboardCardPercent from "./../../../components/widgets/common/CardPercent";

import { ComisionesVendedoresService } from "../../../services/moduloComisionesService";
import DetalleVentasVendedorModal from "../../../components/widgets/comisiones/DetalleVentasVendedorModal";
const paths = routeConfig.comisionesVendedores;

export class PerformanceVendedoresMensual extends Component {
  constructor(props) {
    super(props);

    var date = new Date();
    date.setDate(1);

    this.state = {
      columns: [],
      equiposDeVenta: [],
      solicitudesAprobadas: [],
      detalles: [],

      fecha: date,
      idEquipoDeVenta: null,
      cumpleObjetivo: null,
      idVendedor: null,

      dialogOpen: false,
      solicitudSelected: null,
      totales: null,

      vendedor: '',
      detalleVentas: [],
      detalleDescuentos: [],
      solicitudesADescontar:[],
    };

    this.handleClose = this.handleClose.bind(this);

    this.handleExcelClick = this.handleExcelClick.bind(this);
    this.closeModalDetalleCallBack = this.closeModalDetalleCallBack.bind(this);

    
  }

  async componentDidMount() {
    this.getSolicitudesAprobadas();
  }

  disableWeekends(date) {
    return date.getDate() !== 1;
  }

  async getSolicitudesAprobadas() {
    var data = {
      fecha: jsDateToLocalISO8601DateString(this.state.fecha),
      idEquipoDeVenta: this.state.idEquipoDeVenta,
      cumpleObjetivo: this.state.cumpleObjetivo,
      idVendedor: this.state.idVendedor,
    };

    const response = await axiosInstance
      .post(
        `/api/PerformanceVendedores/CalcularPerformanceVendedoresMensual`,
        data
      )
      .then((response) => {
        this.setState({
          solicitudesAprobadas: response.data.performances,
          totales: response.data.totales,
        });
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            solicitudesAprobadas: [],
            totales: null,
          });

          store.dispatch({
            type: "SNACKBAR_SUCCESS",
            message: error.message,
            snackBarType: "error",
          });
        }
      });
  }

  onChangeDate = (newValue) => {
    this.setState(
      {
        fecha: newValue,
      },
      () => {
        this.getSolicitudesAprobadas();
      }
    );
  };

  async handleDetallesClick(solicitud) {
    var data = {
      fecha: this.state.fecha,
      idVendedor: solicitud.idVendedor,
      periodo: 2,
    };

    // const detalles = await axiosInstance.post(
    //   `/api/ComisionesVendedores/GetDetalleVentasPorVendedor`,
    //   data
    // );

    // this.setState({
    //   detalles: detalles.data.solicitudesAprobadas,
    //   dialogOpen: true,
    //   solicitudSelected: solicitud,
    // });

    const detalleResponse =
    await ComisionesVendedoresService.getDetalleVentasPorVendedor(data);

  this.setState({
    //detalles: detalles.data,
    dialogOpen: true,
    vendedor: solicitud.vendedor,
    detalleVentas: detalleResponse.solicitudesAprobadas,
    detalleDescuentos: detalleResponse.operacionesADescontar,
    solicitudesADescontar: detalleResponse.solicitudesADescontar,
  });
  }

  
  closeModalDetalleCallBack(){
    this.setState({
        dialogOpen: false,
    })
  }

  onSelectEquipoVentaChange = (e) => {
    this.setState(
      {
        idEquipoDeVenta: e.target.value === 0 ? null : e.target.value,
      },
      () => {
        this.getSolicitudesAprobadas();
      }
    );
  };

  onSelectCumpleObjetivoChange = (e) => {
    this.setState(
      {
        cumpleObjetivo: e.target.value === 0 ? null : e.target.value,
      },
      () => {
        this.getSolicitudesAprobadas();
      }
    );
  };

  onSelectVendedorChange = (e) => {
    this.setState(
      {
        idVendedor: e.target.value === 0 ? null : e.target.value,
      },
      () => {
        this.getSolicitudesAprobadas();
      }
    );
  };

  handleClose() {
    this.setState({
      dialogOpen: false,
    });
  }

  handleExcelClick(e) {
    var params = "fecha=" + formatDate(this.state.fecha);

    if (this.state.idEquipoDeVenta !== null) {
      params += "&IdEquipoVenta=" + this.state.idEquipoDeVenta;
    }

    if (this.state.idVendedor !== null) {
      params += "&idVendedor=" + this.state.idVendedor;
    }

    if (this.state.cumpleObjetivo !== null) {
      params += "&cumpleObjetivo=" + this.state.cumpleObjetivo;
    }

    params += "&periodo=" + 2;

    axiosInstance({
      method: "GET",
      url: "/api/PerformanceVendedores/GenerateExcel?" + params,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "performanceMensual.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5" className="mt-0">
              Performance vendedores mensual
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <ExcelButton
              variant="text"
              size="small"
              startIcon={<AiFillFileExcel />}
              onClick={this.handleExcelClick}
              type="button"
              style={{ float: "right" }}
            >
              Exportar
            </ExcelButton>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item>
                <LocalizationProvider
                  locale={esLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    variant="outlined"
                    orientation="portrait"
                    label="Mes"
                    openTo="day"
                    value={this.state.fecha}
                    shouldDisableDate={this.disableWeekends}
                    onChange={(newValue) => this.onChangeDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <EquipoVentaSelect
                  onSelectChange={this.onSelectEquipoVentaChange}
                />
              </Grid>
              <Grid item>
                <CumplioObjetivoSelect
                  onSelectChange={this.onSelectCumpleObjetivoChange}
                />
              </Grid>
              <Grid item>
                <VendedorSelect onSelectChange={this.onSelectVendedorChange} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <br />

        <Paper className="p-10">
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {this.state.totales != null ? (
                <Grid container spacing={3}>
                  <Grid item>
                    <DashboardCard
                      title="Solicitudes aprobadas"
                      icon={<Assignment />}
                      value={this.state.totales.cantidadDeSolicitudes}
                      valueType="text"
                      color={red[500]}
                      trend={0}
                      showTrend={false}
                    />
                  </Grid>
                  <Grid item>
                    <DashboardCard
                      title="Clientes nuevos"
                      icon={<Person />}
                      value={this.state.totales.clientesNuevos}
                      valueType="text"
                      color={blue[500]}
                      trend={0}
                      showTrend={false}
                    />
                  </Grid>

                  <Grid item>
                    <DashboardCard
                      title="Total prestamo"
                      icon={<AttachMoney />}
                      value={this.state.totales.ventasNetas}
                      valueType="currency"
                      color={green[500]}
                      trend={0}
                      showTrend={false}
                    />
                  </Grid>
                  <Grid item>
                    <DashboardCardPercent
                      title="Performance"
                      icon={<Percent />}
                      value={this.state.totales.performance.toFixed(2)}
                      valueType="text"
                      color={green[500]}
                      trend={0}
                      showTrend={false}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <br />
              <br />
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Cumple <br />
                      objetivo
                    </TableCell>
                    <TableCell colSpan={2}>Performance</TableCell>
                    <TableCell>Equipo</TableCell>
                    <TableCell>Vendedor</TableCell>
                    <TableCell align="center">Clientes nuevos</TableCell>

                    <TableCell align="right">Objetivo</TableCell>

                    <TableCell align="right">Prestamo</TableCell>

                    <TableCell align="right"></TableCell>

                    <TableCell align="right">Dif a vender</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.solicitudesAprobadas.map((solicitud, index) => (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {solicitud.cumpleObjetivo ? (
                          <Check style={{ color: "green" }} />
                        ) : (
                          <Cancel size="small" style={{ color: "red" }} />
                        )}
                      </TableCell>

                      <TableCell style={{ width: "100px" }}>
                        <Box sx={{ width: "100%" }}>
                          {solicitud.objetivoPorcentaje >= 100 ? (
                            <LinearProgress
                              variant="determinate"
                              value={100}
                              color="success"
                            />
                          ) : (
                            <LinearProgress
                              variant="determinate"
                              value={solicitud.objetivoPorcentaje}
                            />
                          )}
                        </Box>
                      </TableCell>

                      {solicitud.cumpleObjetivo ? (
                        <TableCell
                          align="right"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            color: "green",
                          }}
                        >
                          {solicitud.objetivoPorcentaje + " %"}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="right"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {solicitud.objetivoPorcentaje + " %"}
                        </TableCell>
                      )}
                      <TableCell className="fs-10" component="th" scope="row">
                        {solicitud.equipoVenta}
                      </TableCell>

                      <TableCell
                        className="fs-10"
                        component="th"
                        scope="row"
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {solicitud.vendedor}
                      </TableCell>

                      <TableCell align="center">
                        {solicitud.cantidadSolicitudesDeClientesNuevos === 0
                          ? ""
                          : solicitud.cantidadSolicitudesDeClientesNuevos}
                      </TableCell>

                      <TableCell align="right">
                        <CurrencyFormat
                          value={solicitud.objetivo}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      </TableCell>

                      <TableCell align="right">
                        <CurrencyFormat
                          value={solicitud.montoTotal}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        <Tooltip title="Ver detalles">
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleDetallesClick(solicitud)}
                            style={{ padding: "0px" }}
                          >
                            <FindInPage />
                          </IconButton>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="right">
                        {solicitud.diferenciaAVender < 0 ? (
                          <CurrencyFormat
                            style={{ color: "#E4392F" }}
                            value={solicitud.diferenciaAVender}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                          />
                        ) : (
                          <CurrencyFormat
                            value={solicitud.diferenciaAVender}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {this.state.totales !== null ? (
                  <TableRow>
                    <TableCell colSpan={5}></TableCell>
                    <TableCell align="center">
                      <b>{this.state.totales.clientesNuevos}</b>
                    </TableCell>

                    <TableCell align="right">
                      <b>
                        <CurrencyFormat
                          value={this.state.totales.objetivo}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      </b>
                    </TableCell>

                    <TableCell align="right">
                      <b>
                        <CurrencyFormat
                          value={this.state.totales.ventasNetas}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      </b>
                    </TableCell>
                    <TableCell align="right"></TableCell>

                    <TableCell align="center" colSpan={2}></TableCell>
                  </TableRow>
                ) : null}
              </Table>
            </Grid>
          </Grid>
        </Paper>

        <DetalleVentasVendedorModal
          open={this.state.dialogOpen}
          closeModalCallBack={this.closeModalDetalleCallBack}
          detalleVentas={this.state.detalleVentas}
          detalleDescuentos={this.state.detalleDescuentos}
          solicitudesADescontar={this.state.solicitudesADescontar}
          vendedor={this.state.vendedor}
          fecha={this.state.fecha}
        />
      </div>
    );
  }
}
