import {
  LocalShipping,
  ThumbUp,
  Search,
  Forward,
  Check,
} from "@mui/icons-material/";


  import {  Avatar } from '@mui/material'

export const CalificacionCrediticiaIcon = () => {
  return <ThumbUp style={{ color: "#2263ce" }} />;
};

export const LogisticaIcon = () => {
  return <LocalShipping style={{ color: "#818182" }} />;
};

export const ControlIcon = () => {
  return <Search style={{ color: "#1b1e21" }} />;
};

export const EntregadaIcon = () => {
  return <Forward style={{ color: "#155724" }} />;
};

export const AprobadaIcon = () => {
  return <Check style={{ color: "#155724" }} />;
};

export const getAvatarIconByEtapa = (idEtapa) => {
  switch (idEtapa) {
    case 1:
      return (
        <Avatar alt="Control" style={{backgroundColor:'#d6d8d9'}}>
          <Search style={{ color: "#1b1e21" }} />
        </Avatar>
      );
    case 2:
      return (
        <Avatar alt="Calificación crediticia" style={{backgroundColor:'#cce5ff'}}>
          <ThumbUp style={{ color: "#2263ce" }} />
        </Avatar>
      );
    case 3:
      return (
        <Avatar alt="Logística" style={{backgroundColor:'#fefefe', border:'1px solid #ddd'}}>
          <LocalShipping style={{ color: "#818182" }} />
        </Avatar>
      );
    case 4:
      return (
        <Avatar alt="Entregada" style={{backgroundColor:'#fff'}}>
          <Forward style={{ color: "#155724" }} />
        </Avatar>
      );
  }
};
