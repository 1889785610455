import React from 'react'
import { Stack } from "@mui/material";
import { InfoWindowF } from "@react-google-maps/api";
import ButtonVerGoogleMaps from "./ButtonVerGoogleMaps";

export default function InfoWindowVerificacionAmbiental(props) {
  var marker = props.marker;

  return (
    <InfoWindowF
    onCloseClick={() => props.setActiveMarker(null)}
    position={marker.position}
  >
    <div style={{ padding: "1rem 0.5rem 0.5rem 0.5rem" }}>
      <Stack style={{ width: "200px" }}>
        <p>
          <b>Cliente: </b> {marker.cliente}
        </p>
        <p>
          <b>Rubro: </b> {marker.rubro}
        </p>
        <p>
          <b>Producto: </b> {marker.producto}
        </p>      

        <p style={{ width: "190px" }}>{marker.direccion}</p>

        <ButtonVerGoogleMaps marker={marker} />
    
      </Stack>
    </div>
  </InfoWindowF>
  )
}
