import { Checkbox, Grid } from "@mui/material";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";

export class DateRangePickerFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: this.props.range == "today" ? moment() : moment().startOf("month"),
      end: this.props.range == "today" ? moment() :  moment().endOf("month"),
      //range: this.props.range == undefined ? "month" : this.props.range,
    };
  }

  onChange = async (e) => {
    this.setState({
      cumplioObjetivo: e.target.value,
    });

    this.props.onSelectChange(e);
  };

  handleCallback = (start, end) => {
    this.setState({
      start: start,
      end: end,
    });

    this.props.onDateRangePickerChange(start, end);
  };

  async componentDidMount() {
 

   
   
  }

  render() {
    var start = this.state.start;
    var end = this.state.end;

    var label = start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY");

    return (
      <Grid container alignItems={"center"}>

      { this.props.withCheckBox !== undefined && this.props.withCheckBox ?

        <Grid item>
          <Checkbox name="filtraPorFecha" onChange={this.props.onCheckedChange} defaultChecked={this.props.defaultCheckbox}/>
        </Grid>

        :

        null
        
        }
        <Grid item>
      <DateRangePicker
        disable
        initialSettings={{
          locale: {
            label:"asd",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Desde",
            toLabel: "Hasta",
            customRangeLabel: "Custom",
            weekLabel: "W",
            daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Deciembre",
            ],
            firstDay: 1,
          },

          startDate: start.toDate(),
          endDate: end.toDate(),
          ranges: {
            Hoy: [moment().toDate(), moment().toDate()],
            Ayer: [moment().subtract(1, "days"), moment().subtract(1, "days")],
            "Semana actual": [moment().startOf("week"), moment().endOf("week")],
            "Semana anterior": [
              moment().startOf("week").subtract(7, "days"),
              moment().endOf("week").subtract(7, "days"),
            ],
            "Mes actual": [moment().startOf("month"), moment().endOf("month")],
            "Mes anterior": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        }}
        onCallback={this.handleCallback}
      >
        <div
          id="reportrange"
          className="col-4"
          style={{
            // background: '#fff',
            cursor: "pointer",
            padding: "0px 10px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            width: "100%",
            //borderRadius:'8px',

            fontSize: "1rem",
            marginTop: "6px",
            hover: {
              background: "red",
            },
          }}
        >
          <i className="fa fa-calendar"></i>&nbsp;
          <span>{label}</span> <i className="fa fa-caret-down"></i>
        </div>
      </DateRangePicker>
      </Grid>
      </Grid>
    );
  }
}
