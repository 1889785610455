import React, { useState, useEffect } from "react";

import {
  Grid,
  Button,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Divider,
} from "@mui/material/";

import SaveButton from "./../../common/buttons/SaveButton";

import CancelIcon from "@mui/icons-material/Cancel";
import { Edit, CalendarMonth, LockClock, WhatsApp } from "@mui/icons-material/";

import {
  ValidatorForm,
  SelectValidator,
} from "react-material-ui-form-validator";

import store from "../../../store";
import DatePicker from "@mui/lab/DatePicker";
import { axiosInstance } from "../../../services/api/axiosInstance";
import { formatDate } from "../../../helpers/dateTimeHelper";
import CustomCard from "./../../common/widgets/CustomCard";

import { LogisticaAPI as API } from "../../../apis/logisticaAPI";
import { formatCurrency } from "../../../helpers/currencyPercent-helper";

export default function LogisticaFormMin(props) {
  const [isEdit, setIsEdit] = useState(false);

  const [solicitudForm, setSolicitudForm] = useState({
    idFletero: props.solicitud.idFletero,
    fecha: props.solicitud.fechaEstimadaDeEntrega,
    rangoHorarioDeEntrega: props.solicitud.rangoHorarioDeEntrega,
    idProveedor: props.solicitud.idProveedor,
  });

  const [proveedores, setProveedores] = useState([]);
  const [fleteros, setFleteros] = useState([]);

  useEffect(() => {
    getProveedores();
    getFleteros();
  }, []);

  const getProveedores = async () => {
    const proveedoresResponse = await axiosInstance.get(
      `/api/EtapaLogistica/GetProveedoresForSelect`
    );
    setProveedores(proveedoresResponse.data);
  };

  const getFleteros = async () => {
    const fleterosResponse = await axiosInstance.get(
      `/api/EtapaLogistica/GetFleterosForSelect`
    );

    setFleteros(fleterosResponse.data);
  };

  const renderProveedorOptions = () => {
    return proveedores.map((dt, i) => {
      return (
        <MenuItem value={dt.id} key={i} name={dt.text}>
          {dt.text}
        </MenuItem>
      );
    });
  };

  const renderFleteroOptions = () => {
    return fleteros.map((dt, i) => {
      return (
        <MenuItem value={dt.id} key={i} name={dt.text}>
          {dt.text}
        </MenuItem>
      );
    });
  };

  const onSubmit = async () => {
    var data = {
      idFletero: solicitudForm.idFletero,
      fechaEstimadaDeEntrega: solicitudForm.fecha,
      rangoHorarioDeEntrega: solicitudForm.rangoHorarioDeEntrega,
      idProveedor: solicitudForm.idProveedor,
    };

    var response = await API.updateSolicitud(props.solicitud.idSolicitud, data);

    if (response !== undefined && response.status === 200) {
      store.dispatch({
        type: "SNACKBAR_SUCCESS",
        message: "Operación exitosa",
        snackBarType: "success",
      });

      props.onSubmitCallback();
      setIsEdit(false);
    }
  };

  const onChange = (event, value) => {
    setSolicitudForm({
      ...solicitudForm, // Copy the old fields
      [event.target.name]: event.target.value, // But override this one
    });
  };

  const onChangeDate = (newValue) => {
    setSolicitudForm({
      ...solicitudForm,
      fecha: newValue,
    });
  };

  const DataForm = (props) => {
    return (
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="subtitle">
            <b>Vendedor:</b> {props.solicitud.vendedor} 
          </Typography>

          <br />
          <Typography variant="subtitle">
            <b>Venta bruta:</b> {formatCurrency(props.solicitud.ventaBruta)}
          </Typography>

          <br />
          <Typography variant="subtitle">
            <b>Plan:</b> {props.solicitud.numeroCuotas} cuotas de{" "}
            {formatCurrency(props.solicitud.montoCuota)}
          </Typography>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={6}>
          <CustomCard title={props.solicitud.fletero} subTitle="Fletero">
            <b>
              {" "}
              <CalendarMonth />
              {formatDate(props.solicitud.fechaEstimadaDeEntrega)}
            </b>
            <br />
            <Typography variant="caption">Fecha estimada de entrega</Typography>
            <br />

            <div style={{ marginTop: "16px" }}>
              <b>
                <LockClock /> {props.solicitud.rangoHorarioDeEntrega}
              </b>{" "}
              <br />
              <Typography variant="caption">
                Rango horario de entrega
              </Typography>
            </div>
          </CustomCard>
        </Grid>

        <Grid item lg={4} md={6} sm={6} xs={6}>
          <CustomCard title={props.solicitud.proveedor} subTitle="Proveedor">
            <b>{props.solicitud.proveedor}</b>
            <br />
            <Typography variant="caption">Proveedor</Typography>
            <br />
            <br />


        
          </CustomCard>
        </Grid>

        <Grid item xs={12}>
        <Typography variant="subtitle">
            <b>Producto:</b> {props.solicitud.productoGenerico	} 
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid
        container
        alignContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6" inline>
            Solicitud
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setIsEdit(!isEdit)}>
            <Edit />
          </IconButton>
        </Grid>
      </Grid>

      <br />

      <ValidatorForm
        onSubmit={onSubmit}
        onError={(errors) => console.log(errors)}
      >
        {isEdit ? (
          <Grid container spacing={2}>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <SelectValidator
                style={{ width: "100%" }}
                name="idFletero"
                variant="outlined"
                size="medium"
                label="Fletero"
                value={solicitudForm.idFletero}
                validators={["required"]}
                errorMessages={["El fletero es requerido"]}
                onChange={onChange}
              >
                {renderFleteroOptions()}
              </SelectValidator>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12}>
              <DatePicker
                name="date"
                label="Fecha estimada de entrega"
                className="w-100"
                value={solicitudForm.fecha}
                // disablePast={true}
                onChange={(newValue) => onChangeDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    className="w-100"
                    required
                    variant="outlined"
                    {...params}
                  />
                )}
                inputFormat="DD/MM/YYYY"
              />
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12}>
              <SelectValidator
                style={{ width: "100%" }}
                name="rangoHorarioDeEntrega"
                variant="outlined"
                size="medium"
                label="Rango horario de entrega"
                value={solicitudForm.rangoHorarioDeEntrega}
                validators={["required"]}
                errorMessages={["El rango horario de entrega es requerido"]}
                onChange={onChange}
              >
                <MenuItem
                  value={"09:00 - 13:00"}
                  key={1}
                  name={"09:00 - 13:00"}
                >
                  {"09:00 - 13:00"}
                </MenuItem>
                <MenuItem
                  value={"13:00 - 16:00"}
                  key={2}
                  name={"13:00 - 16:00"}
                >
                  {"13:00 - 16:00"}
                </MenuItem>
                <MenuItem
                  value={"16:00 - 18:00"}
                  key={2}
                  name={"16:00 - 18:00"}
                >
                  {"16:00 a 18:00"}
                </MenuItem>
              </SelectValidator>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider />
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12}>
              <SelectValidator
                style={{ width: "100%" }}
                name="idProveedor"
                variant="outlined"
                size="medium"
                label="Proveedor"
                value={solicitudForm.idProveedor}
                validators={["required"]}
                errorMessages={["El proveedor es requerido"]}
                onChange={onChange}
              >
                {renderProveedorOptions()}
              </SelectValidator>
            </Grid>

            <br />

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={2}
              >
                <Grid item>
                  <SaveButton />
                </Grid>
                <Grid item>
                  <Button
                    className="ml-10"
                    variant="text"
                    color="primary"
                    size="medium"
                    startIcon={<CancelIcon />}
                    onClick={() => setIsEdit(false)}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <br />
          </Grid>
        ) : (
          <>
            {props.solicitud !== null ? (
              <DataForm solicitud={props.solicitud} />
            ) : null}
          </>
        )}
      </ValidatorForm>
    </>
  );
}
