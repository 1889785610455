import React, { Component } from "react";

import { Grid } from "@mui/material/";

import { FaThumbsUp, FaThumbsDown, FaMinus } from "react-icons/fa";

export class StatusOpinionCobrador extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    var icon = <FaThumbsUp style={{ fontSize: "1rem", color: "#FFF" }} />;
    var color = "#368551";

    if (this.props.calificacion == null) {
      icon = <FaMinus style={{ fontSize: "1rem", color: "#FFF" }} />;
      color = "#8899a5 ";
    } else if (this.props.calificacion === false) {
      var icon = <FaThumbsUp style={{ fontSize: "1rem", color: "#FFF" }} />;
      var color = "#E4392F";
    } else {
      var icon = <FaThumbsUp style={{ fontSize: "1rem", color: "#FFF" }} />;
      var color = "#368551";
    }

    return (
      <Grid
        container
        style={{
          border: "1px solid #368551",
          borderColor: color,
          borderRadius: "50px",
          paddingRight: "15px",
        }}
        alignItems="center"
      >
        <Grid
          item
          style={{
            backgroundColor: color,
            borderRadius: "50px",
            padding: "7px",
          }}
        >
          {icon}
        </Grid>
        <Grid item style={{ marginLeft: "8px" }}>
          <p
            style={{
              fontWeight: "500",
              fontSize: "0.85rem",
              color: "#444",
              margin: "0px",
            }}
          >
            {this.props.titulo}
          </p>
        </Grid>
      </Grid>
    );
  }
}
