import React, { useState } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LoadingButton from "./../../../components/common/buttons/LoadingButton";

import {
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Send from "@mui/icons-material/Send";
import { LogisticaAPI as API } from "../../../apis/logisticaAPI";
import store from "../../../store";

export default function GenerarOperacionForm(props) {
  const [submiting, setIsSubmiting] = useState(false);
  const [productoNoEntregado, setProductoNoEntregado] = useState(false);

  const onSubmit = async () => {
    var data = {
      productoNoEntregado: productoNoEntregado,
    };

    var response = await API.generarOperacion(props.idSolicitud, data);

    if (response !== undefined && response.status == "200") {
      store.dispatch({
        type: "SNACKBAR_SUCCESS",
        message: "Operación exitosa",
        snackBarType: "success",
      });

      props.generarOperacionCallBack();
    }
  };

  const handleOnChange = () => {
    setProductoNoEntregado(!productoNoEntregado);
  };

  return (
    <ValidatorForm
      onSubmit={onSubmit}
      onError={(errors) => console.log(errors)}
      style={{ padding: "10px" }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox checked={productoNoEntregado} onChange={handleOnChange} />
          }
          label="Producto no entregado"
        />
      </FormGroup>

      <br />
      <Grid container direction="row" justifyContent="flex-end" spacing={2}>
        <Grid item>
          <LoadingButton
            color="success"
            style={{ width: "100px" }}
            icon={<Send />}
            isLoading={submiting}
            text="Generar operación"
            loadingText="Generando"
          />
        </Grid>
        <Grid item>
          <Button
            className="ml-10"
            variant="text"
            color="primary"
            size="medium"
            startIcon={<CancelIcon />}
            onClick={() => props.handleCustomModalCallBack()}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}
