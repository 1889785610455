import React, { useEffect, useState } from "react";
import { axiosInstance } from "services/api/axiosInstance";

import {
  Cancel,
  Edit,
  PictureAsPdf,
  Print,
  Send,
  WhatsApp,
} from "@mui/icons-material/";
import ArrowBack from "@mui/icons-material/ArrowBack";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  Box,
  Tabs,
  Tab,
} from "@mui/material/";
import ClienteLogistica from "components/customer/clienteLogistica";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { withStyles } from "@mui/styles";
import { FaHandsHelping } from "react-icons/fa";

import { ChipCalificacion } from "components/solicitud/chipCalificacion";
import { ChipEtapa } from "components/solicitud/chipEtapa";

import { OpinionCobrador } from "components/solicitud/opinionCobrador";
import SolicitudObservaciones from "components/solicitud/solicitudObservaciones";

import SolicitudStatus from "components/solicitud/SolicitudStatus";

import { CustomModal } from "components/customModal";
import { VerificacionTelefonica } from "components/solicitud/verificacionTelefonica";

import { LogisticaCMVForm } from "components/solicitud/logistica/LogisticaCMVForm";
import LogisticaFormMin from "components/solicitud/logistica/LogisticaFormMin";

import { EnviarAControlCrediticioModal } from "components/solicitud/logistica/enviarAControlCrediticioModal";
import GenerarOperacionForm from "components/solicitud/logistica/GenerarOperacionForm";

import CancelarSolicitudModal from "components/solicitud/logistica/CancelarSolicitudModal";

import VerificacionesAmbientalesCarpeta from "components/customer/verificacionesAmbientalesCarpeta";

import "moment/locale/es";
import { compose } from "recompose";

import { LogisticaAPI as API } from "apis/logisticaAPI";
import { formatCurrency } from "helpers/currencyPercent-helper";

import GalleryImage from "components/multimedia/GalleryImage";

import { showDialog } from "helpers/swal-helper";

import LoadWrapper from "components/common/LoadWrapper";
import BackButton from "components/common/buttons/BackButton";
import CotizacionCarpeta from "components/cotizacion/cotizacionCarpeta";
import PaperEdit from "components/common/PaperEdit";

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "370px",
    },

    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },

    [theme.breakpoints.up("md")]: {
      width: "100%",
    },

    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    width: "50%",
  },
});

function CarpetaLogistica(props) {
  const [isGeneratingPagare, setIsGeneratingPagare] = useState(false);
  const [isGeneratingSolicitud, setIsGeneratingSolicitud] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [carpeta, setCarpeta] = useState(null);

  const [solicitud, setSolicitud] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedIdSolicitudProducto, setSelectedIdSolicitudProducto] =
    useState("");
  const [verificacionesAmbientales, setVerificacionesAmbientales] = useState(
    []
  );
  const [solicitudObservaciones, setSolicitudObservaciones] = useState([]);
  const [
    enviarAControlCrediticioModalOpen,
    setEnviarAControlCrediticioModalOpen,
  ] = useState(false);
  const [cancelarSolicitudModalOpen, setCancelarSolicitudModalOpen] =
    useState(false);
  const [generarOperacionModalOpen, setGenerarOperacionModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { id, view } = useParams();
  const open2 = Boolean(anchorEl);
  const [valueTab, setValueTab] = React.useState(0);

  useEffect(() => {
    if (id) {
      getCarpetaSolicitud();
    }
  }, []);

  const generatePagare = async () => {
    setIsGeneratingPagare(true);

    return await axiosInstance({
      method: "GET",
      url: `/api/EtapaLogistica/GenerarPagare/${id}`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `pagare-${id}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      setIsGeneratingPagare(false);
    });
  };

  const generateSolicitud = async () => {
    setIsGeneratingSolicitud(true);

    return await axiosInstance({
      method: "GET",
      url: `/api/EtapaLogistica/GenerarSolicitud/${id}`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `solicitud-${id}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      setIsGeneratingSolicitud(false);
    });
  };

  const getCarpetaSolicitud = async () => {
    setLoading(true);

    const response = await API.getCarpetaSolicitud(id);

    if (response != undefined && response.status == 200) {
      setError(false);
      setCarpeta(response.data);
    } else {
      setError(true);
    }

    setLoading(false);
  };

  const handleCustomModalCallBack = () => {
    setOpen(false);
  };

  const handleUpdateCMVSubmitCallback = () => {
    setOpen(false);
    getCarpetaSolicitud();
  };

  const handleClickOpenModal = (selectedProducto) => {
    setSelectedIdSolicitudProducto(selectedProducto);
    setOpen(true);
  };

  const handleClickEnviarAControlCrediticioModalOpen = () => {
    setEnviarAControlCrediticioModalOpen(true);
  };

  const closeEnviarAControlCrediticioModal = () => {
    setEnviarAControlCrediticioModalOpen(false);
  };

  const handleClickGenerarOperacionModalOpen = () => {
    setGenerarOperacionModalOpen(true);
  };

  const closeGenerarOperacionModal = () => {
    setGenerarOperacionModalOpen(false);
  };

  const handleClickCancelarSolicitudModalOpen = () => {
    setCancelarSolicitudModalOpen(true);
  };

  const handleSubmitCancelarSolicitudModal = () => {
    setCancelarSolicitudModalOpen(false);
    getCarpetaSolicitud();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmitLogisticaFormCallback = () => {
    getCarpetaSolicitud();
  };

  const enviarAControlCrediticioSubmitCallBack = () => {
    getCarpetaSolicitud();
  };

  const generarOperacionCallBack = () => {
    setGenerarOperacionModalOpen(false);
    getCarpetaSolicitud();
  };

  const handleMarcarComoEntregada = async () => {
    const result = await showDialog(
      "Advertencia",
      "Confirma operacion",
      "Si, marcar como entregada"
    );

    if (result.isConfirmed) {
      const isOK = await API.marcarComoEntregada(id);

      if (isOK) {
        getCarpetaSolicitud();
      }
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <>
      <LoadWrapper loading={loading} error={error}>
        {carpeta && (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    Solicitud #{id} -{" "}
                    {carpeta.cliente.datosPersonales.apellidoNombre}
                  </Typography>
                </Grid>

                <Grid item>
                  <BackButton to="/logistica" />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Paper className="paper">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <SolicitudStatus solicitud={carpeta.solicitud} />
                        <br />

                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          alignItems="center"
                        >
                          <Grid item>
                            <Button
                              color="success"
                              //style={{backgroundColor:"#fff3cd", color: "#856404"}}
                              variant="contained"
                              size="small"
                              disabled={!carpeta.solicitud.puedeOperarLogistica}
                              startIcon={<Send />}
                              onClick={() =>
                                handleClickGenerarOperacionModalOpen()
                              }
                            >
                              Generar operación
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              startIcon={<FaHandsHelping />}
                              size="small"
                              color="secondary"
                              variant="contained"
                              disabled={!carpeta.solicitud.puedeOperarLogistica}
                              onClick={() =>
                                handleClickEnviarAControlCrediticioModalOpen()
                              }
                            >
                              Enviar a calificación crediticia
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              startIcon={<Cancel />}
                              size="small"
                              color="primary"
                              variant="contained"
                              disabled={!carpeta.solicitud.puedeOperarLogistica}
                              onClick={() =>
                                handleClickCancelarSolicitudModalOpen()
                              }
                            >
                              Cancelar solicitud
                            </Button>
                          </Grid>

                          {carpeta.solicitud.idOperacion !== null &&
                          carpeta.solicitud.idEtapa === 3 ? (
                            <Grid item>
                              <Button
                                startIcon={<Send />}
                                size="small"
                                color="success"
                                variant="contained"
                                onClick={() => handleMarcarComoEntregada()}
                              >
                                Marcar como entregada
                              </Button>
                            </Grid>
                          ) : null}

                          <Grid item>
                            <Tooltip title="Impresiones">
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                color="secondary"
                                aria-controls={
                                  open2 ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open2 ? "true" : undefined}
                              >
                                <Print />
                              </IconButton>
                            </Tooltip>

                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open2}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <MenuItem onClick={generatePagare}>
                                <ListItemIcon>
                                  {isGeneratingPagare ? (
                                    <CircularProgress
                                      color="inherit"
                                      size="1rem"
                                    />
                                  ) : (
                                    <PictureAsPdf fontSize="small" />
                                  )}
                                </ListItemIcon>
                                Generar pagare
                              </MenuItem>

                              <MenuItem onClick={generateSolicitud}>
                                <ListItemIcon>
                                  {isGeneratingSolicitud ? (
                                    <CircularProgress
                                      color="inherit"
                                      size="1rem"
                                    />
                                  ) : (
                                    <PictureAsPdf fontSize="small" />
                                  )}
                                </ListItemIcon>
                                Imprimir solicitud
                              </MenuItem>
                            </Menu>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <ChipEtapa
                              idEtapa={carpeta.solicitud?.idEtapa}
                              label={carpeta.solicitud?.etapa}
                              style={{ marginBottom: "6px" }}
                            />
                          </Grid>
                          <Grid item>
                            <ChipCalificacion
                              idCalificacion={carpeta.solicitud?.idCalificacion}
                              label={carpeta.solicitud?.calificacion}
                              style={{ marginTop: "16px" }}
                            />
                          </Grid>
                          <Grid item>
                            {carpeta.solicitud !== null &&
                            carpeta.solicitud.cancelada ? (
                              <Chip
                                icon={<Cancel style={{ color: "#8b0000" }} />}
                                style={{
                                  color: "#8b0000",
                                  backgroundColor: "#ffc5c5",
                                  fontWeight: "500",
                                }}
                                label="Cancelada"
                              ></Chip>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <br />

              <Tabs
                value={valueTab}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Solicitud" {...a11yProps(0)} />
                <Tab label="Cliente" {...a11yProps(1)} />
                <Tab label="Verificaciones" {...a11yProps(2)} />
                <Tab label="Galería de imagenes" {...a11yProps(3)} />
              </Tabs>

              <CustomTabPanel value={valueTab} index={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={5} lg={4}>
                    <SolicitudObservaciones
                      idSolicitud={id}
                      data={carpeta.observaciones}
                      idEtapa={3}
                      handleSubmitCallBack={getCarpetaSolicitud}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7} lg={8}>
                    <PaperEdit
                      title="Cotización asociada"
                      isEdit={false}
                      canEdit={false}
                    >
                      {carpeta.cotizacion !== null ? (
                        <CotizacionCarpeta cotizacion={carpeta.cotizacion} />
                      ) : (
                        <p>Sin cotización asociada</p>
                      )}
                    </PaperEdit>

                    <br />
                    <Paper className="paper">
                      <LogisticaFormMin
                        solicitud={carpeta.solicitudLogistica}
                        onSubmitCallback={onSubmitLogisticaFormCallback}
                      />

                      <br />

                      <Divider />
                      <br />

                      <Typography variant="title">Productos</Typography>

                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Venta bruta</TableCell>
                            <TableCell align="right">Costo</TableCell>
                            <TableCell align="right">CMV</TableCell>
                            <TableCell align="center">Opciones</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {carpeta.solicitudLogistica.productos.map(
                            (producto, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {producto.producto}
                                </TableCell>
                                <TableCell align="right">
                                  {formatCurrency(producto.monto)}
                                </TableCell>
                                <TableCell align="right">
                                  {formatCurrency(producto.montoCosto)}
                                </TableCell>
                                <TableCell align="right">
                                  {formatCurrency(
                                    producto.costoMercaderiaVendida
                                  )}
                                </TableCell>

                                <TableCell align="center">
                                  <Tooltip title="Editar datos del producto">
                                    <IconButton
                                      aria-label="Editar"
                                      onClick={() =>
                                        handleClickOpenModal(producto)
                                      }
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>

                                <TableCell>
                                  <Tooltip title="Enviar WhatsApp al proveedor">
                                    <a
                                      style={{ color: "#25d366" }}
                                      target="_blank"
                                      href={producto.mensajeAProveedor}
                                    >
                                      <IconButton color="whatsApp">
                                        <WhatsApp />
                                      </IconButton>
                                    </a>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                </Grid>
              </CustomTabPanel>

              <CustomTabPanel value={valueTab} index={1}>
                <ClienteLogistica cliente={carpeta.clienteLogistica} />
              </CustomTabPanel>

              <CustomTabPanel value={valueTab} index={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <OpinionCobrador data={carpeta.solicitud.opinionCobrador} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <VerificacionTelefonica
                      verificacion={carpeta.solicitud.verificacionTelefonica}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <VerificacionesAmbientalesCarpeta
                      verificacionesAmbientales={
                        carpeta.verificacionesAmbientales
                      }
                    />
                  </Grid>
                </Grid>
              </CustomTabPanel>

              <CustomTabPanel value={valueTab} index={3}>
                <GalleryImage idCliente={carpeta.cliente.idCliente} />
              </CustomTabPanel>
            </Grid>
          </Grid>
        )}
      </LoadWrapper>

      <CustomModal
        title="Logística"
        open={open}
        handleCustomModalCallBack={() => handleCustomModalCallBack()}
      >
        <LogisticaCMVForm
          handleCustomModalCallBack={() => handleCustomModalCallBack()}
          producto={selectedIdSolicitudProducto}
          submitCallBack={() => handleUpdateCMVSubmitCallback()}
        />
      </CustomModal>

      <EnviarAControlCrediticioModal
        open={enviarAControlCrediticioModalOpen}
        idSolicitud={id}
        closeModalCallBack={() => closeEnviarAControlCrediticioModal()}
        submitCallBack={() => enviarAControlCrediticioSubmitCallBack()}
      />

      <CustomModal
        title="Generar operación"
        open={generarOperacionModalOpen}
        handleCustomModalCallBack={closeGenerarOperacionModal}
      >
        <GenerarOperacionForm
          idSolicitud={id}
          generarOperacionCallBack={generarOperacionCallBack}
          handleCustomModalCallBack={closeGenerarOperacionModal}
        />
      </CustomModal>

      <CancelarSolicitudModal
        open={cancelarSolicitudModalOpen}
        idSolicitud={id}
        submitModalCallBack={() => handleSubmitCancelarSolicitudModal()}
      />
    </>
  );
}

export default compose(withStyles(styles))(CarpetaLogistica);
