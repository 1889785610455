import axios from "axios";

const TOKEN_KEY = "GW_TOKEN_ADMIN";

export function setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
}

export async function getToken() {
    return localStorage.getItem(TOKEN_KEY);
}

export function deleteToken() {
    //e.preventDefault();
    localStorage.removeItem(TOKEN_KEY);
    //window.location = "/";
}

export async function getCurrentUser() {
    if (!getToken()) return false;
    try {


        axios.get('/api/auth/GetUserProfile'
            , { headers: { "Authorization": `Bearer ${getToken()}` } })
            .then(response => {
                return true;
                
            })
            .catch(() => {
                return false;
            });

    } catch (error) {
        return false;
    }
}


export async function isUserAuth() {
    if (!getToken()) return false;
    try {


        axios.get('/api/auth/IsUserAuthenticated'
            , { headers: { "Authorization": `Bearer ${getToken()}` } })
            .then(response => {
                return true;

            })
            .catch(() => {
                return false;
            });

    } catch (error) {
        return false;
    }
}

export async function initAxiosInterceptors() {

    const token = await getToken();


    axios.interceptors.request.use(
        config => {

            if (token !== null) {
                //config.headers.Authorization = `Bearer ${token}`;
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            else {
                config.headers["Authorization"] = `Bearer 1`;
			}

   


        return config;
    });

    //axios.interceptors.response.use(
    //    response => response,
    //    error => {
    //        if (error.response.status === 401) {
    //            //deleteToken();
    //            window.location = "/tu-hermana";
    //        } else {
    //            return Promise.reject(error);
    //        }
    //    }
    //);
}