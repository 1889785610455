import React, { Component  } from 'react';
import { Chip }  from '@mui/material/';
import { LocalShipping, ThumbUp } from "@mui/icons-material/";

import { ControlIcon, CalificacionCrediticiaIcon, LogisticaIcon, EntregadaIcon } from "./../../components/common/Icons";

 export class ChipEtapa extends Component {
    constructor(props) {

        super(props);

        this.state = {
         
        };    
    }

    render(){
        var idEtapa = this.props.idEtapa;
        var etapa = this.props.etapa;


        var color ="";
        var backgroundColor = "";
        var border = "";
        var icon = null;
        var iconColor = '';

        if(idEtapa !== null && etapa !== null){

        
            switch(idEtapa){
                case 1:                        
                    color = '#1b1e21';
                    backgroundColor ='#d6d8d9';
                    icon = <ControlIcon />
                    etapa = "Control"
                    break;
                   
              

                case 2:                        
                    color = '#2263ce';
                    backgroundColor ='#cce5ff';
                    icon = <CalificacionCrediticiaIcon />
                    break;

                case 3:                        
                    color = '#818182';
                    backgroundColor ='#fefefe';
                    border ='1px solid #aaa';
                    icon = <LogisticaIcon />
                    break;

                case 4:                        
                    color = '#155724';
                    backgroundColor ='#d4edda';
                    icon=<EntregadaIcon />
                    break;
                
                default:                        
                    color = '#333';
                    backgroundColor ='#FFF';
                    break;
             

            }

            return (
                <Chip 
                icon={icon}
                style={{ padding:'6px', color: color, backgroundColor: backgroundColor, border: border }} 
                label={this.props.label}
                size={this.props.size !== undefined ? this.props.size : "medium"}>      
                </Chip>                            
            )
        }
        else{
            return(<p>-</p>);
        }
            
    }

 }

