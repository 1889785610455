import React, { Component } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import store from "../store";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export class CustomAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertIsOpen: false,
      snackBarType: "success",
      message: "",
    };

    store.subscribe(() => {
      this.setState({
        alertIsOpen: true,
        message: store.getState().message,
        snackBarType: store.getState().snackBarType,
      });
    });
  }

  handleCloseAlert = (e) => {
    this.setState({ alertIsOpen: false });
  };

  handleClose = (e) => {
    this.setState({ alertIsOpen: false });
  };

  render() {
    var severity = "success";

    if (this.state.snackBarType === "error") severity = "error";

    return (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.alertIsOpen}
          autoHideDuration={2500}
          onClose={this.handleCloseAlert}
          message={this.state.message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={this.handleCloseAlert}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <Alert severity={severity}>{this.state.message}</Alert>
        </Snackbar>
    );
  }
}
