import React, { useState, useEffect } from "react";

import { axiosInstance } from "../services/api/axiosInstance";

import { styled, useTheme } from "@mui/material/styles";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExitToApp from "@mui/icons-material/ExitToApp";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import WbSunnyIcon from "@mui/icons-material/WbSunny";
import Dashboard from "@mui/icons-material/Dashboard";
import Chat from "@mui/icons-material/Chat";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import NightlightIcon from "@mui/icons-material/Nightlight";

import { CustomAlert } from "../components/customAlert";

import { Link } from "react-router-dom";
import UserContext from "../userContext";

import { Switch, Typography } from "@mui/material";

import Logo from "../assets/images/logoRenoavado3.png";
//import LogoCrediario from "../assets/images/logo-crediario.svg";
import Cart from "./Cart";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "1px",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  const theme = useTheme();
  const user = React.useContext(UserContext);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false); 
  
  const [menu, setMenu] = React.useState(user ? user.menu  : [])
  // useEffect(() => {
  //   const getMenu = async () => {
  //     await axiosInstance
  //       .get("/api/Menu")
  //       .then((response) => {
  //         setMenu(response.data);
  //       })
  //       .catch(() => {
  //         setMenu([]);
  //       });
  //   };

  //   getMenu();
  // }, [props.login]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen2(!open2);
  };

  const handleMenuClick = (option) => {
    const newState = menu.map((obj) => {
      if (obj.id === option.id) {
        return { ...obj, open: !option.open };
      }
      return obj;
    });

    setMenu(newState);
  };

  const handleSubMenuClick = (text, option) => {
    const itemsCopy = [...menu];

    const index = itemsCopy.findIndex((item) => item.id === text.id);

    const subMenuCopy = { ...itemsCopy[index] };

    const subMenuIndex = subMenuCopy.items.findIndex(
      (item) => item.id === option.id
    );

    const tercerLevel = { ...subMenuCopy.items[subMenuIndex] };

    tercerLevel.open = !option.open;

    subMenuCopy.items[subMenuIndex] = tercerLevel;

    setMenu(itemsCopy);
  };

  return (
    <Box style={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed" open={open} elevation={1}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            style={{ marginRight: 16 }}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          {user.roles !== null &&
          (user.roles.includes("Administrador") ||
            user.roles.includes("Dashboard")) ? (
            <IconButton
              color="inherit"
              component={Link}
              to="/dashboard"
              style={{ marginRight: 16 }}
              edge="start"
              sx={{
                marginRight: 5,
                marginLeft: 0,
              }}
            >
              <Dashboard />
            </IconButton>
          ) : null}

          {/* <IconButton
            color="inherit"
            component={Link}
            to="/chat"
            style={{ marginRight: 16 }}
            edge="start"
            sx={{
              marginRight: 5,
              marginLeft: 0,
            }}
          >
            <Chat />
          </IconButton> */}

          {user.roles.includes("Administrador") ||
          user.roles.includes("Agenda") ? (
            <IconButton
              color="inherit"
              component={Link}
              to="/agenda"
              style={{ marginRight: 16 }}
              edge="start"
              sx={{
                marginRight: 5,
                marginLeft: 0,
              }}
            >
              <CalendarMonth />
            </IconButton>
          ) : null}

          <Switch
            checked={props.darkMode}
            onChange={() => props.setDarkMode(!props.darkMode)}
            style={{ marginRight: 16 }}
          />

          <Typography variant="h5">
            {props.darkMode ? <WbSunnyIcon /> : <NightlightIcon />}
          </Typography>
          <section style={{ marginLeft: "auto", marginRight: -12 }}>
            <Stack direction="row">
              {/* <Cart  sx={{ flexGrow: 1 }}  /> */}

              <IconButton
                sx={{ flexGrow: 1 }}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={props.logoutClickCallback}
              >
                <ExitToApp />
              </IconButton>
            </Stack>
          </section>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={{ backgroundColor: "#FFF" }}>
          <img src={Logo} alt="Grupo West" width="75%" />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon
                style={{ color: props.darkMode ? "#fff" : "#d1d5db" }}
              />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider />

        <List>
          {menu !== undefined ? (
            <>
              {" "}
              {menu.map((menuLevel1, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                    color: props.darkMode ? "#fff" : "#d1d5db",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 40,
                      justifyContent: menuLevel1.open ? "initial" : "center",
                      color: menuLevel1.open ? "#ec5990" : "#fff",
                      p: 1,
                      px: 2.5,
                    }}
                    onClick={() => handleMenuClick(menuLevel1)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: menuLevel1.open ? "#ec5990" : "#fff",
                      }}
                    >
                      <span
                        className="material-icons"
                        style={{ fontSize: "1.5rem" }}
                      >
                        {menuLevel1.icon}
                      </span>
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <Typography
                          type="body2"
                          sx={{
                            color: menuLevel1.open ? "#ec5990" : "#fff",
                            fontWeight: menuLevel1.open ? "900" : "400",
                            fontSize: "0.9rem",
                          }}
                        >
                          {menuLevel1.label}
                        </Typography>
                      }
                      sx={{
                        opacity: open ? 1 : 0,
                        fontWeight: "800",
                      }}
                    />

                    {open == false ? null : menuLevel1.open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>

                  <Collapse in={menuLevel1.open} timeout="auto" unmountOnExit>
                    {/* segundo nivel */}
                    <List component="div" disablePadding>
                      {menuLevel1.items.map((menuLevel2, index) => (
                        <div>
                          {(menuLevel2.items !== null &&
                            menuLevel2.items.length) > 0 ? (
                            <div>
                              <ListItemButton
                                sx={{
                                  minHeight: 48,
                                  justifyContent: open ? "initial" : "center",
                                  p: 1,
                                  px: 2.5,
                                  color: menuLevel2.open ? "#ec5990" : "#fff",
                                }}
                                onClick={() =>
                                  handleSubMenuClick(menuLevel1, menuLevel2)
                                }
                              >
                                <ListItemIcon
                                  sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                    // color: props.darkMode ? "#fff" : "#d1d5db",
                                    color: menuLevel2.open ? "#ec5990" : "#fff",
                                  }}
                                >
                                  <span className="material-icons">
                                    {menuLevel2.icon}
                                  </span>
                                </ListItemIcon>

                                <ListItemText
                                  primary={
                                    <Typography
                                      type="body2"
                                      sx={{
                                        color: menuLevel2.open
                                          ? "#ec5990"
                                          : "#fff",
                                        fontWeight: menuLevel2.open
                                          ? "900"
                                          : "400",
                                        fontSize: "0.85rem",
                                      }}
                                    >
                                      {menuLevel2.label}
                                    </Typography>
                                  }
                                  sx={{
                                    opacity: open ? 1 : 0,
                                    fontWeight: "800",
                                  }}
                                />

                                {open == false ? null : menuLevel2.open ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItemButton>

                              <Collapse
                                in={menuLevel2.open}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {menuLevel2.items.map((item2, index) => (
                                    <ListItemButton
                                      component={Link}
                                      to={item2.to}
                                      key={index}
                                      sx={{
                                        minHeight: 35,
                                        justifyContent: open
                                          ? "initial"
                                          : "center",
                                        px: 2.5,
                                        ml: 0.85,
                                      }}
                                    >
                                      <ListItemIcon
                                        sx={{
                                          minWidth: 0,
                                          mr: open ? 3 : "auto",
                                          justifyContent: "center",
                                          color: props.darkMode
                                            ? "#fff"
                                            : "#d1d5db",
                                        }}
                                      >
                                        <span className="material-icons">
                                          {item2.icon}
                                        </span>
                                      </ListItemIcon>

                                      <ListItemText
                                        primary={
                                          <Typography
                                            type="body2"
                                            sx={{ fontSize: "0.9rem" }}
                                          >
                                            {item2.label}
                                          </Typography>
                                        }
                                        sx={{ opacity: open ? 1 : 0 }}
                                      />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </div>
                          ) : (
                            <ListItemButton
                              component={Link}
                              to={menuLevel2.to}
                              key={index}
                              sx={{
                                minHeight: 35,
                                justifyContent: open ? "initial" : "center",
                                p: 1,
                                px: 2.5,
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : "auto",
                                  justifyContent: "center",
                                  color: props.darkMode ? "#fff" : "#d1d5db",
                                }}
                              >
                                <span
                                  style={{ fontSize: "1.25rem" }}
                                  className="material-icons"
                                >
                                  {menuLevel2.icon}
                                </span>
                              </ListItemIcon>

                              <ListItemText
                                primary={
                                  <Typography
                                    type="body2"
                                    sx={{ fontSize: "0.85rem" }}
                                  >
                                    {menuLevel2.label}
                                  </Typography>
                                }
                                sx={{ opacity: open ? 1 : 0 }}
                              />
                            </ListItemButton>
                          )}
                        </div>
                      ))}
                    </List>
                  </Collapse>
                </ListItem>
              ))}
            </>
          ) : null}
        </List>
        <Divider />
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props.children}
      </Box>

      <CustomAlert />
    </Box>
  );
}
