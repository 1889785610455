import React from "react";
import { Grid, Paper, Typography } from "@mui/material/";
import Avatar from "@mui/material/Avatar";
import TrendingDown from "@mui/icons-material/TrendingDown";
import TrendingUp from "@mui/icons-material/TrendingUp";
import { formatCurrency } from "../../helpers/currencyPercent-helper";


export default function DashboardCard(props) {
  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: "0.75rem",
      }}
    >
      <div className="p-20">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <span className="dashboard-title">{props.title}</span>

            <Typography variant="h5">
              { props.valueType == "text" ? <span>{props.value}</span> : <span>{formatCurrency(props.value)}</span> }
              </Typography>

            {props.showTrend ? (
              <div>
                {props.trend > 0 ? (
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                      <TrendingUp style={{ color: "green" }} />
                    </Grid>
                    <Grid item>
                      <p className="footer-text">
                        <span style={{ color: "green" }}>
                          {props.trend.toFixed(1)}%
                        </span>{" "}
                        más que ayer
                      </p>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                      <TrendingDown style={{ color: "red" }} />
                    </Grid>
                    <Grid item>
                      <p className="footer-text">
                        <span style={{ color: "red" }}>
                          {props.trend.toFixed(1)}%
                        </span>{" "}
                        menos que ayer.
                      </p>
                    </Grid>
                  </Grid>
                )}
              </div>
            ) : (
              <div>
                <br /> <br />
              </div>
            )}
          </Grid>
          <Grid item>
            <Avatar sx={{ bgcolor: props.color }}>{props.icon}</Avatar>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
