import React from "react";
import {
  Paper,
  Typography,
  Divider,
  Grid,
  ListItem,
  ListItemText,
} from "@mui/material/";
import { formatCurrency } from "../../../helpers/currencyPercent-helper";

export default function SaldosDisponibilidades(props) {
  const resumen = props.data;

  return (
    <Paper
      style={{
        borderRadius: "0.75rem",
        backgroundColor: "corn",
      }}
    >
      <div className="p-10">
        <Typography variant="h6">Total de saldos</Typography>
      </div>

      <Divider />
      {resumen == null ? null : (
        <Grid container dense={true}>
          <Grid item>
            <ListItem>
              <ListItemText
                primary={<>{formatCurrency(resumen.saldoCaja)}</>}
                secondary="Caja"
              />
            </ListItem>
          </Grid>

          <Grid item>
            <ListItem>
              <ListItemText
                primary={<>{formatCurrency(resumen.saldoBancos)}</>}
                secondary="Bancos"
              />
            </ListItem>
          </Grid>
          <Grid item>
            <ListItem>
              <ListItemText
                primary={
                  <>{formatCurrency(resumen.saldoBilleterasVirtuales)}</>
                }
                secondary="Billeteras virtuales"
              />
            </ListItem>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
