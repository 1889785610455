// src/apis/pageAPI.js

import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const LogisticaAPI = {

  getCarpetaSolicitud: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/getCarpetaSolicitud/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getCarpetaSolicitud.name].handleRequestCancellation().signal
        : undefined,
    });

    // returning the page returned by the API
    return response;
  },

  getEntregasPendientes: async function (cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/getEntregasPendientes/`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getEntregasPendientes.name].handleRequestCancellation().signal
        : undefined,
    });

    // returning the page returned by the API
    return response;
  },



  getSolicitudObservaciones: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/GetSolicitudObservaciones/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getSolicitudObservaciones.name].handleRequestCancellation().signal
        : undefined,
    });

    // returning the page returned by the API
    return response.data;
  },

  getUltimasVerificacionesAmbientales: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/GetUltimasVerificacionesAmbientales/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getUltimasVerificacionesAmbientales.name].handleRequestCancellation().signal
        : undefined,
    });

    // returning the page returned by the API
    return response.data;
  },

  cancelarSolicitud: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/CancelarSolicitud/${id}`,
      method: "PUT",
      data: data,
      signal: cancel
        ? cancelApiObject[this.cancelarSolicitud.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },

  marcarComoEntregada: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/MarcarComoEntregada/${id}`,
      method: "PUT",
      data: data,
      signal: cancel
        ? cancelApiObject[this.marcarComoEntregada.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },  

  updateSolicitud: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/UpdateSolicitud/${id}`,
      method: "PUT",
      data: data,
      signal: cancel
        ? cancelApiObject[this.updateSolicitud.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },

  updateCostoMercaderiaVendida: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/UpdateCostoMercaderiaVendida/${id}`,
      method: "PUT",
      data: data,
      signal: cancel
        ? cancelApiObject[this.updateCostoMercaderiaVendida.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },

  enviarAControlCrediticio: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/EnviarAControlCrediticio/${id}`,
      method: "PUT",
      data: data,
      signal: cancel
        ? cancelApiObject[this.enviarAControlCrediticio.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },

  generarOperacion: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/GenerarOperacion/${id}`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.generarOperacion.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },

  guardarObservacion: async function (id, data, cancel = false) {
    const response = await api.request({
      url: `/api/Solicitud/GuardarObservacion/${id}`,
      method: "POST",
      data: data,
      signal: cancel
        ? cancelApiObject[this.guardarObservacion.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },

  marcarSolicitudComoContactada: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/EtapaLogistica/MarcarSolicitudComoContactada/${id}`,
      method: "PUT",
      //data: data,
      signal: cancel
        ? cancelApiObject[this.marcarSolicitudComoContactada.name].handleRequestCancellation().signal
        : undefined,
    });

    return response;
  },
  
  getPaginated: async function ({ page, perPage, sortColumn, sortDirection, query, filters }, cancel = false  ) {

    var params = {
      page: page,
      per_page: perPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      query: query,        
    };

    Object.assign(params, filters);

    const response = await api.request({
      url: "/api/EtapaLogistica/",
      method: "GET",
      params: params,      
      signal: cancel
        ? cancelApiObject[this.getPaginated.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response;
  },

  getTotals: async function ({ query, filters }, cancel = false  ) {

    var params = {
      query: query,        
    };

    Object.assign(params, filters);

    const response = await api.request({
      url: "/api/EtapaLogistica/GetTotales",
      method: "GET",
      params: params,      
      signal: cancel
        ? cancelApiObject[this.getTotals.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response;
  },

  generateExcel: async function ( { sortColumn, sortDirection, query, filters }, cancel = false) {

    var params = {
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      query: query,        
    };

    Object.assign(params, filters);


    const response = await api.request({
      url: "/api/EtapaLogistica/GenerateExcel",
      method: "GET",
      params: params,      
      responseType: "blob",
      signal: cancel
        ? cancelApiObject[this.generateExcel.name].handleRequestCancellation().signal
        : undefined,
    });

       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", "logistica.xlsx");
       document.body.appendChild(link);
       link.click();

       return response;
  },



  generatePdf: async function ( { sortColumn, sortDirection, query, filters }, cancel = false) {

    var params = {
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      query: query,        
    };

    Object.assign(params, filters);


    const response = await api.request({
      url: "/api/EtapaLogistica/GeneratePdf",
      method: "GET",
      params: params,      
      responseType: "blob",
      signal: cancel
        ? cancelApiObject[this.generatePdf.name].handleRequestCancellation().signal
        : undefined,
    });

       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", "logistica.pdf");
       document.body.appendChild(link);
       link.click();

       return response;

  },


};

// defining the cancel API object for pageAPI
const cancelApiObject = defineCancelApiObject(LogisticaAPI);
