import React, { useState, useEffect } from "react";

import {
  Grid,
  Button,
  Paper,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material/";

import { ExpandMore } from "@mui/icons-material/";
import CustomDataTableV2 from "../../components/data-table/CustomDataTableV2";
import { formatDate } from "../../helpers/dateTimeHelper";
import { DateRangePickerFilter } from "../../components/formComponents/dateRangePickerFilter";
import { FaFilter } from "react-icons/fa";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { Typography } from "@mui/material";
import BalanceSector from "./balanceSector";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import useFilterData from "./../../customHooks/useFilterData";
import Autocomplete from "@mui/material/Autocomplete";
import { CajaConsolidadaAPI as API } from "../../apis/cajaConsolidadaAPI";
import { showValidationDialog } from "../../helpers/swal-helper";

const columns = [
  {
    id: "ID",
    name: "id",
    selector: (row) => row.id,
    sortable: false,
    hide: "sm",
  },

  {
    id: "IdMovimientoCajaDetalle",
    name: "idMovimientoCajaDetalle",
    selector: (row) => row.idMovimientoCajaDetalle,
    sortable: false,
    hide: "sm",
  },

  {
    id: "Caja",
    name: "Caja",
    selector: (row) => row.caja,
    sortable: false,
    hide: "sm",
    cell: (row) => <b>{row.caja}</b>,
  },

  {
    id: "fecha",
    name: "Fecha",
    selector: (row) => row.fecha,
    sortable: false,

    width: "120px",
    cell: (row) => <span>{moment(row.fecha).format("DD/MM/YYYY")}</span>,
  },

  {
    id: "centroDeCosto",
    name: "centro de costo",
    selector: (row) => row.centroDeCosto,
    sortable: false,
    hide: "sm",
  },

  {
    id: "conceptoGeneral",
    name: "Concepto general",
    selector: (row) => row.conceptoGeneral,
    sortable: false,
    hide: "sm",
  },

  {
    id: "conceptoParticular",
    name: "Concepto particular",
    selector: (row) => row.conceptoParticular,
    sortable: false,
    hide: "sm",
  },

  {
    id: "concepto",
    name: "Detalle",
    selector: (row) => row.concepto,
    sortable: false,
    cell: (row) => <p style={{ marginTop: "10px" }}>{row.concepto}</p>,
  },

  {
    id: "haber",
    name: "Egreso",
    selector: (row) => row.haber,
    sortable: false,
    hide: "sm",
    width: "110px",
    cell: (row) => (
      <CurrencyFormat
        value={row.haber}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"$"}
      />
    ),
  },

  {
    id: "debe",
    name: "Ingreso",
    selector: (row) => row.debe,
    sortable: false,
    hide: "sm",
    width: "110px",
    cell: (row) => (
      <CurrencyFormat
        value={row.debe}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"$"}
      />
    ),
  },

  {
    id: "saldo",
    name: "saldo",
    selector: (row) => row.saldo,
    sortable: false,
    hide: "sm",
    width: "110px",
    cell: (row) => (
      <CurrencyFormat
        value={row.saldo}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"$"}
      />
    ),
  },
];

const dataTableRef = React.createRef();

export function CentroDeCostosList() {
  const initialState = {
    filtraPorFecha: false,
    fechaDesde: formatDate(moment().startOf("month")),
    fechaHasta: formatDate(moment().endOf("month")),

    saldoHistorico: false,

    idCuentaDisponibilidad: 22,

    idConceptoParticular: 0,
  };

  const { filters, updateFilter } = useFilterData(
    initialState,
    "cajaConsolidada"
  );
  const [balance, setBalance] = useState([]);
  const [cuentas, setCuentas] = useState([]);
  const [disponibilidades, setDisponibilidades] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    var response = await API.getBalanceDisponibilidades();

    if (response !== undefined && response.status == 200) {
      setBalance(response.data);
    }

    var responseDisponibilidadesForSelect =
      await API.getDisponibilidadesForSelect();

    if (
      responseDisponibilidadesForSelect !== undefined &&
      responseDisponibilidadesForSelect.status == 200
    ) {
      setDisponibilidades(responseDisponibilidadesForSelect.data);
    }

    var responseCuentasAutocomplete = await API.getCuentasForAutocomplete();

    if (
      responseCuentasAutocomplete !== undefined &&
      responseCuentasAutocomplete.status == 200
    ) {
      setCuentas(responseCuentasAutocomplete.data);
    }
  };

  const handleFiltrarClick = (e) => {
   
      dataTableRef.current.refresh();
    

  };


  const onDateRangePickerChange = (start, end) => {
    updateFilter("fechaDesde", formatDate(start));
    updateFilter("fechaHasta", formatDate(end));
  };


  const onChange = (event) => {
    const { name, value } = event.target;
    updateFilter(name, value);
  };

  const renderDisponibilidades = () => {
    return disponibilidades.map((dt, i) => {
      return (
        <MenuItem value={dt.id} key={i} name={dt.text}>
          {dt.text}
        </MenuItem>
      );
    });
  };

  const onChangeAutocomplete = () => (event, value) => {
    if (value !== null) {
      updateFilter("idConceptoParticular", value.id);
    } else {
      updateFilter("idConceptoParticular", 0);
    }
  };

  const onCheckedChange = async (event) => {
    const { name, checked } = event.target;
    updateFilter(name, checked);
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Typography variant="h5" className="mt-0">
            Caja consolidada
          </Typography>
        </Grid>

      
      </Grid>

      <br />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Saldos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BalanceSector
            titulo=""
            balance={balance.filter((b) => b.codigo.startsWith("1."))}
            mostrarCodigoCuenta={false}
          />
        </AccordionDetails>
      </Accordion>

      <br />

      <Paper className="p-20">
        <Grid container>
          <Grid item xs={6} sm={10} md={10} lg={11}>
            <Grid
              container
              spacing={4}
              alignItems="center"
              alignContent={"center"}
            >
              <Grid item>
                <DateRangePickerFilter
                  onDateRangePickerChange={onDateRangePickerChange}
                  range="month"
                  defaultCheckbox={filters.filtraPorFecha}
                  withCheckBox={true}
                  onCheckedChange={onCheckedChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: "200px" }}
                  name="idCuentaDisponibilidad"
                  variant="standard"
                  size="small"
                  select
                  label="Disponibilidades (Cajas)"
                  value={filters.idCuentaDisponibilidad}
                  onChange={onChange}
                >
                  <MenuItem value={0}>Todos</MenuItem>

                  {renderDisponibilidades()}
                </TextField>
              </Grid>

              <Grid item>
                <Autocomplete
                  defaultValue={""}
                  //ref={this.autocompleteRef}
                  groupBy={(option) => option.groupBy}
                  style={{ width: "100%", minWidth: "300px" }}
                  disablePortal
                  id="idCuenta"
                  name="idCuenta"
                  size="medium"
                  options={cuentas}
                  required
                  onChange={onChangeAutocomplete()}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      variant="standard"
                      {...params}
                      label="Concepto particular"
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="saldoHistorico"
                      checked={filters.saldoHistorico}
                      onChange={onCheckedChange}
                    />
                  }
                  label="Contemplar saldo histórico"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} sm={2} md={2} lg={1}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<FaFilter />}
              style={{ float: "right", width: "100%" }}
              onClick={handleFiltrarClick}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>

        {/* <div className="p-10">
            <Typography variant="h5">
              Saldo{" "}
              <CurrencyFormat
                value={this.state.saldo}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </Typography>
          </div> */}
        <CustomDataTableV2
          getPaginated={API.getPaginated}
          generateExcel={API.generateExcel}
          generatePdf={API.generatePdf}
          //getTotals={API.getTotals}
          //totalCallBack={updateTotals}
          columns={columns}
          //conditionalRowStyles={conditionalRowStyles}
          ref={dataTableRef}
          showHeader={true}
          elevation={0}
          filters={filters}
          showExportButton={true}
          expandableRows={false}
          //expandableRowsComponent={<TESTexpandableRowsComponent />}
        />
      </Paper>
    </div>
  );
}
