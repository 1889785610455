import React, { Component } from "react";
import { axiosInstance } from "../../services/api/axiosInstance";
import { Grid, Button, Paper, MenuItem, Typography } from "@mui/material/";
import { RadioButtonChecked } from "@mui/icons-material/";
import CustomDataTable from "../../components/CustomDataTable";
import routeConfig from "./../../constants/routeConfig";
import { ExcelButton } from "../../components/buttons";
import { AiFillFileExcel } from "react-icons/ai";
import { formatDate } from "../../helpers/dateTimeHelper";
import { DateRangePickerFilter } from "../../components/formComponents/dateRangePickerFilter";
import { ZonaSelect } from "../../components/formComponents/zonaSelect";
import { MotivoNoPagoSelect } from "../../components/formComponents/motivoNoPagoSelect";
import { FaFilter } from "react-icons/fa";
import moment from "moment";

const paths = routeConfig.movimientosNoPagos;

export class MovimientoNoPagoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      url: paths.url,

      start: moment().startOf("month"),
      end: moment().endOf("month"),

      roles: [],
      zona: null,
      idMotivoNoPago: null,
    };

    this.dataTableRef = React.createRef();
    this.handleFiltrarClick = this.handleFiltrarClick.bind(this);
    this.handleExcelClick = this.handleExcelClick.bind(this);
  }

  async componentDidMount() {
    const roles = await axiosInstance.get(`/api/auth/GetRoles`);

    this.setState({
      roles: roles.data,
    });

    var columns = [
      {
        id: "IdMovimientoNoPago",
        name: "Id",
        selector: (row) => row.idMovimientoNoPago,
        sortable: true,
        hide: "sm",
        width: "70px",
      },
      {
        id: "fecha",
        name: "Fecha",
        selector: (row) => row.fecha,
        sortable: true,
        width: "110px",
        hide: "sm",
        cell: (row) => <p>{moment(row.fecha).format("DD/MM/YYYY")}</p>,
      },
      {
        id: "zona",
        name: "zona",
        selector: (row) => row.zona,
        sortable: true,
        hide: "sm",
        width: "90px",
      },
      {
        id: "Cliente",
        name: "Cliente",
        selector: (row) => row.cliente,
        sortable: true,
        maxWidth: "250px",
      },

      {
        id: "Motivo",
        name: "Motivo",
        selector: (row) => row.motivoNoPago,
        sortable: true,
        maxWidth: "250px",
        cell: (row) => (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <RadioButtonChecked style={{ color: row.colorMotivoNoPago }} />
            </Grid>
            <Grid item>
              <p>{row.motivoNoPago}</p>
            </Grid>
          </Grid>
        ),
      },
    ];

    if (this.state.roles.includes("Cazador")) {
      columns = columns.filter(function (obj) {
        return (
          obj.id !== "asignarProspectoAVendedor" && obj.id !== "rastrillador"
        );
      });
    }

    this.setState({
      columns: columns,
    });
  }

  buildParamsQuery() {
    var params = "&fechaDesde=" + formatDate(this.state.start);
    params += "&fechaHasta=" + formatDate(this.state.end);

    if (this.state.zona !== null) {
      params += "&zona=" + this.state.zona;
    }

    if (this.state.idMotivoNoPago !== null) {
      params += "&idMotivoNoPago=" + this.state.idMotivoNoPago;
    }

    if (this.dataTableRef.current.state.query !== "") {
      params += "&query=" + this.dataTableRef.current.state.query;
    }

    this.setState({
      params: params,
    });

    return params;
  }

  handleFiltrarClick(e) {
    this.dataTableRef.current.refreshDataTable("&" + this.buildParamsQuery());
  }

  handleExcelClick(e) {
    var params = this.buildParamsQuery();

    axiosInstance({
      method: "GET",
      url: paths.url + "/GenerateExcel?k=1" + params,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "noPagos.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  onFilterChange = (e) => {
    console.log(e.target);
    this.setState(
      {
        [e.target.name]: e.target.value === 0 ? null : e.target.value,
      },
      () => {
        this.buildParamsQuery();
      }
    );
  };

  onDateRangePickerChange = (start, end) => {
    this.setState(
      {
        start: start,
        end: end,
      },
      () => {
        this.buildParamsQuery();
      }
    );
  };

  handleOpen(row) {
    this.setState({
      selectedProspecto: row,
      dialogOpen: true,
    });
  }

  handleClose() {
    this.setState({
      dialogOpen: false,
    });
  }

  onChange = (event, value) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    var title = paths.label;

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={10} md={10} lg={11}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
        </Grid>

        <br />

        {this.state.roles.includes("Administrador") ||
        this.state.roles.includes("NoPagosExcel") ? (
          <ExcelButton
            variant="text"
            size="medium"
            startIcon={<AiFillFileExcel />}
            onClick={this.handleExcelClick}
            type="button"
            className="mt-20"
          >
            Exportar
          </ExcelButton>
        ) : null}

        <Paper className="mt-10 p-10">
          <Grid container>
            <Grid item xs={12} sm={10} md={10} lg={11}>
              <Grid container spacing={4} alignItems="center">
                <Grid item>
                  <DateRangePickerFilter
                    onDateRangePickerChange={this.onDateRangePickerChange}
                  />
                </Grid>
                <Grid item>
                  <ZonaSelect onSelectChange={this.onFilterChange} />
                </Grid>
                <Grid item>
                  <MotivoNoPagoSelect onSelectChange={this.onFilterChange} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2} md={2} lg={1}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                startIcon={<FaFilter />}
                style={{ float: "right", width: "100%" }}
                onClick={this.handleFiltrarClick}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>

          <div className="p-10"></div>
          <CustomDataTable
            url={paths.url}
            params={this.state.params}
            columns={this.state.columns}
            handleOpenModal={this.handleOpenModal}
            ref={this.dataTableRef}
            showHeader={true}
            elevation={0}
          />
        </Paper>
      </div>
    );
  }
}
