import React, { Component } from "react";

import { Grid, Button, MenuItem, TextField } from "@mui/material/";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import store from "../../../store";
import DatePicker from "@mui/lab/DatePicker";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

import CurrencyInputReturnDecimal from "./../../../components/common/inputs/CurrencyInputReturnDecimal"

import { LogisticaAPI as API } from "../../../apis/logisticaAPI";

export class LogisticaCMVForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ventaNetaReal: 0,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = async function () {
    var data = {
        costoMercaderiaVendida: this.state.ventaNetaReal,
    };

    var response = "";

    response = await API.updateCostoMercaderiaVendida(this.props.producto.idSolicitudProducto, data);

    if (response !== undefined && response.status === 200) {


      this.props.submitCallBack();

      store.dispatch({
        type: "SNACKBAR_SUCCESS",
        message: "Operación exitosa",
        snackBarType: "success",
      });

     
    }

    

   
  };

  onChange = (event, value) => {
    if (event.target.type === "checkbox") {
      this.setState({
        [event.target.name]: event.target.checked,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  onChange2 = (event, value) => {
    this.setState({
        [event.target.name]: value
    })    
}

  closeModal() {
    this.props.handleCustomModalCallBack();
  }

  onCostoDeMercaderiaVendidaChange = (newValue) => {
   this.setState({
    ventaNetaReal: newValue
   });
  };

  render() {
    return (
      <ValidatorForm
        onSubmit={this.onSubmit}
        onError={(errors) => console.log(errors)}
      >
        <br />
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CurrencyInputReturnDecimal 
              onCurrencyChange={this.onCostoDeMercaderiaVendidaChange}
              defaultValue={this.props.producto.costoMercaderiaVendida}

              // label="CMV"
              // className="w-100"
              // name="ventaNetaReal"
              // variant="outlined"
              // value={this.state.ventaNetaReal}
              // currencySymbol="$"
              // outputFormat="string"
              // onChange={(event, value) => this.onChange2(event, value)}
              // decimalCharacter="."
              // digitGroupSeparator=","
              // onBlur={(event, value) => this.onBlur(event, value)}
            />
          </Grid>

          <br />

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  autoFocus
                  startIcon={<SaveIcon />}
                >
                  Guardar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className="ml-10"
                  variant="text"
                  color="primary"
                  size="medium"
                  startIcon={<CancelIcon />}
                  onClick={() => this.closeModal()}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <br />
        </Grid>
      </ValidatorForm>
    );
  }
}
