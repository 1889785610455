import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Button,
  TableContainer,
} from "@mui/material/";
import { FindInPage } from "@mui/icons-material";
import { formatDate } from "../../helpers/dateTimeHelper";
import { formatCurrency } from "../../helpers/currencyPercent-helper";
import { CentroDeCostosAPI as API } from "../../apis/centroDeCostosAPI";

import BalanceDetalle from "./BalanceDetalle";

export default function BalanceSector(props) {
  const [mostrarCodigoCuenta, setMostrarCodigoCuenta] = useState(
    props.mostrarCodigoCuenta == undefined ? true : props.mostrarCodigoCuenta
  );

  const [detalles, setDetalles] = useState([]);
  const [open, setOpen] = useState(false);

  const isParentRow = (codigo) => {
    const myArray = codigo.split(".");

    var ultimoNumero = myArray[3];

    const parsed = parseInt(ultimoNumero);

    return parsed == 0;
  };

  const verDetalles = async (idCuenta) => {
    var filters = {
      fechaDesde: formatDate(props.start),
      fechaHasta: formatDate(props.end),
      idCuenta: idCuenta,
    };

    var data = await API.getCentroDeCostosDetalle(filters);

    setDetalles(data);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const styleParentRow = {
    textAlign: "right",
    color: "#444",
    fontWeight: "bold",
  };

  const styleRow = { textAlign: "right", color: "#444", fontSize: ".75rem" };

  const styleRow1001 = {
    textAlign: "right",
    color: "#444",
    fontSize: ".75rem",
    fontWeight: "bold",
  };

  return (
    <>
      <Paper className="p-20">
        <Typography variant="h5">{props.titulo}</Typography>
        <TableContainer>
          <Table aria-label="simple table" size="small" hover>
            <TableHead>
              <TableRow>
                {mostrarCodigoCuenta ? <TableCell>Código</TableCell> : null}
                <TableCell>Cuenta</TableCell>
                <TableCell align="right">Saldo</TableCell>

                {/* {props.mostrarBotonDetalle ? (
              <TableCell align="right"></TableCell>
            ) : null} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.balance.map((row, i) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {mostrarCodigoCuenta ? (
                    <TableCell component="th" scope="row">
                      <span
                        style={
                          isParentRow(row.codigo) ? styleParentRow : styleRow
                        }
                      >
                        {row.codigo}
                      </span>
                    </TableCell>
                  ) : null}

                  <TableCell component="th" scope="row">
                    <span
                      style={
                        isParentRow(row.codigo) ? styleParentRow : styleRow
                      }
                    >
                      {row.name}
                    </span>

                    {row.codigo.trim() === "1.1.1.1" &&
                    props.concepto1001 !== undefined ? (
                      <>
                        <br />
                        <span style={styleRow1001}>
                          (Concepto 1001:{" "}
                          {formatCurrency(
                            props.concepto1001[0].saldoIncludingChildren
                          )}
                          )
                        </span>
                      </>
                    ) : null}
                  </TableCell>

                  <TableCell align="right">

                    { props.mostrarBotonDetalle ?
                    <Button
                      variant="text"
                      color="primary"
                      style={{ textDecoration: "none", color: "#222" }}
                      href="#"
                      onClick={() => verDetalles(row.id)}
                    >
                      <span
                        style={
                          isParentRow(row.codigo) ? styleParentRow : styleRow
                        }
                      >
                        {formatCurrency(row.saldoIncludingChildren)}
                      </span>
                    </Button>
                    :
                    <span
                    style={
                      isParentRow(row.codigo) ? styleParentRow : styleRow
                    }
                  >
                    {formatCurrency(row.saldoIncludingChildren)}
                  </span>

                      }
                  </TableCell>

                  {/* {props.mostrarBotonDetalle ? (
                <TableCell>
                  {row.saldoIncludingChildren > 0 ? (
                    <IconButton
                      size="small"
                      style={{ margin: "0px", padding: "0px" }}
                      onClick={() => verDetalles(row.id)}
                    >
                      <FindInPage />
                    </IconButton>
                  ) : null}
                </TableCell>
              ) : null} */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <BalanceDetalle
        open={open}
        handleModalClose={handleModalClose}
        detalles={detalles}
      />
    </>
  );
}
