import React, { useState, useEffect } from "react";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

export default function CurrencyInputReturnDecimal(props) {
  const [value, setValue] = useState("0");

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  const onChange = async (e) => {
    var importeDecimal = 0;

    if (e.target.value) {
      importeDecimal = parseFloat(
        e.target.value.replace(/\./g, "").replace(",", ".")
      );
    }

    setValue(importeDecimal);

    props.onCurrencyChange(importeDecimal);
  };

  const onBlur = async (e) => {
    var importeDecimal = 0;

    if (e.target.value) {
      importeDecimal = parseFloat(
        e.target.value.replace(/\./g, "").replace(",", ".")
      );
    }

    setValue(importeDecimal);

    props.onCurrencyChange(importeDecimal);
  };

  return (
    <CurrencyTextField
      name="importe"
      variant="outlined"
      value={value}
      currencySymbol={
        props.currencySymbol === undefined ? "$" : props.currencySymbol
      }
      outputFormat="number"
      onChange={onChange}
      decimalCharacter=","
      digitGroupSeparator="."
      minimumValue="0"
      required
      className="w-100"
      disabled={props.disabled === undefined ? false : props.disabled}

      label={props.label === undefined ? "CMV" : props.label}
      onBlur={onBlur}
      size={props.size === undefined ? "medium" : props.size}
    />
  );
}
