import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  IconButton
} from "@mui/material/";
import Typography from "@mui/material/Typography";

import { Add } from "@mui/icons-material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import SaveButton from "../../components/common/buttons/SaveButton";

import { LogisticaAPI as API } from "../../apis/logisticaAPI";
import store from "./../../store";

import { getAvatarIconByEtapa } from "../../components/common/Icons";

export default function SolicitudObservaciones(props) {
  const [timeline, setTimeline] = useState(props.data);
  const [showForm, setShowForm] = useState(false);
  const [observacion, setObservacion] = useState("");

  useEffect(() => {
    setTimeline(props.data);
  }, [props.data]);

  const handleCancelClick = () => {
    setShowForm(false);
    setObservacion("");
  };

  const onSubmit = async () => {
    var data = {
      observacion: observacion,
      idEtapa: props.idEtapa
    };

    var response = await API.guardarObservacion(props.idSolicitud, data);

    if (response !== undefined && response.status === 200) {
      store.dispatch({
        type: "SNACKBAR_SUCCESS",
        message: "Operación exitosa",
        snackBarType: "success",
      });

      setShowForm(false);
      setObservacion("");
      props.handleSubmitCallBack();
    }
  };

  return (
    <Box className="paper">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6">Observaciones</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setShowForm(true)}>
            <Add />
          </IconButton>
        </Grid>
      </Grid>

      {showForm ? (
        <>
          <br />

          <ValidatorForm
            onSubmit={onSubmit}
            onError={(errors) => console.log(errors)}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextValidator
                  id="observacion"
                  style={{
                    width: "100%",
                  }}
                  name="observacion"
                  label="Observación"
                  variant="outlined"
                  onChange={(e) => setObservacion(e.target.value)}
                  multiline
                  rows={6}
                  value={observacion}
                  validators={["required", "maxStringLength:500"]}
                  errorMessages={[
                    "La observación es requerida",
                    "Máximo 500 carácteres",
                  ]}
                  autoComplete="off"
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <SaveButton />
                  </Grid>
                  <Grid item>
                    <Button type="text" onClick={handleCancelClick}>
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ValidatorForm>
          <br />
          <br />
        </>
      ) : null}

      <br />
      <br />

      {timeline.map((item, index) => {
        return (
          <Grid container wrap="nowrap" spacing={2} style={{borderBottom: '1px solid #eee', marginBottom:'20px'}} key={index}>
            <Grid item>{getAvatarIconByEtapa(item.idEtapa)}</Grid>


            <Grid justifyContent="left" item xs zeroMinWidth>
              <h6 style={{  textAlign: "left" }}>{item.etapa} <small style={{ textAlign: "right", color: "gray", fontSize:'0.75rem' }}>
              {item.tiempoTranscurrido} 
                </small></h6>

              <b style={{ color:'#444'}}>{item.motivo}</b>
              <p style={{ textAlign: "left", color:'#666' }}>{item.observacion}</p>
         
            </Grid>
         
          </Grid>

          // <TimelineItem key={index}>
          //   <TimelineOppositeContent
          //     sx={{ flex: "0.2" }}
          //     align="right"
          //     variant="body2"
          //     color="text.secondary"
          //   >
          //     <center style={{ marginTop: "10px" }}>
          //       {formatDate(item.fecha)}
          //       <br />
          //       {getHour(item.fecha)}
          //     </center>
          //   </TimelineOppositeContent>

          //   <TimelineSeparator>
          //     <TimelineConnector />
          //     <TimelineDot
          //       style={{ backgroundColor: item.color }}
          //     ></TimelineDot>
          //     <TimelineConnector />
          //   </TimelineSeparator>

          //   <TimelineContent sx={{ py: "12px", px: 2 }}>
          //     <Typography variant="h8" component="span">
          //       <ChipEtapa idEtapa={item.idEtapa} label={item.etapa} />
          //     </Typography>
          //     <Typography style={{ color: "#666", fontSize: "0.85rem" }}>
          //
          //     </Typography>
          //   </TimelineContent>
          // </TimelineItem>
        );
      })}
    </Box>
  );
}
