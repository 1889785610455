import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import NavigationIcon from "@mui/icons-material/Navigation";

export default function ButtonVerGoogleMaps(props) {
  var marker = props.marker;

  return (
    <Button
      component={Link}
      size="small"
      variant="outlined"
      to={`geo:0,0?q=${marker.position.lat},${marker.position.lng}`}
      startIcon={<NavigationIcon />}
    >
      Ver en Google Maps
    </Button>
  );
}
