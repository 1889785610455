// src/apis/pageAPI.js

import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const CentroDeCostosAPI = {
  getCentroDeCostosDetalle: async function (filters, cancel = false) {
    const response = await api.request({
      url: `/api/CentroDeCostos/GetDetalleCostos/`,
      method: "GET",
      params: filters,
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    // returning the page returned by the API
    return response.data
  }, 

  getCentroDeCostosPorGrupoDetalle: async function (filters, cancel = false) {
    const response = await api.request({
      url: `/api/CentroDeCostos/GetCentroDeCostosPorGrupoDetalle/`,
      method: "GET",
      params: filters,
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    // returning the page returned by the API
    return response.data
  }, 
};

// defining the cancel API object for pageAPI
const cancelApiObject = defineCancelApiObject(CentroDeCostosAPI);
