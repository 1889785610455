import React from "react";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
    width: "100%"
  },
  skeleton: {
    marginBottom: theme.spacing(2),
  },
}));

export default function LoadWrapper(props) {
  const classes = useStyles();

  if (props.loading) {
    return (
      <Stack className={classes.root} spacing={2}>
        <Skeleton variant="rounded" animation="wave" width={500} height={60} />
        <Skeleton variant="rounded" animation="wave" width={500} height={20} />
        <Skeleton variant="rounded" animation="wave" width={500} height={20} />
        <Typography variant="body1" style={{ color: "#999" }}>
          Espere por favor...
        </Typography>
      </Stack>
    );
  } else if (props.error) {
    return (
        <Alert style={{marginTop:'20px'}} severity="error">Ocurrió un error.</Alert>
    );
  } else {
    return <>{ props.children}</>;
  }
}
