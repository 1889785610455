import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material/";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { Cancel } from '@mui/icons-material'

import store from "../../../store";

import { LogisticaAPI as API } from "./../../../apis/logisticaAPI";

import LoadingButton from "./../../../components/common/buttons/LoadingButton";

export default function CancelarSolicitudModal(props) {
  const [open, setOpen] = useState(props.open);
  const [idSolicitud, setIdSolicitud] = useState(props.idSolicitud);
  const [observacion, setObservacion] = useState("");
  const [submiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleRejectedModal = (value) => {
    setOpen(value);
  };

  const onChange = (event, value) => {
    setObservacion(event.target.value);
  };

  const closeSendCreditVerificadorModal = () => {
    setObservacion("");
    props.submitModalCallBack();
    
  };

  const onSubmit = async () => {

    setIsSubmiting(true);

    var data = {
      motivoCancelacion: observacion,
    };

    var response = "";

    response = await API.cancelarSolicitud(idSolicitud, data);

    if (response !== undefined && response.status === 200) {
      store.dispatch({
        type: "SNACKBAR_SUCCESS",
        message: "Operación exitosa",
        snackBarType: "success",
      });

      closeSendCreditVerificadorModal();
    }

    setIsSubmiting(false);

  };

  return (
    <Dialog
      open={open}
      onClose={() => handleRejectedModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-title">Cancelar solicitud</DialogTitle>
      <ValidatorForm
        onSubmit={onSubmit}
        onError={(errors) => console.log(errors)}
      >
        <DialogContent>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextValidator
                id="observacion"
                style={{
                  width: "100%",
                }}
                name="observacion"
                label="Motivo de cancelación"
                variant="outlined"
                onChange={onChange}
                multiline
                rows={4}
                value={observacion}
                validators={["required", "maxStringLength:500"]}
                errorMessages={[
                  "El motivo de cancelación es requerido",
                  "Máximo 500 carácteres",
                ]}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
          style={{width:'100px'}}
            icon={<Cancel />}
            isLoading={submiting}
            text="Cancelar solicitud"
            loadingText="Cancelando solicitud"
          />

          <Button onClick={() => closeSendCreditVerificadorModal()}>
            Cancelar
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}
