import React, { Component } from "react";
import { axiosInstance } from "../../services/api/axiosInstance";
import {
  Grid,
  Button,
  Paper,
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography
} from "@mui/material/";
import { ExcelButton } from "../../components/buttons";
import { AiFillFileExcel } from "react-icons/ai";

import { Link } from "react-router-dom";
import {
  Edit,
  AddOutlined,  
  Brightness1,
  CheckCircle,
} from "@mui/icons-material/";
import  CustomDataTable  from "../../components/CustomDataTable";
import routeConfig from "./../../constants/routeConfig";
import { FaFilter } from "react-icons/fa";
import { DateRangePickerFilter } from "../../components/formComponents/dateRangePickerFilter";

import MultipleSelectCheckmarks from "./../../components/formComponents/MultipleSelectCheckmarks";
import NewButton from "./../../components/common/buttons/NewButton";


import moment from "moment";
import "moment/locale/es";
import { formatDate } from "../../helpers/dateTimeHelper";
import EditIconButton from "../../components/common/buttons/EditIconButton";

const paths = routeConfig.agenda;



const estados = [
  { id: 1, name: "Creado", selected: true },
  { id: 2, name: "En desarrollo", selected: true },
  { id: 3, name: "Resuelto", selected: true },
  { id: 4, name: "En espera de info", selected: false },
  { id: 5, name: "Próximo a publicar", selected: false },
  { id: 6, name: "Anulado", selected: false },
];

const prioridades = [
  { id: 1, name: "Baja", selected: false },
  { id: 2, name: "Media", selected: false },
  { id: 3, name: "Alta", selected: false },
  { id: 4, name: "Urgente", selected: false },
];

export class AgendaList extends Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(window.localStorage.getItem("stateAgendaGrid")) || {
      columns: [],
      estados: estados,
      params: "",
      prioridades: prioridades,

      filtraFechaDeCarga: true,
      filtraFechaDesolucion: false,

      desde: moment().startOf("month"),
      hasta: moment().endOf("month"),

      desdeSolucion: moment().startOf("month"),
      hastaSolucion: moment().endOf("month"),
    };

    this.dataTableRef = React.createRef();
    this.onChangeEstados = this.onChangeEstados.bind(this);
    this.onChangePrioridades = this.onChangePrioridades.bind(this);
    this.handleFiltrarClick = this.handleFiltrarClick.bind(this);
    this.handleExcelClick = this.handleExcelClick.bind(this);

    this.filtraFechaDeCargaHandleChange =
      this.filtraFechaDeCargaHandleChange.bind(this);
    this.filtraFechaDeSolucionHandleChange =
      this.filtraFechaDeSolucionHandleChange.bind(this);
  }

  async buildParamsQuery() {
    var params = "&fechaDesde=" + formatDate(this.state.desde);
    params += "&fechaHasta=" + formatDate(this.state.hasta);

    params += "&fechaSolucionDesde=" + formatDate(this.state.desdeSolucion);
    params += "&fechaSolucionHasta=" + formatDate(this.state.hastaSolucion);

    if (this.state.estados.length > 0) {
      this.state.estados
        .filter((e) => e.selected)
        .map((estado, index) => {
          params += "&estados[" + index + "]=" + estado.id;
        });
    }

    if (this.state.prioridades.length > 0) {
      this.state.prioridades
        .filter((e) => e.selected)
        .map((prioridad, index) => {
          params += "&prioridades[" + index + "]=" + prioridad.id;
        });
    }

    if (this.state.filtraFechaDeCarga) {
      params += "&filtraFechaDeCarga=true";
    }

    if (this.state.filtraFechaDesolucion) {
      params += "&filtraFechaDesolucion=true";
    }

    if (this.dataTableRef.current.state.query !== "") {
      params += "&query=" + this.dataTableRef.current.state.query;
    }

    this.setState({
      params: params,
    });

    return params;
  }

  async handleFiltrarClick(e) {
    var params = await this.buildParamsQuery();
    window.localStorage.setItem("stateAgendaGrid", JSON.stringify(this.state));
    this.dataTableRef.current.refreshDataTable("&" + params);
  }

  onDateRangePickerChange = (start, end) => {
    this.setState(
      {
        desde: start,
        hasta: end,
      },
      () => {
        this.buildParamsQuery();
      }
    );
  };

  onDateRangePickerChangeSolucion = (start, end) => {
    this.setState(
      {
        desdeSolucion: start,
        hastaSolucion: end,
      },
      () => {
        this.buildParamsQuery();
      }
    );
  };

  onChangeEstados(event) {
    console.log("event: ", event.target.value);

    this.setState((prevState) => ({
      estados: prevState.estados.map((es) =>
        1 === 1 ? { ...es, selected: false } : es
      ),
    }));

    event.target.value.forEach((element) =>
      this.setState((prevState) => ({
        estados: prevState.estados.map((es) =>
          es.id === element.id ? { ...es, selected: true } : es
        ),
      }))
    );
  }

  onChangePrioridades(event) {


    this.setState((prevState) => ({
      prioridades: prevState.prioridades.map((es) =>
        1 === 1 ? { ...es, selected: false } : es
      ),
    }));

    event.target.value.forEach((element) =>
      this.setState((prevState) => ({
        prioridades: prevState.prioridades.map((es) =>
          es.id === element.id ? { ...es, selected: true } : es
        ),
      }))
    );
  }

  async componentDidMount() {
    const columns = [
      {
        id: "idActividad",
        name: "Id",
        selector: (row) => row.idActividad,
        sortable: true,
        width: "90px",
        hide: "sm",
      },
      {
        id: "fecha",
        name: "fecha",
        selector: (row) => row.fecha,
        sortable: true,
        width: "120px",
        cell: (row) => <span>{moment(row.fecha).format("DD - MMMM")}</span>,
        hide: "sm",
      },

      {
        id: "prioridad",
        name: "prioridad",
        selector: (row) => row.prioridad,
        sortable: true,
        width: "120px",
        cell: (row) => (
          <Tooltip title={row.prioridad}>
            <Brightness1 style={{ color: row.prioridadColor }} />
          </Tooltip>
        ),
        hide: "sm",
      },

      {
        id: "descripcion",
        name: "Actividad",
        selector: (row) => row.descripcion,
        sortable: true,
        cell: (row) => (
          <div className="p-10">
            <b>{row.titulo}</b>
            <p>{row.descripcion}</p>
          </div>
        ),
      },

      {
        id: "responsable",
        name: "responsable",
        selector: (row) => row.responsable,
        sortable: true,
        width: "150px",
        hide: "sm",
      },

      {
        id: "estado",
        name: "estado",
        selector: (row) => row.estado,
        sortable: true,
        width: "120px",
        hide: "sm",
        cell: (row) =>
          row.estado == "Resuelto" ? (
            <CheckCircle style={{ color: "green" }} />
          ) : (
            <p>{row.estado}</p>
          ),
      },

      {
        name: "",
        button: true,
        cell: (row) => (
          <EditIconButton to={paths.list} id={row.idActividad} />
        ),      
        allowOverflow: true,
        width: "80px",
      },
    ];

    this.setState({
      columns: columns,
    });
  }

  filtraFechaDeCargaHandleChange(e) {
    this.setState({
      filtraFechaDeCarga: !this.state.filtraFechaDeCarga,
    });
  }

  filtraFechaDeSolucionHandleChange(e) {
    this.setState({
      filtraFechaDesolucion: !this.state.filtraFechaDesolucion,
    });
  }

  async handleExcelClick(e) {
    var params = await this.buildParamsQuery();

    axiosInstance({
      method: "GET",
      url: paths.url + "/GenerateExcel?k=1" + params,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "agenda.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  render() {
    var title = paths.label;

    return (
  
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} md={7} lg={9}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={3}>
            <NewButton to="add" />           
          </Grid>

          <Grid item xs={12}>

          <ExcelButton
                variant="text"
                size="medium"
                startIcon={<AiFillFileExcel />}
                onClick={this.handleExcelClick}
                type="button"
                className="mt-20"
              >
                Exportar
              </ExcelButton>

            <Paper className="mt-10 p-20">
              <Grid container>
                <Grid item xs={6} sm={10} md={10} lg={11}>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.filtraFechaDeCarga}
                              onChange={this.filtraFechaDeCargaHandleChange}
                            />
                          }
                          label="Fecha de carga"
                        />
                      </FormGroup>
                      <DateRangePickerFilter
                        onDateRangePickerChange={this.onDateRangePickerChange}
                        range="month"
                      />
                    </Grid>
                    <Grid item>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.filtraFechaDesolucion}
                              onChange={this.filtraFechaDeSolucionHandleChange}
                            />
                          }
                          label="Fecha de solución"
                        />
                      </FormGroup>
                      <DateRangePickerFilter
                        onDateRangePickerChange={
                          this.onDateRangePickerChangeSolucion
                        }
                      />
                    </Grid>
                    <Grid item>
                      <MultipleSelectCheckmarks
                        label="Estados"
                        data={this.state.estados}
                        multipleSelectCheckmarksChange={this.onChangeEstados}
                      />
                    </Grid>
                    <Grid item>
                      <MultipleSelectCheckmarks
                        label="Prioridad"
                        data={this.state.prioridades}
                        multipleSelectCheckmarksChange={
                          this.onChangePrioridades
                        }
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6} sm={2} md={2} lg={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<FaFilter />}
                    style={{ float: "right", width: "100%" }}
                    onClick={this.handleFiltrarClick}
                  >
                    Filtrar
                  </Button>
                </Grid>
              </Grid>

      

              <CustomDataTable
                url={paths.url}
                params={this.state.params}
                columns={this.state.columns}
                handleOpenModal={this.handleOpenModal}
                ref={this.dataTableRef}
                showHeader={true}
                elevation={0}
              />
            </Paper>
          </Grid>
        </Grid>
 
    );
  }
}
