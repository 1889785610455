import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import {
  TextValidator
} from "react-material-ui-form-validator";

import DatePicker from "@mui/lab/DatePicker";

const InteraccionesCliente = forwardRef((props, ref) => {
  
  const [interacciones, setInteracciones] = useState(props.data);

  useImperativeHandle(ref, () => ({
    getInteracciones() {
      return interacciones;
    },
  }));

  useEffect(() => {}, []);

  const addInteraccion = () => {
    setInteracciones((interacciones) => [
      ...interacciones,
      {
        observacion: "",
        fecha: new Date(),
      },
    ]);
  };

  const remove = (index) => {
    let interaccionesCopy = [...interacciones];

    interaccionesCopy.splice(index, 1);

    setInteracciones(interaccionesCopy);
  };

  const onChange = (index, event) => {
    let interaccionesCopy = [...interacciones];

    let interaccion = { ...interaccionesCopy[index] };

    if (event.target.name == "observacion") {
      interaccion.observacion = event.target.value;
    }

    interaccionesCopy[index] = interaccion;

    setInteracciones(interaccionesCopy);
  };

  const onChangeDate = (index, newValue) => {
    var date = new Date(newValue);

    let interaccionesCopy = [...interacciones];

    let interaccion = { ...interaccionesCopy[index] };

    interaccion.fecha = date;

    interaccionesCopy[index] = interaccion;

    setInteracciones(interaccionesCopy);
  };

  return (
    <Paper elevation={0} className="paper mt-20">
      <div className="paper-header">
        <Grid container justifyContent="space-between">
          <Grid item>
            <h5 className="mt-0">Interacciones con el cliente</h5>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<Add />}
              type="button"
              onClick={addInteraccion}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </div>

      <div className="paper-body">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Observación</TableCell>
              <TableCell align="right">Fecha de revisita</TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interacciones.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  <TextValidator
                    name="observacion"
                    className="w-100"
                    size="medium"                    
                    variant="outlined"
                    value={row.observacion}
                    multiline
                    rows={4}
                    onChange={(e) => onChange(index, e)}
                    validators={["required", "maxStringLength:400"]}
                    errorMessages={[
                      "La observación es requerida",
                      "Máximo 400 carácteres",
                    ]}
                  />
                </TableCell>

                <TableCell align="right">
                  <DatePicker
                    name="fecha"                    
                    value={row.fecha}
                    onChange={(newValue) => onChangeDate(index, newValue)}
                    renderInput={(params) => (
                      <TextField required variant="outlined" {...params} />
                    )}
                    inputFormat="DD/MM/YYYY"
                  />
                </TableCell>

                <TableCell align="right">
                  <IconButton color="secondary" onClick={() => remove(index)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
});

export default InteraccionesCliente;
