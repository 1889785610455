import { useState, useEffect } from 'react';
import { formatDate } from "../helpers/dateTimeHelper"

const useFilterData = (initialFilters = {}, currentView) => {
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    localStorage.setItem(currentView, JSON.stringify(filters));
  }, [filters])


  const updateFilter = async (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const updateFilterDates = (key, value, key2, value2) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
      [key2]: value2,
    }));
  };
  
  const clearFilter = async (emptyFilters) => {
    setFilters(emptyFilters);
  };


  const filtersString = () => {

    Object.keys(filters).forEach(key => {

        // console.log("asdas:" , key, filters[key]);

        //console.log("🚀 ~ file: useFilterData .jsx:23 ~ Object.keys ~ key:", key)

      });

    //var params = "&dateFrom=" + formatDate(start);
    // params += "&dateUntil=" + formatDate(end);

    // setParams(params);

    return "adsdsds";
  }

  return { filters, updateFilter, filtersString, updateFilterDates, clearFilter };
};

export default useFilterData;