import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Container,
  Typography,
  Divider,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Tooltip,
} from "@mui/material/";

import { Check } from "@mui/icons-material/";
import LinearProgress from "@mui/material/LinearProgress";
import Skeleton from "@mui/material/Skeleton";

import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { ThemeProvider } from "@mui/system";
import { useTheme } from "@mui/styles";

export default function CobranzaDelDiaStatus(props) {
  const theme = useTheme();

  var hideColum = { display: { xl:'table-cell', lg:'table-cell', md:'table-cell', xs: 'none' }};

  return (
    <div>
      {props.data == null && props.data == undefined ? (
        <Skeleton variant="rounded" height={100} nimation="wave" />
      ) : (
        <Paper
          elevation={0}
          style={{
            borderRadius: "0.75rem",
            padding: "10px",
          }}
        >

        {/* <LinearProgress variant="determinate" value={60} sx={{margin:'20px 20px 30px 20px', height:'12px', borderRadius:'5px'}}/> */}

          <Grid container dense={true}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  
                  <TableCell align="center">
                    Zona
                  </TableCell>

                  <TableCell sx={hideColum} >
                    Horarios
                  </TableCell>

                  <TableCell sx={hideColum} >
                    Clientes
                  </TableCell>

                  <TableCell sx={hideColum} >
                    Operaciones
                  </TableCell>

                  <TableCell  sx={hideColum} >                   
                    Objetivo
                  </TableCell>

                  <TableCell sx={hideColum}>
                    Cobrado
                  </TableCell>

                  <TableCell>%</TableCell>

                  <TableCell></TableCell>

                </TableRow>
              </TableHead>

              <TableBody>
                {props.data.map((zona, index) => {
                  return (
                    <TableRow>
                      <TableCell align="center">
                        <b>{zona.id}</b>
                      </TableCell>

                      <TableCell style={{ color: "#555" }} sx={hideColum}>
                        {moment(zona.primerCarga).format("HH:mm") +
                          " - " +
                          moment(zona.ultimaCarga).format("HH:mm")}
                      </TableCell>

                      <TableCell style={{ color: "#555" }} sx={hideColum}>
                        {zona.clientesCobrados + " / " + zona.cantidadClientes}
                      </TableCell>

                    <TableCell style={{ color: "#555" }} sx={hideColum}                   
                      >
                        {zona.operacionesCobradas +
                          " / " +
                          zona.cantidadOperaciones}
                      </TableCell>

                      <TableCell style={{ color: "#555" }} sx={hideColum}>
                        <CurrencyFormat
                          value={zona.objetivo}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      </TableCell>


                      <TableCell style={{ color: "#555" }} sx={hideColum}>
                        <CurrencyFormat
                          value={zona.cobrado}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      </TableCell>

                      <TableCell>
                        {zona.porcentajeCobrado >= 100 ? (
                          <ThemeProvider theme={theme}>
                            <LinearProgress
                              variant="determinate"
                              value={100}
                              color="success"
                            />
                          </ThemeProvider>
                        ) : (
                          <LinearProgress
                            variant="determinate"
                            value={zona.porcentajeCobrado}
                          />
                        )}
                        <span
                          style={{
                            fontSize: "0.7rem",
                            color:
                              zona.porcentajeCobrado >= 100 ? "green" : "red",
                          }}
                        >
                          {zona.porcentajeCobrado.toFixed(2)} %
                        </span>
                      </TableCell>

                      <TableCell>
                        {zona.confirmado ? (
                          <Check style={{ color: "green" }} />
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
