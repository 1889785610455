import React, { Component } from "react";
import { axiosInstance } from "../../services/api/axiosInstance";
import store from "../../store";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Delete from "@mui/icons-material/Delete";
import Autorenew from "@mui/icons-material/Autorenew";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import { Tooltip } from "@mui/material";
import appConfig from "../../constants/appConfig";

export class CustomImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idTipoDocumento:
        this.props.idTipoDocumento !== undefined
          ? this.props.idTipoDocumento
          : 0,
      saveAction: this.props.saveAction,
      deleteAction: this.props.deleteAction,
      rotateAction: this.props.rotateAction,
      getAction: this.props.getAction,
      imagen: null,
      imagenURL: null,
      imageHash: Date.now(),
    };

    this.deleteImagen = this.deleteImagen.bind(this);
    this.rotateImage = this.rotateImage.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }

  async deleteImagen() {
    await axiosInstance
      .delete(
        "/api/Multimedia/" + this.state.deleteAction + "/" + this.props.idImagen
      )
      .then((response) => {
        if (response.status == "200") {
          store.dispatch({
            type: "SNACKBAR_SUCCESS",
            message: "Operación exitosa",
          });

          this.props.updateDocumentCallBackRemove(this.props.idImagen);
        }
      })
      .catch((error) => {
        if (error.response) {
          store.dispatch({
            type: "SNACKBAR_SUCCESS",
            message: error.response.statusText,
            snackBarType: "error",
          });
        }
      });
  }

  handleClickOpen() {
    this.props.handleClickOpen(this.props.name);
  }

  async rotateImage() {
    await axiosInstance
      .put(
        "/api/Multimedia/" + this.props.rotateAction + "/" + this.props.idImagen
      )
      .then((response) => {
        if (response.status == "200") {
          store.dispatch({
            type: "SNACKBAR_SUCCESS",
            message: "Operación exitosa",
          });

          this.setState({
            imageHash: Date.now(),
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          store.dispatch({
            type: "SNACKBAR_SUCCESS",
            message: error.response.statusText,
            snackBarType: "error",
          });
        }
      });
  }

  render() {
    return (
      <Card style={{ minHeight: "70px" }}>
        <div>
          {this.props.idImagen !== 0 ? (
            <img
              width="100%"
              src={`${
                appConfig.baseURL +
                "/api/Multimedia/" +
                this.props.getAction +
                "/" +
                this.props.idImagen +
                "?" +
                this.state.imageHash
              }`}
              alt={"imagen-" + this.props.idImagen}
            />
          ) : null}
        </div>

        <CardActions disableSpacing style={{ paddingTop: "0px" }}>
          {this.state.id == 0 ? null : (
            <div>
              {this.props.readOnly !== true ? (
                <Tooltip title="Rotar hacia la derecha">
                  <IconButton
                    aria-label="rotar"
                    size="large"
                    color="primary"
                    onClick={this.rotateImage}
                  >
                    <Autorenew />
                  </IconButton>
                </Tooltip>
              ) : null}

              <Tooltip title="Ver imagen">
                <IconButton
                  aria-label="Ver imagen"
                  size="large"
                  color="primary"
                  onClick={this.handleClickOpen}
                >
                  <RemoveRedEye />
                </IconButton>
              </Tooltip>

              {this.props.readOnly !== true ? (
                <Tooltip title="Eliminar imagen">
                  <IconButton
                    aria-label="Eliminar"
                    onClick={this.deleteImagen}
                    color="error"
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          )}
        </CardActions>
      </Card>
    );
  }
}
