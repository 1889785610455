import React, { Component } from "react";
import { axiosInstance } from "../../services/api/axiosInstance";
import { TextField, Grid } from "@mui/material/";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import Delete from "@mui/icons-material/Delete";
import appConfig from "../../constants/appConfig";

export class BuscadorDeProductos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      texto: "",
      articulos: [],
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.search = this.search.bind(this);
  }

  onChange = (event, value) => {
    if (event.target.type === "checkbox") {
      this.setState({
        [event.target.name]: event.target.checked,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };
  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  async search() {

    if (this.state.texto !== "") {
      this.setState({
        articulos: [],
      });

      const articulosResponse = await axiosInstance.get(
        `/api/Prospecto/GetArticulos/` + this.state.texto
      );

      this.setState({
        articulos: articulosResponse.data,
      });
    }
  }

  async componentDidMount() {}

  meInteresa(articulo) {
    this.props.updateArticuloCallBack(articulo);
  }

  yaNomeInteresa(articulo) {
    this.props.updateArticuloCallBackRemove(articulo);
  }

  estaSeleccionad(articulo) {
    return (
      this.props.articulosSeleccionados.filter(
        (art) => art.idArticulo === articulo.idArticulo
      ).length === 1
    );
  }

  render() {
    return (
      <div>
        {this.props.readOnly !== true ? (
          <Button variant="outlined" onClick={this.handleClickOpen}>
            Buscar productos
          </Button>
        ) : null}

        <br />

        <TableContainer component={Paper} className="mt-10">
          <Table size="small" aria-label="tabla de articulos">
            <TableBody>
              {this.props.articulosSeleccionados.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
            
                    <img
                      width="75px"
                      // src={`${
                      //   appConfig.baseURL +
                      //   "/api/Prospecto/GetArticuloImage/" +
                      //   row.idArticulo
                      // }`}


                      src={`${appConfig.baseURL}/api/Articulo/GetArticuloImage/${row.idArticulo}?${Date.now()}`}

                      alt={"imagen-" + this.props.idArticulo}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <b>{row.marca}</b>
                    <br />
                    {row.nombre} <br />
                  </TableCell>

                  {this.props.readOnly !== true ? (
                    <TableCell>
                      <Button
                        variant="text"
                        color="error"
                        size="large"
                        startIcon={<Delete />}
                        type="button"
                        onClick={() => this.yaNomeInteresa(row)}
                      ></Button>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={false}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle id="alert-dialog-title">
            Buscador de productos
          </DialogTitle>
          <DialogContent style={{ padding: "20px" }}>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={10}>
                <TextField
                  name="texto"
                  variant="outlined"
                  onChange={this.onChange}
                  value={this.state.texto}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<SearchIcon />}
                  type="button"
                  onClick={this.search}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>

            <br />
            <br />
            <TableContainer component={Paper}>
              <Table size="small" aria-label="tabla de articulos">
                <TableBody>
                  {this.state.articulos.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <img
                          width="75px"
                          //src={`${ appConfig.baseURL + "/api/Prospecto/GetArticuloImage/" + row.idArticulo }`}
                          src={`${appConfig.baseURL}/api/Articulo/GetArticuloImage/${row.idArticulo}?${Date.now()}`}
                          alt={"imagen-" + this.props.idArticulo}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <b>{row.marca}</b>
                        <br />
                        {row.nombre} <br />
                      </TableCell>
                      <TableCell>
                        {this.estaSeleccionad(row) ? (
                          <Button
                            variant="text"
                            color="primary"
                            size="small"
                            startIcon={<ThumbDown />}
                            type="button"
                            onClick={() => this.yaNomeInteresa(row)}
                          >
                            Ya no me interesa
                          </Button>
                        ) : (
                          <Button
                            variant="text"
                            color="primary"
                            size="small"
                            startIcon={<ThumbUp />}
                            type="button"
                            onClick={() => this.meInteresa(row)}
                          >
                            Me interesa
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} autoFocus>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
