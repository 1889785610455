import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ClearCacheProvider } from "react-clear-cache";
// configureStore
import { configureStore } from "./store/index";
import { Provider } from 'react-redux';

const root = document.getElementById("root");

// store
export const store = configureStore();

if (true) {
  const RedBox = require("redbox-react").default;
  try {
    ReactDOM.render(
      <React.StrictMode>
        <ClearCacheProvider auto={true}>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </ClearCacheProvider>
      </React.StrictMode>,
      root
    );
  } catch (e) {
    ReactDOM.render(<RedBox error={e} />, root);
  }
} else {
  ReactDOM.render(
    <ClearCacheProvider auto={true}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </ClearCacheProvider>,
    root
  );
}

// ReactDOM.render(

//   ,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
