import { containerClasses, createTheme } from "@mui/material/";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#E4392F",
      contrast: "#fff",
    },
    secondary: {
      main: "#4974a5",
      contrast: "#fff",
    },
    delete: {
      main: "#b62d25",
      contrast: "#fff",
    },
    warning: {
      main: "#e74c3c",
      contrast: "#fff",
    },
    background: {
      default: "#fafbfb",
    },

    operacion: {
      main: '#fff3cd',
      contrastText: '#856404',
    },

    whatsApp:{
      main: '#128C7E',
      contrastText: '#fff',
    }

    ,

    grey:{
      main: '#999',
      contrastText: '#fff',
    } 
    ,

    lightGrey:{
      main: '#ABABAB',
      contrastText: '#fff',
    } 
    ,

    calificacionRechazada:{
      main: '#c9302c',
      contrastText: '#fff',      
    } ,

    calificacionPendiente:{
      main: '#ec971f',
      contrastText: '#fff',      
    } ,

    refinanciar:{
      main: '#ec971f',
      contrastText: '#fff',      
    } 
    ,

    incobrable:{
      main: '#31b0d5',
      contrastText: '#fff',      
    } 
    ,

    quita:{
      main: '#5cb85c',
      contrastText: '#fff',      
    } 
    ,

    devolucion:{
      main: '#286090',
      contrastText: '#fff',      
    } 
    ,

    secuestro:{
      main: '#fff',
      contrastText: '#333',      
    } 
  },





  components: {

    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#111827",
          color: "#d1d5db",
          fontWeight: 800,
        },

        link: {
          color: "#00ff00",
          "&:hover": {
            color: "#000000",
            textDecoration: "underline #000000",
          },
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "red",
          opacity: 0.7,
          "$checked$checked + &": {
            // Controls checked color for the thumb
            //color: "red",
            //opacity: 0.7,
          }
        },
      
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: "red",
          "$checked$checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#fff"
          }
        }
      }
    }
  },


});
export default lightTheme;
